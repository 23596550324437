import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ErrorField from '../../../components/form/ErrorField'
import Loader from '../../../components/Loader'
import * as Lang from '../../../i18n/constants'
import ICalendar from '../../../services/Calendar/Calendar.interface'
import { Days } from '../../../services/Day/Day.interface'
import ITimeZone from '../../../services/TimeZone/TimeZone.interface'
import * as Zone from '../../../services/Zone/Zone'
import IZone, { IEnvironmentalRestrictions } from '../../../services/Zone/Zone.interface'

interface ZoneModalProps {
    show: boolean
    handleClose: () => void
    reloadZones: () => void
    zone?: IZone | false
}

interface Day {
    name: string
    active: boolean
    timeZone1: ITimeZone
    timeZone2: ITimeZone
}

interface ZoneForm extends Omit<IZone, 'calendar'> {
    days?: Day[]
    dateOfExpiry?: string
}

const ZoneModal = ({ show, handleClose, reloadZones, zone }: ZoneModalProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue
    } = useForm<ZoneForm>()

    const { fields, update } = useFieldArray({
        control,
        name: 'days'
    })

    const handleCreateZone = async (data: ZoneForm): Promise<void> => {
        setLoading(true)

        const calendar: ICalendar = {
            days: [],
            dateOfExpiry: moment(data.dateOfExpiry, 'YYYY-MM-DD').toDate()
        }

        if (data.days)
            for (const day of data.days)
                if (day.active)
                    calendar.days.push({
                        day: day.name as Days,
                        timeZone1: {
                            start: day.timeZone1.start
                                ? moment(day.timeZone1.start, 'HH:mm').local().utc().format('HH:mm')
                                : '',
                            end: day.timeZone1.end
                                ? moment(day.timeZone1.end, 'HH:mm').local().utc().format('HH:mm')
                                : ''
                        },
                        timeZone2: {
                            start: day.timeZone2.start
                                ? moment(day.timeZone2.start, 'HH:mm').local().utc().format('HH:mm')
                                : '',
                            end: day.timeZone2.end
                                ? moment(day.timeZone2.end, 'HH:mm').local().utc().format('HH:mm')
                                : ''
                        }
                    })

        const zone: IZone = {
            name: data.name,
            description: data.description,
            color: data.color,
            coordinates: data.coordinates,
            environmentalRestrictions: data.environmentalRestrictions,
            timeBetweenEvents: data.timeBetweenEvents,
            courtesyTime: data.courtesyTime,
            calendar
        }

        const createdZone = await Zone.createZone(zone)

        if (createdZone) {
            toast.success(t(Lang.ZONE_CREATED_SUCCESSFULLY, { zone }))
            reloadZones()
        } else toast.error(t(Lang.ERROR_CREATING_ZONE, { zone }))

        setLoading(false)

        handleClose()
    }

    const handleUpdateZone = async (data: ZoneForm): Promise<void> => {
        setLoading(true)

        const calendar: ICalendar = {
            days: [],
            dateOfExpiry: moment(data.dateOfExpiry, 'YYYY-MM-DD').toDate()
        }

        if (data.days)
            for (const day of data.days)
                if (day.active)
                    calendar.days.push({
                        day: day.name as Days,
                        timeZone1: {
                            start: day.timeZone1.start
                                ? moment(day.timeZone1.start, 'HH:mm').local().utc().format('HH:mm')
                                : '',
                            end: day.timeZone1.end
                                ? moment(day.timeZone1.end, 'HH:mm').local().utc().format('HH:mm')
                                : ''
                        },
                        timeZone2: {
                            start: day.timeZone2.start
                                ? moment(day.timeZone2.start, 'HH:mm').local().utc().format('HH:mm')
                                : '',
                            end: day.timeZone2.end
                                ? moment(day.timeZone2.end, 'HH:mm').local().utc().format('HH:mm')
                                : ''
                        }
                    })

        const zone: IZone = {
            _id: data._id,
            name: data.name,
            description: data.description,
            color: data.color,
            coordinates: data.coordinates,
            environmentalRestrictions: data.environmentalRestrictions,
            timeBetweenEvents: data.timeBetweenEvents,
            courtesyTime: data.courtesyTime,
            calendar
        }

        const updatedZone = await Zone.updateZone(zone)

        if (updatedZone) {
            toast.success(t(Lang.ZONE_UPDATED_SUCCESSFULLY, { zone }))
            reloadZones()
        } else toast.error(t(Lang.ERROR_UPDATING_ZONE, { zone }))

        setLoading(false)
        handleClose()
    }

    const handleApplyAllDays = () => {
        const timeZone1: ITimeZone = { start: '', end: '' }
        const timeZone2: ITimeZone = { start: '', end: '' }

        for (const field of fields) {
            if (
                (field.timeZone1.start && field.timeZone1.end) ||
                (field.timeZone2.start && field.timeZone2.end)
            ) {
                if (field.timeZone1.start && field.timeZone1.end) {
                    timeZone1.start = field.timeZone1.start
                    timeZone1.end = field.timeZone1.end
                }

                if (field.timeZone2.start && field.timeZone2.end) {
                    timeZone2.start = field.timeZone2.start
                    timeZone2.end = field.timeZone2.end
                }

                break
            }
        }

        for (const [index, field] of Object.entries(fields))
            setValue(`days.${parseInt(index)}`, {
                name: field.name,
                active: true,
                timeZone1: { start: timeZone1.start, end: timeZone1.end },
                timeZone2: { start: timeZone2.start, end: timeZone2.end }
            })
    }

    useEffect(() => {
        const daysForm: Day[] = []

        if (zone) {
            let loadedZone: ZoneForm = {
                ...zone,
                dateOfExpiry: zone.calendar?.dateOfExpiry
                    ? moment(zone.calendar?.dateOfExpiry).format('YYYY-MM-DD')
                    : ''
            }

            if (zone.calendar) {
                Object.keys(Days).map((item) => {
                    if (zone.calendar) {
                        const found = zone.calendar.days.find((day) => day.day == item)

                        if (found) {
                            daysForm.push({
                                name: item,
                                active: true,
                                timeZone1: {
                                    start:
                                        found.timeZone1 && found.timeZone1.start
                                            ? moment
                                                  .utc(found.timeZone1.start, 'HH:mm')
                                                  .local()
                                                  .format('HH:mm')
                                            : '',
                                    end:
                                        found.timeZone1 && found.timeZone1.end
                                            ? moment
                                                  .utc(found.timeZone1.end, 'HH:mm')
                                                  .local()
                                                  .format('HH:mm')
                                            : ''
                                },
                                timeZone2: {
                                    start:
                                        found.timeZone2 && found.timeZone2.start
                                            ? moment
                                                  .utc(found.timeZone2.start, 'HH:mm')
                                                  .local()
                                                  .format('HH:mm')
                                            : '',
                                    end:
                                        found.timeZone2 && found.timeZone2.end
                                            ? moment
                                                  .utc(found.timeZone2.end, 'HH:mm')
                                                  .local()
                                                  .format('HH:mm')
                                            : ''
                                }
                            })
                        } else
                            daysForm.push({
                                name: item,
                                active: false,
                                timeZone1: { start: '', end: '' },
                                timeZone2: { start: '', end: '' }
                            })
                    }
                })
            } else {
                Object.keys(Days).map((item) => {
                    daysForm.push({
                        name: item,
                        active: false,
                        timeZone1: { start: '', end: '' },
                        timeZone2: { start: '', end: '' }
                    })
                })
            }

            loadedZone = { ...loadedZone, days: daysForm }
            reset(loadedZone)
        } else {
            const environmentalRestrictions: IEnvironmentalRestrictions = {
                zero: false,
                eco: false,
                b: false,
                c: false,
                without: false,
                foreign: false
            }

            let loadedZone: ZoneForm = {
                name: '',
                description: '',
                color: '#0000FF',
                coordinates: '',
                environmentalRestrictions: environmentalRestrictions,
                dateOfExpiry: moment(new Date()).format('YYYY-MM-DD'),
                timeBetweenEvents: 0,
                courtesyTime: 0
            }

            Object.keys(Days).map((item) => {
                daysForm.push({
                    name: item,
                    active: false,
                    timeZone1: { start: '', end: '' },
                    timeZone2: { start: '', end: '' }
                })
            })

            loadedZone = { ...loadedZone, days: daysForm }

            reset(loadedZone)
        }
    }, [zone])

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={zone ? handleSubmit(handleUpdateZone) : handleSubmit(handleCreateZone)}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {zone ? t(Lang.EDIT_ZONE, { zone }) : t(Lang.ADD_ZONE)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={errors.name ? 'border-2 border-danger' : ''}
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.name && <ErrorField message={errors.name.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            rows={5}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={errors.description ? 'border-2 border-danger' : ''}
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.description && <ErrorField message={errors.description.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COLOR)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'palette']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="color"
                            type="color"
                            maxLength={16}
                            placeholder={t(Lang.COLOR)}
                            aria-label={t(Lang.COLOR)}
                            aria-describedby={t(Lang.COLOR)}
                            className={errors.color ? 'border-2 border-danger' : ''}
                            {...register('color', {
                                maxLength: {
                                    value: 16,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COLOR),
                                        value: 16
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.color && <ErrorField message={errors.color.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COORDINATES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-crosshairs']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="coordinates"
                            type="text"
                            maxLength={4096}
                            placeholder={t(Lang.COORDINATES)}
                            aria-label={t(Lang.COORDINATES)}
                            aria-describedby={t(Lang.COORDINATES)}
                            className={errors.coordinates ? 'border-2 border-danger' : ''}
                            {...register('coordinates', {
                                maxLength: {
                                    value: 4096,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COORDINATES),
                                        value: 4096
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.coordinates && <ErrorField message={errors.coordinates.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.TIME_BETWEEN_EVENTS_MINUTES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.timeBetweenEvents
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'stopwatch']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="timeBetweenEvents"
                            type="number"
                            min={0}
                            max={99999}
                            placeholder={'* ' + t(Lang.TIME_BETWEEN_EVENTS_MINUTES)}
                            aria-label={t(Lang.TIME_BETWEEN_EVENTS_MINUTES)}
                            aria-describedby={t(Lang.TIME_BETWEEN_EVENTS_MINUTES)}
                            className={errors.timeBetweenEvents ? 'border-2 border-danger' : ''}
                            {...register('timeBetweenEvents', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.TIME_BETWEEN_EVENTS_MINUTES)
                                }) as string,
                                max: {
                                    value: 99999,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.TIME_BETWEEN_EVENTS_MINUTES),
                                        value: 99999
                                    })
                                },
                                min: {
                                    value: 0,
                                    message: t(Lang.MIN_FIELD, {
                                        field: t(Lang.TIME_BETWEEN_EVENTS_MINUTES),
                                        value: 0
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.timeBetweenEvents && (
                        <ErrorField message={errors.timeBetweenEvents.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COURTESY_TIME_MINUTES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.courtesyTime
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'clock']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="courtesyTime"
                            type="number"
                            min={0}
                            max={99999}
                            placeholder={'* ' + t(Lang.COURTESY_TIME_MINUTES)}
                            aria-label={t(Lang.COURTESY_TIME_MINUTES)}
                            aria-describedby={t(Lang.COURTESY_TIME_MINUTES)}
                            className={errors.timeBetweenEvents ? 'border-2 border-danger' : ''}
                            {...register('courtesyTime', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.COURTESY_TIME_MINUTES)
                                }) as string,
                                max: {
                                    value: 99999,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COURTESY_TIME_MINUTES),
                                        value: 99999
                                    })
                                },
                                min: {
                                    value: 0,
                                    message: t(Lang.MIN_FIELD, {
                                        field: t(Lang.COURTESY_TIME_MINUTES),
                                        value: 0
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.courtesyTime && <ErrorField message={errors.courtesyTime.message} />}
                    <Form.Group className="mb-3">
                        <Form.Label>{t(Lang.RESTRICTED_ENVIRONMENTAL_LABELS)}</Form.Label>
                        <Row className="border rounded py-3 mx-0">
                            <Col sm="6" xs="12">
                                <Form.Check
                                    type="checkbox"
                                    id="zeroLabel"
                                    label={t(Lang.ZERO)}
                                    {...register('environmentalRestrictions.zero')}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="EcoLabel"
                                    label={t(Lang.ECO)}
                                    {...register('environmentalRestrictions.eco')}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="BLabel"
                                    label={t(Lang.B)}
                                    {...register('environmentalRestrictions.b')}
                                />
                            </Col>
                            <Col sm="6" xs="12">
                                <Form.Check
                                    type="checkbox"
                                    id="CLabel"
                                    label={t(Lang.C)}
                                    {...register('environmentalRestrictions.c')}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="withoutLabel"
                                    label={t(Lang.WITHOUT)}
                                    {...register('environmentalRestrictions.without')}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="foreignLabel"
                                    label={t(Lang.FOREIGN)}
                                    {...register('environmentalRestrictions.foreign')}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>{t(Lang.CALENDAR)}</Form.Label>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Button variant="primary" onClick={handleApplyAllDays}>
                                {t(Lang.APPLY_ALL)}
                            </Button>
                        </Col>
                    </Row>
                    {fields.map((item, index) => {
                        return (
                            <div key={index} className="mb-3 card p-3">
                                <Row className="mb-1">
                                    <Col>
                                        <Controller
                                            name={`days.${index}.active`}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={field.value}
                                                    label={t(langObj[item.name.toUpperCase()])}
                                                    onChange={() => {
                                                        field.onChange(!item.active)
                                                        item.active = !item.active
                                                        update(index, item)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col md="2">{t(Lang.AM)}</Col>
                                    <Col md="5">
                                        <Controller
                                            name={`days.${index}.timeZone1.start`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <DatePicker
                                                    value={field.value}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption={t(Lang.HOUR)}
                                                    timeFormat="HH:mm"
                                                    dateFormat="HH:mm"
                                                    className="form-control w-100"
                                                    onChange={(date) => {
                                                        const timeString = date
                                                            ? date.toTimeString().slice(0, 5)
                                                            : ''
                                                        field.onChange(timeString)
                                                        item.timeZone1.start = timeString
                                                        update(index, item)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md="5">
                                        <Controller
                                            name={`days.${index}.timeZone1.end`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <DatePicker
                                                    value={field.value}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption={t(Lang.HOUR)}
                                                    timeFormat="HH:mm"
                                                    dateFormat="HH:mm"
                                                    className="form-control w-100"
                                                    onChange={(date) => {
                                                        const timeString = date
                                                            ? date.toTimeString().slice(0, 5)
                                                            : ''
                                                        field.onChange(timeString)
                                                        item.timeZone1.end = timeString
                                                        update(index, item)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">{t(Lang.PM)}</Col>
                                    <Col md="5">
                                        <Controller
                                            name={`days.${index}.timeZone2.start`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <DatePicker
                                                    value={field.value}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption={t(Lang.HOUR)}
                                                    timeFormat="HH:mm"
                                                    dateFormat="HH:mm"
                                                    className="form-control w-100"
                                                    onChange={(date) => {
                                                        const timeString = date
                                                            ? date.toTimeString().slice(0, 5)
                                                            : ''
                                                        field.onChange(timeString)
                                                        item.timeZone2.start = timeString
                                                        update(index, item)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md="5">
                                        <Controller
                                            name={`days.${index}.timeZone2.end`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <DatePicker
                                                    value={field.value}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption={t(Lang.HOUR)}
                                                    timeFormat="HH:mm"
                                                    dateFormat="HH:mm"
                                                    className="form-control w-100"
                                                    onChange={(date) => {
                                                        const timeString = date
                                                            ? date.toTimeString().slice(0, 5)
                                                            : ''
                                                        field.onChange(timeString)
                                                        item.timeZone2.end = timeString
                                                        update(index, item)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DATE_OF_EXPIRY)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.dateOfExpiry
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'calendar-days']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="dateOfExpiry"
                            type="date"
                            aria-label={t(Lang.DATE_OF_EXPIRY)}
                            aria-describedby={t(Lang.DATE_OF_EXPIRY)}
                            className={errors.dateOfExpiry ? 'border-2 border-danger' : ''}
                            {...register('dateOfExpiry')}
                        />
                    </InputGroup>
                    {errors.dateOfExpiry && <ErrorField message={errors.dateOfExpiry.message} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader loading={loading} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ZoneModal
