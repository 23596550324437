import { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Menu from '../../components/menu/Menu'
import IUser from '../../services/User/User.interface'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import Loader from '../../components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import IList from '../../services/List/List.interface'
import * as List from '../../services/List/List'
import IPlate from '../../services/Plate/Plate.interface'
import * as Plate from '../../services/Plate/Plate'
import WhitePlateModal from './components/WhitePlateModal'
import WhitePlatesTable from './components/WhitePlatesTable'
import WhiteExportCSV from './components/WhiteExportCSV'
import WhiteImportCSV from './components/WhiteImportCSV'
import PlatesListFilter, { PlatesListFilterForm } from '../../components/lists/PlatesListFilter'

interface WhitePlatesListProps {
    user?: IUser
}

const WhitePlatesListLayout = ({ user }: WhitePlatesListProps): JSX.Element => {
    const params = useParams()
    const id = params.id

    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState<IList | false>(false)
    const [plates, setPlates] = useState<IPlate[]>([])
    const [showPlateModal, setShowPlateModal] = useState(false)
    const [plateModal, setPlateModal] = useState<IPlate | false>()
    const [fetchPlates, setFetchPlates] = useState(true)
    const [filter, setFilter] = useState<PlatesListFilterForm>()

    const triggerPlatesFetch = () => setFetchPlates((t) => !t)

    const handleOpenPlateModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button: HTMLButtonElement = event.currentTarget

        const id = button.value

        if (id) {
            const plate = plates.find((plate) => plate._id === id)
            setPlateModal(plate)
        } else setPlateModal(undefined)

        setShowPlateModal(true)
    }

    const handleClosePlateModal = (): void => {
        setPlateModal(false)
        setShowPlateModal(false)
    }

    const handleFilter = (data: PlatesListFilterForm) => {
        const plate: PlatesListFilterForm = {}
        Object.entries(data).map(([index, value]) => {
            if (value !== '') {
                plate[index as keyof PlatesListFilterForm] = value
            }
        })
        setFilter(plate)
        setLoading(true)
        triggerPlatesFetch()
    }

    useEffect(() => {
        const loadPlates = async () => {
            if (!id) return setLoading(false)

            const list = await List.getList(id)

            if (!list || !list._id) return setLoading(false)

            setList(list)

            const plates = await Plate.getPlates(list._id, filter?.plate, filter?.owner)

            if (plates) setPlates(plates)

            setLoading(false)
        }

        loadPlates()
    }, [fetchPlates])

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? <Menu currentPage={t(Lang.WHITE_LISTS)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    {list ? (
                        <>
                            <Row>
                                <Col className="text-center">
                                    <h2>{`${t(Lang.LIST)} ${list.name}`}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" lg="1">
                                    <Button
                                        variant="link"
                                        className="btn-add"
                                        onClick={handleOpenPlateModal}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'plus']}
                                            size="2x"
                                            fixedWidth
                                        />
                                    </Button>
                                    <WhitePlateModal
                                        show={showPlateModal}
                                        handleClose={handleClosePlateModal}
                                        reloadPlates={triggerPlatesFetch}
                                        plate={plateModal}
                                        list={list}
                                    />
                                </Col>
                                <Col xs="12" lg="10">
                                    <WhiteImportCSV list={list} reloadPlates={triggerPlatesFetch} />
                                </Col>
                                {plates.length ? (
                                    <Col xs="12" lg="1">
                                        <WhiteExportCSV plates={plates} list={list} />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    <PlatesListFilter handleFilter={handleFilter} />
                                </Col>
                            </Row>
                            {plates.length ? (
                                <Row>
                                    <Col>
                                        <WhitePlatesTable
                                            plates={plates}
                                            handleEditPlate={handleOpenPlateModal}
                                            reloadPlates={triggerPlatesFetch}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Loader loading={loading} size={100} color="#0d6efd" />
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </>
    )
}
export default WhitePlatesListLayout
