import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Menu from '../../components/menu/Menu'
import IUser from '../../services/User/User.interface'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import Loader from '../../components/Loader'
import IZone from '../../services/Zone/Zone.interface'
import * as Zone from '../../services/Zone/Zone'
import IAlert from '../../services/Alert/Alert.interface'
import * as Alert from '../../services/Alert/Alert'
import ICamera from '../../services/Camera/Camera.interface'
import * as Camera from '../../services/Camera/Camera'
import AlertsFilters, { AlertsFiltersForm } from './components/AlertsFilters'
import AlertsTable from './components/AlertsTable'
import AlertModal from './components/AlertModal'
import Pagination from '../../components/CustomPagination'
import moment from 'moment'

interface AlertsLayoutProps {
    user?: IUser
}

const AlertsLayout = ({ user }: AlertsLayoutProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [zones, setZones] = useState<IZone[]>([])
    const [zoneSelected, setZoneSelected] = useState<string | undefined>()
    const [alerts, setAlerts] = useState<IAlert[]>([])
    const [totalAlerts, setTotalAlerts] = useState<number>()
    const [fetchAlerts, setFetchAlerts] = useState(true)
    const [alertModal, setAlertModal] = useState<IAlert | false>()
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [cameras, setCameras] = useState<ICamera[]>([])
    const [filter, setFilter] = useState<AlertsFiltersForm>()
    const [limit, setLimit] = useState(50)
    const [page, setPage] = useState(1)

    const triggerAlertFetch = () => setFetchAlerts((t) => !t)

    const handleChangeZone = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setZoneSelected(event.target.value)
        triggerAlertFetch()
    }

    const handleFilter = (data: AlertsFiltersForm) => {
        const alert: AlertsFiltersForm = {}
        Object.entries(data).map(([index, value]) => {
            if (value !== '') {
                if (index === 'start' || index === 'end')
                    alert[index] = moment(value).utc().format('YYYY-MM-DD HH:mm')
                else alert[index as keyof AlertsFiltersForm] = value
            }
        })
        setFilter(alert)
        triggerAlertFetch()
    }

    const handleOpenAlertModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const button: HTMLButtonElement = event.currentTarget

        const id = button.value

        if (id) {
            const alert = alerts.find((alert) => alert._id === id)
            setAlertModal(alert)
        } else setAlertModal(undefined)

        setShowAlertModal(true)
    }

    const handleCloseAlertModal = (): void => {
        setAlertModal(false)
        setShowAlertModal(false)
    }

    const handleChangeLimit = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setLimit(parseInt(event.target.value))
        setPage(1)
        triggerAlertFetch()
    }

    const handleClickPage = (nPage: number) => {
        setPage(nPage)
        triggerAlertFetch()
    }

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones()

            if (zones && zones.length) {
                setZones(zones)

                if (!zoneSelected) setZoneSelected(zones[0]._id)

                const zoneId = zoneSelected || zones[0]._id || ''

                const cameras = await Camera.getCameras(zoneId)

                if (cameras) setCameras(cameras)

                const alertsCount = await Alert.getAlertsCount({
                    ...filter,
                    zone: zoneId
                })

                if (alertsCount) setTotalAlerts(alertsCount)

                const alerts = await Alert.getAlerts({
                    ...filter,
                    zone: zoneId,
                    limit,
                    page
                })

                if (alerts) setAlerts(alerts)
            }
            setLoading(false)
        }
        load()
    }, [fetchAlerts])

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? <Menu currentPage={t(Lang.ALERTS)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row className="mb-5">
                        {zones.length ? (
                            <Col xs="12" xl="4">
                                <Form.Select onChange={handleChangeZone}>
                                    {zones.map((zone) => {
                                        return (
                                            <option key={zone._id} value={zone._id}>
                                                {zone.name}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    {cameras.length ? (
                        <Row className="mb-3">
                            <Col>
                                <AlertsFilters cameras={cameras} handleFilter={handleFilter} />
                                {zoneSelected && alertModal ? (
                                    <AlertModal
                                        show={showAlertModal}
                                        handleClose={handleCloseAlertModal}
                                        alert={alertModal}
                                        cameras={cameras}
                                    />
                                ) : (
                                    ''
                                )}
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {alerts.length && cameras.length ? (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <AlertsTable
                                        alerts={alerts}
                                        cameras={cameras}
                                        handleEditAlert={handleOpenAlertModal}
                                        reloadAlerts={triggerAlertFetch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" xl="2" className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ITEMS_PER_PAGE, {
                                            item: t(Lang.ALERTS)
                                        })}
                                    </Form.Label>
                                </Col>
                                <Col xs="6" xl="1">
                                    <Form.Select defaultValue={limit} onChange={handleChangeLimit}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Col>
                                {totalAlerts && totalAlerts > limit ? (
                                    <Col xs="12" xl="9">
                                        <Pagination
                                            totalPages={Math.ceil(totalAlerts / limit)}
                                            currentPage={page}
                                            pageClicked={handleClickPage}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    )
}

export default AlertsLayout
