import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IWarning from './Warning.interface'

const apiURL = `${baseURL}/warnings`

export const createWarning = async (warning: IWarning): Promise<IWarning | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, warning, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdWarning: IWarning = response.data
            return createdWarning
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getWarnings = async (
    user?: string,
    page?: number,
    limit?: number
): Promise<IWarning[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { user, page, limit }
        })

        if (response.data) {
            const warnings: IWarning[] = response.data
            return warnings
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getWarningsCount = async (user?: string): Promise<number | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { user, count: true }
        })

        if (response.data) {
            const warningsCount: number = response.data
            return warningsCount
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getWarning = async (id: string): Promise<IWarning | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const warning: IWarning = response.data
            return warning
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateWarning = async (warning: IWarning): Promise<IWarning | false> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiURL}/${warning._id}`, warning, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedWarning: IWarning = response.data
            return updatedWarning
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteWarning = async (id: string): Promise<IWarning | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedWarning: IWarning = response.data
            return deletedWarning
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
