import { Table, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../components/Loader'
import IPanel from '../../../services/Panel/Panel.interface'
import * as Panel from '../../../services/Panel/Panel'
import ICamera from '../../../services/Camera/Camera.interface'

interface PanelsTableProps {
    panels: IPanel[]
    handleEditPanel: (event: React.MouseEvent<HTMLButtonElement>) => void
    reloadPanels: () => void
    cameras: ICamera[]
}

const PanelsTable = ({
    panels,
    handleEditPanel,
    reloadPanels,
    cameras
}: PanelsTableProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState('')

    const handleDeletePanel = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault()
        const button: HTMLButtonElement = event.currentTarget
        const id = button.value

        setLoading(id)

        const deletedPanel = await Panel.deletePanel(id)

        if (deletedPanel) {
            setLoading('')
            toast.success(t(Lang.PANEL_DELETED_SUCCESSFULLY, { panel: deletedPanel }))
            reloadPanels()
        } else {
            setLoading('')
            toast.error(t(Lang.ERROR_DELETING_PANEL, { panel: deletedPanel }))
        }
    }

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.NAME)}</th>
                            <th>{t(Lang.DESCRIPTION)}</th>
                            <th>{t(Lang.LOCATION)}</th>
                            <th>{t(Lang.COORDINATES)}</th>
                            <th>{t(Lang.MANUFACTURER)}</th>
                            <th>{t(Lang.MODEL)}</th>
                            <th>{t(Lang.NLINES)}</th>
                            <th>{t(Lang.HOST)}</th>
                            <th>{t(Lang.PORT)}</th>
                            <th>{t(Lang.PROTOCOL)}</th>
                            <th>{t(Lang.CAMERA)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {panels.map((panel: IPanel) => {
                            const camera = cameras.filter((camera) => camera._id === panel.camera)
                            return (
                                <tr key={panel._id}>
                                    <td></td>
                                    <td>{panel.name}</td>
                                    <td className="text-truncate">{panel.description}</td>
                                    <td>{panel.location}</td>
                                    <td>{panel.coordinates}</td>
                                    <td>{panel.manufacturer}</td>
                                    <td>{panel.model}</td>
                                    <td>{panel.nLines}</td>
                                    <td>{panel.host}</td>
                                    <td>{panel.port}</td>
                                    <td>{panel.protocol}</td>
                                    <td>{camera.length ? camera[0].name : t(Lang.FEMALE_NONE)}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={panel._id}
                                            onClick={handleEditPanel}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={panel._id}
                                            onClick={handleDeletePanel}
                                        >
                                            {loading === panel._id ? (
                                                <Loader loading={true} size={25} color="#dc3545" />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default PanelsTable
