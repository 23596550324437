export enum RestrictionMode {
    environmental = 'environmental',
    permanent = 'permanent',
    timeControl = 'timeControl',
    timeControl_Environmental = 'timeControl_Environmental'
}

export default interface IOption {
    _id?: string
    name: string
    value: string
    createdAt?: Date
    updatedAt?: Date
}
