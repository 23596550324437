import TimeZone from '../TimeZone/TimeZone.interface'

export enum Days {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

export default interface IDay {
    _id?: string
    day: Days
    timeZone1?: TimeZone
    timeZone2?: TimeZone
    createdAt?: Date
    updatedAt?: Date
}
