import moment from 'moment'
import { Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { REACT_APP_BACKEND_URL } from '../../../configuration'
import * as Lang from '../../../i18n/constants'
import ICamera from '../../../services/Camera/Camera.interface'
import IEvent from '../../../services/Event/Event.interface'

interface SanctionModalProps {
    show: boolean
    handleClose: () => void
    sanction: IEvent
    cameras: ICamera[]
}

const SanctionModal = ({
    show,
    handleClose,
    sanction,
    cameras
}: SanctionModalProps): JSX.Element => {
    const { t } = useTranslation()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const camera = cameras.filter((camera) => camera._id === sanction.camera)

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="large-modal">
            <Modal.Header closeButton className="border-0">
                <Modal.Title>{t(Lang.VIEW_SANCTION)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.PLATE)}: {sanction.plate}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.CAMERA)}: {camera.length ? camera[0].name : ''}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.DIRECTION)}: {t(langObj[sanction.direction.toUpperCase()])}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.LOCATION)}: {camera.length ? camera[0].location : ''}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.DATE)}:{' '}
                            {moment(sanction.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                        </label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <label>
                            {t(Lang.RESTRICTION_MODE)}:{' '}
                            {sanction.restrictionMode
                                ? t(langObj[sanction.restrictionMode.toUpperCase()])
                                : ''}
                        </label>
                    </Col>
                </Row>
                {sanction.imagePlateURL && sanction.imagePlateURL.length ? (
                    <Row className="mb-3">
                        <Col xs="6">
                            <img
                                src={`${REACT_APP_BACKEND_URL}/${sanction.imagePlateURL}`}
                                className="w-100 card"
                            />
                        </Col>
                    </Row>
                ) : (
                    ''
                )}
                {sanction.imageOCRURL && sanction.imageOCRURL.length ? (
                    <Row className="mb-3">
                        <Col xs="6">
                            <img
                                src={`${REACT_APP_BACKEND_URL}/${sanction.imageOCRURL}`}
                                className="w-100 card"
                            />
                        </Col>
                    </Row>
                ) : (
                    ''
                )}
                {sanction.imagePixelatedURL && sanction.imagePixelatedURL.length ? (
                    <Row className="mb-3">
                        <Col xs="6">
                            <img
                                src={`${REACT_APP_BACKEND_URL}/${sanction.imagePixelatedURL}`}
                                className="w-100 card"
                            />
                        </Col>
                    </Row>
                ) : (
                    ''
                )}
                {sanction.imageContextURL && sanction.imageContextURL.length ? (
                    <Row className="mb-3">
                        <Col xs="12">
                            <img
                                src={`${REACT_APP_BACKEND_URL}/${sanction.imageContextURL}`}
                                className="w-100 card"
                            />
                        </Col>
                    </Row>
                ) : (
                    ''
                )}
            </Modal.Body>
        </Modal>
    )
}

export default SanctionModal
