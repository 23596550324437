import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import ErrorField from '../../../components/form/ErrorField'
import Loader from '../../../components/Loader'
import { toast } from 'react-toastify'
import ISensor from '../../../services/Sensor/Sensor.interface'
import * as Sensor from '../../../services/Sensor/Sensor'
import { DeviceStatus } from '../../../services/Camera/Camera.interface'
import ISensorType from '../../../services/SensorType/SensorType.interface'

interface SensorModalProps {
    show: boolean
    handleClose: () => void
    reloadSensors: () => void
    sensor?: ISensor | false
    zoneId: string
    sensorsType: ISensorType[]
}

const SensorModal = ({
    show,
    handleClose,
    reloadSensors,
    sensor,
    zoneId,
    sensorsType
}: SensorModalProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<ISensor>()

    const handleCreateSensor = async (data: ISensor): Promise<void> => {
        setLoading(true)

        const sensor: ISensor = {
            idExt: data.idExt,
            name: data.name,
            description: data.description,
            type: data.type,
            location: data.location,
            coordinates: data.coordinates,
            status: DeviceStatus.offline,
            zone: zoneId
        }

        const createdSensor = await Sensor.createSensor(sensor)

        if (createdSensor) {
            toast.success(
                t(Lang.SENSOR_CREATED_SUCCESSFULLY, {
                    sensor
                })
            )
            reloadSensors()
        } else toast.error(t(Lang.ERROR_CREATING_SENSOR, { sensor }))

        setLoading(false)

        handleClose()
    }

    useEffect(() => {
        if (sensor) {
            reset(sensor)
        } else {
            reset({
                idExt: '',
                name: '',
                description: '',
                type: '',
                location: '',
                coordinates: ''
            })
        }
    }, [sensor])

    const handleUpdateSensor = async (data: ISensor): Promise<void> => {
        setLoading(true)

        const sensor: ISensor = {
            _id: data._id,
            idExt: data.idExt,
            name: data.name,
            description: data.description,
            type: data.type,
            location: data.location,
            coordinates: data.coordinates,
            status: DeviceStatus.offline,
            zone: zoneId
        }

        const updatedSensor = await Sensor.updateSensor(sensor)

        if (updatedSensor) {
            toast.success(
                t(Lang.SENSOR_UPDATED_SUCCESSFULLY, {
                    sensor
                })
            )
            reloadSensors()
        } else toast.error(t(Lang.ERROR_UPDATING_SENSOR, { sensor }))

        setLoading(false)

        handleClose()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    sensor ? handleSubmit(handleUpdateSensor) : handleSubmit(handleCreateSensor)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {sensor
                            ? t(Lang.EDIT_SENSOR, {
                                  sensor
                              })
                            : t(Lang.ADD_SENSOR)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.IDENTIFIER)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.idExt ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'id-card']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="idExt"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.IDENTIFIER)}
                            aria-label={t(Lang.IDENTIFIER)}
                            aria-describedby={t(Lang.IDENTIFIER)}
                            className={errors.idExt ? 'border-2 border-danger' : ''}
                            {...register('idExt', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.IDENTIFIER)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.IDENTIFIER),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.idExt && <ErrorField message={errors.idExt.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={errors.name ? 'border-2 border-danger' : ''}
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.name && <ErrorField message={errors.name.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            maxLength={512}
                            rows={5}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={errors.description ? 'border-2 border-danger' : ''}
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.description && <ErrorField message={errors.description.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.SENSOR_TYPE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.type ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="type"
                            className={errors.type ? 'border-2 border-danger' : ''}
                            {...register('type')}
                        >
                            {sensorsType.map((sensorType: ISensorType) => {
                                return (
                                    <option key={sensorType._id} value={sensorType._id}>
                                        {sensorType.name}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.LOCATION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.location
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-dot']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="location"
                            type="text"
                            maxLength={256}
                            placeholder={'* ' + t(Lang.LOCATION)}
                            aria-label={t(Lang.LOCATION)}
                            aria-describedby={t(Lang.LOCATION)}
                            className={errors.location ? 'border-2 border-danger' : ''}
                            {...register('location', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.LOCATION)
                                }) as string,
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.LOCATION),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.location && <ErrorField message={errors.location.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COORDINATES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.coordinates
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-crosshairs']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="coordinates"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.COORDINATES)}
                            aria-label={t(Lang.COORDINATES)}
                            aria-describedby={t(Lang.COORDINATES)}
                            className={errors.coordinates ? 'border-2 border-danger' : ''}
                            {...register('coordinates', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.COORDINATES)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COORDINATES),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.coordinates && <ErrorField message={errors.coordinates.message} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader loading={loading} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default SensorModal
