import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IOption from './Option.interface'

const apiURL = `${baseURL}/options`

export const createOption = async (option: IOption): Promise<IOption | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, option, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdOption: IOption = response.data
            return createdOption
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getOptions = async (): Promise<IOption[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const options: IOption[] = response.data
            return options
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getOption = async (id: string): Promise<IOption | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const option: IOption = response.data
            return option
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateOption = async (option: IOption): Promise<IOption | false> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiURL}/${option._id}`, option, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedOption: IOption = response.data
            return updatedOption
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteOption = async (id: string): Promise<IOption | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedOption: IOption = response.data
            return deletedOption
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
