import { Table, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../components/Loader'
import IWarning, { DeviceType } from '../../../services/Warning/Warning.interface'
import * as Warning from '../../../services/Warning/Warning'
import ICamera from '../../../services/Camera/Camera.interface'
import IRaspberry from '../../../services/Raspberry/Raspberry.interface'
import IPanel from '../../../services/Panel/Panel.interface'
import ISensor from '../../../services/Sensor/Sensor.interface'
import moment from 'moment'

interface WarningsTableProps {
    warnings: IWarning[]
    cameras?: ICamera[]
    raspberries?: IRaspberry[]
    panels?: IPanel[]
    sensors?: ISensor[]
    reloadWarnings: () => void
}

const WarningsTable = ({
    warnings,
    cameras,
    raspberries,
    panels,
    sensors,
    reloadWarnings
}: WarningsTableProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState('')

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const handleDeleteWarning = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault()
        const button: HTMLButtonElement = event.currentTarget
        const id = button.value

        setLoading(id)

        const deletedWarning = await Warning.deleteWarning(id)

        if (deletedWarning) {
            toast.success(t(Lang.WARNING_DELETED_SUCCESSFULLY))
            reloadWarnings()
        } else toast.error(t(Lang.ERROR_DELETING_WARNING))

        setLoading('')
    }

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.DEVICE)}</th>
                            <th>{t(Lang.DEVICE_TYPE)}</th>
                            <th>{t(Lang.DATE)}</th>
                            <th>{t(Lang.WARNING_TYPE)}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {warnings.map((warning: IWarning) => {
                            let device:
                                | ICamera[]
                                | IRaspberry[]
                                | IPanel[]
                                | ISensor[]
                                | undefined = []

                            if (warning.type === DeviceType.camera)
                                device = cameras?.filter((camera) => camera._id === warning.device)

                            if (warning.type === DeviceType.raspberry)
                                device = raspberries?.filter(
                                    (raspberry) => raspberry._id === warning.device
                                )

                            if (warning.type === DeviceType.panel)
                                device = panels?.filter((panel) => panel._id === warning.device)

                            if (warning.type === DeviceType.sensor)
                                device = sensors?.filter((sensor) => sensor._id === warning.device)

                            return (
                                <tr key={warning._id}>
                                    <td></td>
                                    <td>{device && device.length ? device[0].name : ''}</td>
                                    <td>{t(langObj[warning.type.toUpperCase()])}</td>
                                    <td>
                                        {moment(warning.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                                    </td>
                                    <td>{t(langObj[warning.reason.toUpperCase()])}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={warning._id}
                                            onClick={handleDeleteWarning}
                                        >
                                            {loading === warning._id ? (
                                                <Loader loading={true} size={25} color="#dc3545" />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default WarningsTable
