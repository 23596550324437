import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm, useFieldArray } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import ErrorField from '../../../components/form/ErrorField'
import Loader from '../../../components/Loader'
import { toast } from 'react-toastify'
import IPanel, { Protocol, Manufacturers, PanelType } from '../../../services/Panel/Panel.interface'
import * as Panel from '../../../services/Panel/Panel'
import ICamera from '../../../services/Camera/Camera.interface'

interface PanelModalProps {
    show: boolean
    handleClose: () => void
    reloadPanels: () => void
    panel?: IPanel | false
    zoneId: string
    cameras: ICamera[]
}

const PanelModal = ({
    show,
    handleClose,
    reloadPanels,
    panel,
    zoneId,
    cameras
}: PanelModalProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [manufacturerSelected, setManufacturerSelected] = useState(Object.keys(Manufacturers)[0])

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset
    } = useForm<IPanel>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'lines'
    })

    const numberOfLines = watch('nLines')

    const handleCreatePanel = async (data: IPanel): Promise<void> => {
        setLoading(true)

        const panel: IPanel = {
            name: data.name,
            description: data.description,
            location: data.location,
            coordinates: data.coordinates,
            manufacturer: data.manufacturer,
            model: data.model,
            nLines: data.nLines,
            lines: data.lines,
            host: data.host,
            port: data.port,
            protocol: data.protocol,
            zone: zoneId,
            camera: data.camera,
            type: data.type
        }

        const createdPanel = await Panel.createPanel(panel)

        if (createdPanel) {
            toast.success(t(Lang.PANEL_CREATED_SUCCESSFULLY, { panel }))
            reloadPanels()
        } else toast.error(t(Lang.ERROR_CREATING_PANEL, { panel }))

        setLoading(false)

        handleClose()
    }

    const handleUpdatePanel = async (data: IPanel): Promise<void> => {
        setLoading(true)

        const panel: IPanel = {
            _id: data._id,
            name: data.name,
            description: data.description,
            location: data.location,
            coordinates: data.coordinates,
            manufacturer: data.manufacturer,
            model: data.model,
            nLines: data.nLines,
            lines: data.lines,
            host: data.host,
            port: data.port,
            protocol: data.protocol,
            zone: zoneId,
            camera: data.camera,
            type: data.type
        }

        const updatedPanel = await Panel.updatePanel(panel)

        if (updatedPanel) {
            toast.success(t(Lang.PANEL_UPDATED_SUCCESSFULLY, { panel }))
            reloadPanels()
        } else toast.error(t(Lang.ERROR_UPDATING_PANEL, { panel }))

        setLoading(false)
        handleClose()
    }

    const handleChangeManufacturer = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setManufacturerSelected(event.target.value)
    }

    useEffect(() => {
        const newVal = numberOfLines || 0
        const oldVal = fields.length

        if (newVal > oldVal) {
            for (let i = oldVal; i < newVal; i++) append({ _id: oldVal.toString(), text: '' })
        } else {
            for (let i = oldVal; i > newVal; i--) remove(i - 1)
        }
    }, [numberOfLines])

    useEffect(() => {
        if (panel) {
            reset(panel)
        } else {
            reset({
                name: '',
                description: '',
                location: '',
                coordinates: '',
                manufacturer: '',
                model: '',
                nLines: 3,
                lines: [],
                host: '',
                port: 0,
                protocol: Protocol.HTTP,
                zone: zoneId,
                camera: ''
            })
        }
    }, [panel])

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={panel ? handleSubmit(handleUpdatePanel) : handleSubmit(handleCreatePanel)}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {panel ? t(Lang.EDIT_PANEL, { panel }) : t(Lang.ADD_PANEL)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={errors.name ? 'border-2 border-danger' : ''}
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.name && <ErrorField message={errors.name.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            maxLength={512}
                            rows={5}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={errors.description ? 'border-2 border-danger' : ''}
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.description && <ErrorField message={errors.description.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.LOCATION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.location
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-dot']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="location"
                            type="text"
                            maxLength={256}
                            placeholder={'* ' + t(Lang.LOCATION)}
                            aria-label={t(Lang.LOCATION)}
                            aria-describedby={t(Lang.LOCATION)}
                            className={errors.location ? 'border-2 border-danger' : ''}
                            {...register('location', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.LOCATION)
                                }) as string,
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.LOCATION),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.location && <ErrorField message={errors.location.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COORDINATES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.coordinates
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-crosshairs']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="coordinates"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.COORDINATES)}
                            aria-label={t(Lang.COORDINATES)}
                            aria-describedby={t(Lang.COORDINATES)}
                            className={errors.coordinates ? 'border-2 border-danger' : ''}
                            {...register('coordinates', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.COORDINATES)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COORDINATES),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.coordinates && <ErrorField message={errors.coordinates.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MANUFACTURER)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.manufacturer
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'industry']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="manufacturer"
                            className={errors.manufacturer ? 'border-2 border-danger' : ''}
                            {...register('manufacturer', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.MANUFACTURER)
                                }) as string
                            })}
                            onChange={handleChangeManufacturer}
                        >
                            {Object.keys(Manufacturers).map((manufacturer: string) => {
                                return (
                                    <option key={manufacturer} value={manufacturer}>
                                        {'* ' + manufacturer}
                                    </option>
                                )
                            })}
                        </Form.Select>

                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MODEL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ms-3 rounded-start ${
                                    errors.model ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="model"
                            className={errors.model ? 'border-2 border-danger' : ''}
                            {...register('model', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.MODEL)
                                }) as string
                            })}
                        >
                            {Manufacturers[manufacturerSelected].models.map((model: string) => {
                                return (
                                    <option key={model} value={model}>
                                        {'* ' + model}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.manufacturer && <ErrorField message={errors.manufacturer.message} /> &&
                        errors.model && <ErrorField message={errors.model.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NLINES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.nLines
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'list-ol']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="nLines"
                            type="number"
                            max={5}
                            min={1}
                            placeholder={'* ' + t(Lang.NLINES)}
                            aria-label={t(Lang.NLINES)}
                            aria-describedby={t(Lang.NLINES)}
                            className={errors.nLines ? 'border-2 border-danger' : ''}
                            {...register('nLines', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NLINES)
                                }) as string,
                                min: {
                                    value: 1,
                                    message: t(Lang.MIN_FIELD, {
                                        field: t(Lang.NLINES),
                                        value: 1
                                    })
                                },
                                max: {
                                    value: 5,
                                    message: t(Lang.MAX_FIELD, {
                                        field: t(Lang.NLINES),
                                        value: 5
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.nLines && <ErrorField message={errors.nLines.message} />}
                    {fields.map((item, index) => (
                        <InputGroup key={item.id} className="mb-3">
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>{t(Lang.LINES)}</Tooltip>}
                            >
                                <InputGroup.Text
                                    className={`text-white ${
                                        errors.lines && errors.lines[index]
                                            ? 'bg-danger border-2 border-danger'
                                            : 'bg-primary'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'align-justify']}
                                        viewBox="0 0 512 512"
                                        fixedWidth
                                    />
                                </InputGroup.Text>
                            </OverlayTrigger>
                            <FormControl
                                id={item.id}
                                type="text"
                                maxLength={1024}
                                placeholder={`${t(Lang.LINE)} ${index + 1}`}
                                aria-label={t(Lang.LINES)}
                                aria-describedby={t(Lang.LINES)}
                                className={
                                    errors.lines && errors.lines[index]
                                        ? 'border-2 border-danger'
                                        : ''
                                }
                                {...register(`lines.${index}.text`, {
                                    maxLength: {
                                        value: 1024,
                                        message: t(Lang.MAX_LENGTH_FIELD, {
                                            field: t(Lang.LINES),
                                            value: 1024
                                        })
                                    }
                                })}
                            />
                        </InputGroup>
                    ))}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.HOST)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.host ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'server']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="host"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.HOST)}
                            aria-label={t(Lang.HOST)}
                            aria-describedby={t(Lang.HOST)}
                            className={errors.host ? 'border-2 border-danger' : ''}
                            {...register('host', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.HOST)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.HOST),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.host && <ErrorField message={errors.host.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PORT)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.port ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'network-wired']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="port"
                            type="number"
                            min={1}
                            max={65535}
                            placeholder={'* ' + t(Lang.PORT)}
                            aria-label={t(Lang.PORT)}
                            aria-describedby={t(Lang.PORT)}
                            className={errors.port ? 'border-2 border-danger' : ''}
                            {...register('port', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.PORT)
                                }) as string,
                                min: {
                                    value: 1,
                                    message: t(Lang.MIN_FIELD, {
                                        field: t(Lang.PORT),
                                        value: 1
                                    })
                                },
                                max: {
                                    value: 65535,
                                    message: t(Lang.MAX_FIELD, {
                                        field: t(Lang.PORT),
                                        value: 65535
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.port && <ErrorField message={errors.port.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PROTOCOL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.protocol
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'network-wired']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="protocol"
                            className={errors.protocol ? 'border-2 border-danger' : ''}
                            {...register('protocol', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.PROTOCOL)
                                }) as string
                            })}
                        >
                            {Object.keys(Protocol).map((protocol: string) => {
                                return (
                                    <option
                                        key={protocol}
                                        value={protocol}
                                    >{`* ${protocol}`}</option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.protocol && <ErrorField message={errors.protocol.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.CAMERA)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.camera
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'video']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="camera"
                            className={errors.camera ? 'border-2 border-danger' : ''}
                            {...register('camera')}
                        >
                            <option value="">{t(Lang.FEMALE_NONE)}</option>
                            {cameras.map((camera) => {
                                return (
                                    <option key={camera._id} value={camera._id}>
                                        {camera.name}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.TYPE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.type ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="type"
                            className={errors.type ? 'border-2 border-danger' : ''}
                            {...register('type')}
                        >
                            {Object.keys(PanelType).map((type: string) => {
                                return (
                                    <option key={type} value={type}>{`* ${t(
                                        langObj[type.toUpperCase()]
                                    )}`}</option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader loading={loading} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default PanelModal
