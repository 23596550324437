import { useEffect, useState, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Form, Row, Col, Button, Container, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import Loader from '../../components/Loader'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import * as Auth from '../../services/Authentication'
import * as URL from '../../routes/URL'
import ErrorField from '../../components/form/ErrorField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface RecoveryForm {
    username: string
    recoveryPassword: string
    newPassword: string
    repeatPassword: string
}

const RecoveryLayout = (): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const newPasswordRef = useRef<HTMLInputElement | null>(null)
    const repeatPasswordRef = useRef<HTMLInputElement | null>(null)

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<RecoveryForm>()

    const newPasswordRegister = register('newPassword', {
        minLength: {
            value: 8,
            message: t(Lang.MIN_LENGTH_FIELD, {
                field: t(Lang.NEW_PASSWORD),
                value: 8
            })
        },
        maxLength: {
            value: 256,
            message: t(Lang.MAX_LENGTH_FIELD, {
                field: t(Lang.NEW_PASSWORD),
                value: 256
            })
        }
    })

    const repeatPasswordRegister = register('repeatPassword', {
        required: t(Lang.REQUIRED_FIELD, {
            field: t(Lang.REPEAT_PASSWORD)
        }) as string,
        validate: (value) => {
            if (value.length || password.current)
                return value === password.current || (t(Lang.PASSWORDS_DO_NOT_MATCH) as string)
            else return true
        }
    })

    const password = useRef({})

    password.current = watch('newPassword', '')

    const username = searchParams.get('username')
    const recoveryPassword = searchParams.get('recoveryPassword')

    const handleSubmitRecovery = async (data: RecoveryForm): Promise<void> => {
        setLoading(true)
        let result = 0

        if (username && recoveryPassword)
            result = await Auth.recovery(username, recoveryPassword, false, data.newPassword)

        if (result === 0) toast.error(t(Lang.ERROR_RECOVERING_PASSWORD))
        if (result === 1) toast.success(t(Lang.PASSWORD_RECOVERED_SUCCESSFULLY))
        if (result === 2) toast.error(t(Lang.PASSWORD_ALREADY_USED))

        setLoading(false)
    }

    const handleHideShowPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (event.currentTarget.className === 'p-0 text-secondary btn btn-link') {
            event.currentTarget.className = 'p-0 text-primary btn btn-link'

            if (event.currentTarget.value === 'newPassword' && newPasswordRef.current)
                newPasswordRef.current.type = 'text'

            if (event.currentTarget.value === 'repeatPassword' && repeatPasswordRef.current)
                repeatPasswordRef.current.type = 'text'
        } else {
            event.currentTarget.className = 'p-0 text-secondary btn btn-link'

            if (event.currentTarget.value === 'newPassword' && newPasswordRef.current)
                newPasswordRef.current.type = 'password'

            if (event.currentTarget.value === 'repeatPassword' && repeatPasswordRef.current)
                repeatPasswordRef.current.type = 'password'
        }
    }

    useEffect(() => {
        const check = async () => {
            let isValid = 0

            if (username && recoveryPassword) {
                isValid = await Auth.recovery(username, recoveryPassword, true)
                setValue('username', username)
                setValue('recoveryPassword', recoveryPassword)
            }

            if (isValid === 0) navigate(URL.ROOT)
        }
        check()
    }, [])

    return (
        <Container>
            <Row className="my-5">
                <Col xl={{ span: 4, offset: 4 }}>
                    <img src="/img/logoZBE.png" className="w-100" />
                </Col>
            </Row>
            <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                    <Form onSubmit={handleSubmit(handleSubmitRecovery)}>
                        <h3 className="mb-5">{t(Lang.RECOVERY_PASSWORD)}</h3>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>{t(Lang.NEW_PASSWORD)}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="password"
                                    minLength={8}
                                    maxLength={256}
                                    placeholder={t(Lang.NEW_PASSWORD)}
                                    aria-label={t(Lang.NEW_PASSWORD)}
                                    aria-describedby={t(Lang.NEW_PASSWORD)}
                                    className={errors.newPassword ? 'border-2 border-danger' : ''}
                                    {...newPasswordRegister}
                                    ref={(e: unknown) => {
                                        newPasswordRegister.ref(e)
                                        newPasswordRef.current = e as HTMLInputElement
                                    }}
                                />
                                <InputGroup.Text>
                                    <Button
                                        variant="link"
                                        className="p-0 text-secondary"
                                        value="newPassword"
                                        onClick={handleHideShowPassword}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'eye']}
                                            viewBox="0 0 512 512"
                                        />
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        {errors.newPassword && <ErrorField message={errors.newPassword.message} />}
                        <Form.Group className="mb-5" controlId="repeatPassword">
                            <Form.Label>{t(Lang.REPEAT_PASSWORD)}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="password"
                                    minLength={8}
                                    maxLength={256}
                                    placeholder={t(Lang.REPEAT_PASSWORD)}
                                    aria-label={t(Lang.REPEAT_PASSWORD)}
                                    aria-describedby={t(Lang.REPEAT_PASSWORD)}
                                    className={
                                        errors.repeatPassword ? 'border-2 border-danger' : ''
                                    }
                                    {...repeatPasswordRegister}
                                    ref={(e: unknown) => {
                                        repeatPasswordRegister.ref(e)
                                        repeatPasswordRef.current = e as HTMLInputElement
                                    }}
                                />
                                <InputGroup.Text>
                                    <Button
                                        variant="link"
                                        className="p-0 text-secondary"
                                        value="repeatPassword"
                                        onClick={handleHideShowPassword}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'eye']}
                                            viewBox="0 0 512 512"
                                        />
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        {errors.repeatPassword && (
                            <ErrorField message={errors.repeatPassword.message} />
                        )}
                        <Button type="submit" variant="primary" className="btn-modal">
                            {loading ? (
                                <Loader loading={loading} size={25} color="#FFFFFF" />
                            ) : (
                                t(Lang.SAVE)
                            )}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default RecoveryLayout
