export enum Period {
    hours = 'hours',
    days = 'days',
    weeks = 'weeks',
    months = 'months',
    years = 'years'
}

export default interface IExpiration {
    _id?: string
    value: number
    period: Period
}
