import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/header/Header'
import Menu from '../../components/menu/Menu'
import IUser from '../../services/User/User.interface'
import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ErrorField from '../../components/form/ErrorField'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import Loader from '../../components/Loader'
import IZone from '../../services/Zone/Zone.interface'
import * as Zone from '../../services/Zone/Zone'
import ICamera, { Direction, DeviceStatus } from '../../services/Camera/Camera.interface'
import * as Camera from '../../services/Camera/Camera'
import IEvent, {
    EnvironmentLabel,
    carDirection,
    typeEvent
} from '../../services/Event/Event.interface'
import * as Event from '../../services/Event/Event'
import Highcharts, { Options, SeriesOptionsType, SeriesSankeyPointOptionsObject } from 'highcharts'
import Exporting from 'highcharts/modules/exporting'
import ExportingData from 'highcharts/modules/export-data'
import Sankey from 'highcharts/modules/sankey'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import * as Alert from '../../services/Alert/Alert'
import * as Panel from '../../services/Panel/Panel'
import ISensorType, { ISensorParameter } from '../../services/SensorType/SensorType.interface'
import * as SensorType from '../../services/SensorType/SensorType'
import ISensor from '../../services/Sensor/Sensor.interface'
import * as Sensor from '../../services/Sensor/Sensor'
import { ISensorData } from '../../services/SensorEvent/SensorEvent.interface'
import * as SensorEvent from '../../services/SensorEvent/SensorEvent'

interface AnalyticsLayoutProps {
    user?: IUser
}

export interface AnalyticsFilterForm {
    start?: string
    end?: string
    camera?: string
}

export interface AnalyticsSensorForm {
    start?: string
    end?: string
    sensorId?: string
}

const AnalyticsLayout = ({ user }: AnalyticsLayoutProps): JSX.Element => {
    const { t } = useTranslation()
    const [zones, setZones] = useState<IZone[]>([])
    const [zoneSelected, setZoneSelected] = useState<string | undefined>('')
    const [cameras, setCameras] = useState<ICamera[]>([])
    const [cameraSelected, setCameraSelected] = useState<ICamera>()
    const [fetchEvents, setFetchEvents] = useState(true)
    const [fetchSensorEvents, setFetchSensorEvents] = useState(true)
    //const [lastMonthOptions, setLastMonthOptions] = useState<Options>();
    const [lastWeekOptions, setLastWeekOptions] = useState<Options>()
    const [lastDayOptions, setLastDayOptions] = useState<Options>()
    const [lastHourOptions, setLastHourOptiohns] = useState<Options>()
    const [environmentLabelOptions, setEnvironmentLabelOptions] = useState<Options>()
    const [detectionsOptions, setDetectionsOptions] = useState<Options>()
    const [typeRegistryOptions, setTypeRegistryOptions] = useState<Options>()
    const [maxEntrance, setMaxEntrance] = useState<{
        date: string
        value: number
    }>()
    const [maxExit, setMaxExit] = useState<{ date: string; value: number }>()
    const [alertOptions, setAlertOptions] = useState<Options>()
    const inputPlate = useRef<HTMLInputElement | null>(null)
    const [tracing, setTracing] = useState<{
        [index: string]: {
            name: string
            direction: Direction
            detections: number
            percentage: number
        }
    }>()
    const [sankeyOptions, setSankeyOptions] = useState<Options>()
    const [devicesStatusOptions, setDevicesStatusOptions] = useState<Options>()
    const [sanctionsOptions, setSanctionsOptions] = useState<Options>()
    const [eventTypesOptions, setEventTypesOptions] = useState<Options>()

    const [sensorsType, setSensorsType] = useState<ISensorType[]>([])
    const [sensorTypeSelected, setSensorTypeSelected] = useState<ISensorType>()

    const [sensors, setSensors] = useState<ISensor[]>([])
    const [sensorSelected, setSensorSelected] = useState<string | undefined>('')

    const [sensorsOptions, setSensorsOptions] = useState<Options>()

    const [parameters, setParameters] = useState<ISensorParameter[]>([])
    const [parameterSelected, setParameterSelected] = useState<ISensorParameter>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const {
        register: registerEvent,
        handleSubmit: handleSubmitEvent,
        formState: { errors: errorsEvent }
    } = useForm<AnalyticsFilterForm>()

    const {
        register: registerSensor,
        handleSubmit: handleSubmitSensor,
        formState: { errors: errorsSensor }
    } = useForm<AnalyticsSensorForm>()

    Exporting(Highcharts)
    ExportingData(Highcharts)
    Sankey(Highcharts)

    const lang = {
        downloadPDF: t(Lang.DOWNLOAD_PDF),
        downloadCSV: t(Lang.DOWNLOAD_CSV),
        downloadJPEG: t(Lang.DOWNLOAD_JPEG),
        downloadPNG: t(Lang.DOWNLOAD_PNG),
        downloadSVG: t(Lang.DOWNLOAD_SVG),
        downloadXLS: t(Lang.DOWNLOAD_XLS),
        printChart: t(Lang.PRINT_CHART),
        viewFullscreen: t(Lang.VIEW_FULL_SCREEN),
        exitFullscreen: t(Lang.EXIT_FULL_SCREEN),
        viewData: t(Lang.VIEW_DATA),
        hideData: t(Lang.HIDE_DATA)
    }

    const triggerEventFetch = () => setFetchEvents((t) => !t)

    const triggerSensorFetch = () => setFetchSensorEvents((t) => !t)

    const handleChangeZone = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setZoneSelected(event.target.value)
        triggerEventFetch()
    }

    const intervalHours = (start: Date, end: Date): string[] => {
        const results: string[] = []

        while (start <= end) {
            results.push(moment(start).format('DD/MM/YYYY HH') + ':00')
            start = moment(start).add(1, 'h').toDate()
        }

        return results
    }

    const handleTracing = async () => {
        const plate = inputPlate.current?.value

        if (!plate) return

        const eventsPlate = await Event.getEvents({ plate, select: 'camera' })

        if (!eventsPlate) return

        const registries: {
            [index: string]: {
                name: string
                direction: Direction
                detections: number
                percentage: number
            }
        } = {}

        for (const eventPlate of eventsPlate)
            if (eventPlate.camera)
                registries[eventPlate.camera] = {
                    name: '',
                    direction: Direction.entry,
                    detections: 0,
                    percentage: 0
                }

        for (const camera of cameras) {
            if (camera._id) {
                if (registries[camera._id]) {
                    registries[camera._id].name = camera.name
                    registries[camera._id].direction = camera.direction
                }
            }
        }

        for (const eventPlate of eventsPlate)
            if (eventPlate.camera) registries[eventPlate.camera].detections++

        for (const registry of Object.values(registries))
            registry.percentage = (registry.detections / eventsPlate.length) * 100

        setTracing(registries)

        let registriesArray = Object.values(registries)
        registriesArray = registriesArray.reverse()

        const data: SeriesSankeyPointOptionsObject[] = []

        for (let i = 0; i < registriesArray.length; i++) {
            if (registriesArray[i + 1]) {
                data.push({
                    from: registriesArray[i].name,
                    to: registriesArray[i + 1].name,
                    weight: registriesArray[i].detections
                })
            }
        }

        const options: Options = {
            title: {
                text: t(Lang.VEHICLE_TRACEABILITY)
            },
            chart: {
                type: 'sankey'
            },
            exporting: {
                enabled: true,
                filename: t(Lang.VEHICLE_TRACEABILITY)
            },
            lang,
            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
                }
            },
            series: [
                {
                    type: 'sankey',
                    keys: ['from', 'to', 'weight'],
                    data: data
                }
            ]
        }

        setSankeyOptions(options)
    }

    const SubmitEvent = async (data: AnalyticsFilterForm) => {
        if (!data.start || !data.end || !data.camera) return

        const cameraFiltered = cameras.filter((cam) => cam._id === data.camera)

        const camera: ICamera = cameraFiltered[0]

        setCameraSelected(camera)

        const intervalsHour = intervalHours(new Date(data.start), new Date(data.end))

        const filteredEvents = await Event.getEvents({
            start: moment(data.start).utc().format('YYYY-MM-DD HH:mm'),
            end: moment(data.end).utc().format('YYYY-MM-DD HH:mm'),
            camera: camera._id,
            select: 'direction createdAt type'
        })

        if (!filteredEvents) return

        const detections: { [index: string]: number } = {}
        const entrances: { [index: string]: number } = {}
        const exits: { [index: string]: number } = {}

        for (const intervalHour of intervalsHour) {
            detections[intervalHour] = 0
            entrances[intervalHour] = 0
            exits[intervalHour] = 0
        }

        for (const filteredEvent of filteredEvents) {
            const filteredCreatedAt =
                moment(filteredEvent.createdAt).format('DD/MM/YYYY HH') + ':00'
            if (filteredEvent.direction === carDirection.in) entrances[filteredCreatedAt]++

            if (filteredEvent.direction === carDirection.out) exits[filteredCreatedAt]++

            detections[filteredCreatedAt]++
        }

        const options: Options = {
            title: {
                text: t(Lang.DETECTIONS)
            },
            subtitle: {
                text: `${t(Lang.FROM)} ${moment(data.start).format(
                    'DD/MM/YYYY HH:mm'
                )} ${t(Lang.TO)} ${moment(data.end).format('DD/MM/YYYY HH:mm')}`
            },
            chart: {
                type: 'column'
            },
            exporting: {
                enabled: true,
                filename: t(Lang.DETECTIONS)
            },
            lang,
            xAxis: {
                categories: intervalsHour,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: t(Lang.NUMBER_OF_DETECTIONS)
                }
            },
            series: [
                {
                    name: t(Lang.DETECTIONS),
                    type: 'column',
                    data: Object.values(detections)
                },
                {
                    name: t(Lang.ENTRANCES),
                    type: 'column',
                    data: Object.values(entrances)
                },
                {
                    name: t(Lang.EXITS),
                    type: 'column',
                    data: Object.values(exits)
                }
            ],
            accessibility: { enabled: false }
        }

        setDetectionsOptions(options)

        const possibleInfringement: { [index: string]: number } = {}
        const invalidated: { [index: string]: number } = {}
        const authorized: { [index: string]: number } = {}
        const unauthorized: { [index: string]: number } = {}

        for (const intervalHour of intervalsHour) {
            possibleInfringement[intervalHour] = 0
            invalidated[intervalHour] = 0
            authorized[intervalHour] = 0
            unauthorized[intervalHour] = 0
        }

        for (const filteredEvent of filteredEvents) {
            const filteredCreatedAt =
                moment(filteredEvent.createdAt).format('DD/MM/YYYY HH') + ':00'
            if (filteredEvent.type === typeEvent.possibleInfringement)
                possibleInfringement[filteredCreatedAt]++

            if (filteredEvent.type === typeEvent.invalidated) invalidated[filteredCreatedAt]++

            if (filteredEvent.type === typeEvent.authorized) authorized[filteredCreatedAt]++

            if (filteredEvent.type === typeEvent.unauthorized) unauthorized[filteredCreatedAt]++
        }

        if (
            options.title &&
            options.chart &&
            options.series &&
            options.yAxis &&
            options.exporting
        ) {
            options.title.text = t(Lang.TYPE_OF_REGISTRY)
            options.chart.type = 'line'
            options.yAxis = {
                min: 0,
                title: {
                    text: t(Lang.NUMBER_OF_REGISTRIES)
                }
            }
            options.series = [
                {
                    name: t(Lang.POSSIBLEINFRINGEMENT),
                    type: 'line',
                    data: Object.values(possibleInfringement)
                },
                {
                    name: t(Lang.INVALIDATED),
                    type: 'line',
                    data: Object.values(invalidated)
                },
                {
                    name: t(Lang.AUTHORIZED),
                    type: 'line',
                    data: Object.values(authorized)
                },
                {
                    name: t(Lang.UNAUTHORIZED),
                    type: 'line',
                    data: Object.values(unauthorized)
                }
            ]
            options.exporting.filename = t(Lang.POSSIBLEINFRINGEMENT)
        }

        setTypeRegistryOptions(options)

        const maxEntries: { date: string; value: number } = {
            date: moment().utc().format('DD/MM/YYYY HH:mm'),
            value: -1
        }

        for (const [index, entrance] of Object.entries(entrances)) {
            if (entrance > maxEntries.value) {
                maxEntries.date = index
                maxEntries.value = entrance
            }
        }

        setMaxEntrance(maxEntries)

        const maxExits: { date: string; value: number } = {
            date: moment().utc().format('DD/MM/YYYY HH:mm'),
            value: -1
        }

        for (const [index, exit] of Object.entries(exits)) {
            if (exit > maxExits.value) {
                maxExits.date = index
                maxExits.value = exit
            }
        }

        setMaxExit(maxExits)

        const alerts = await Alert.getAlerts({
            camera: camera._id,
            start: data.start,
            end: data.end
        })

        if (!alerts) return

        const alertHours: { [index: string]: number } = {}

        for (const intervalHour of intervalsHour) alertHours[intervalHour] = 0

        for (const alert of alerts)
            if (alert.createdAt)
                alertHours[moment(alert.createdAt).format('DD/MM/YYYY HH') + ':00']++

        if (
            options.title &&
            options.chart &&
            options.series &&
            options.yAxis &&
            options.exporting
        ) {
            options.title.text = t(Lang.ALERTS)
            options.chart.type = 'column'
            options.yAxis = {
                min: 0,
                title: {
                    text: t(Lang.NUMBER_OF_ALERTS)
                }
            }
            options.xAxis = {
                categories: intervalsHour,
                crosshair: true
            }
            options.series = [
                {
                    name: t(Lang.ALERTS),
                    type: 'column',
                    data: Object.values(alertHours)
                }
            ]
            options.exporting.filename = t(Lang.ALERTS)
        }

        setAlertOptions(options)
    }

    useEffect(() => {
        const load = async () => {
            const zones: IZone[] | false = await Zone.getZones()

            if (!zones) return

            setZones(zones)

            const zoneId = zoneSelected || ''

            const cameras: ICamera[] | false = await Camera.getCameras(zoneId ? zoneId : undefined)

            if (!cameras) return

            setCameras(cameras)

            const now = moment().utc()
            const end = now.format('YYYY-MM-DD HH:mm:ss')
            const start = now.subtract(1, 'week').format('YYYY-MM-DD HH:mm:ss')

            const select = 'camera type createdAt environmentLabel'

            const events: IEvent[] | false = await Event.getEvents(
                zoneId ? { zone: zoneId, start, end, select } : { start, end, select }
            )

            if (!events) return

            const dataLast: {
                [index: string]: { name: string; value: number }
            } = {}

            /*const lastMonthEvents = events.filter((event) =>
                event.createdAt
                    ? moment(event.createdAt).toDate() >
                      moment().subtract(30, 'days').toDate()
                    : false
            );

            for (const camera of cameras)
                if (camera._id)
                    dataLast[camera._id] = { name: camera.name, value: 0 };

            for (const lastMonthEvent of lastMonthEvents)
                if (lastMonthEvent.camera)
                    if (dataLast[lastMonthEvent.camera])
                        dataLast[lastMonthEvent.camera].value++;

            /*let options: Options = {
                title: {
                    text: t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_MONTH),
                },
                subtitle: {
                    text: `${t(Lang.FROM)} ${moment()
                        .subtract(30, 'days')
                        .format('DD/MM/YYYY HH:mm')} ${t(
                        Lang.TO
                    )} ${moment().format('DD/MM/YYYY HH:mm')}`,
                },
                chart: {
                    type: 'column',
                },
                exporting: {
                    enabled: true,
                    filename: t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_MONTH),
                },
                lang,
                xAxis: {
                    categories: cameras.map((camera) => camera.name),
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: t(Lang.NUMBER_OF_VEHICLES),
                    },
                },
                series: [
                    {
                        name: t(Lang.NUMBER_OF_VEHICLES),
                        type: 'column',
                        data: Object.values(dataLast).map((item) => item.value),
                    },
                ],
                accessibility: { enabled: false },
            };

            setLastMonthOptions(options);*/

            /*const lastWeekEvents = events.filter((event) =>
                event.createdAt
                    ? moment(event.createdAt).toDate() >
                      moment().subtract(7, 'days').toDate()
                    : false
            );*/

            const lastWeekEvents = events

            for (const camera of cameras)
                if (camera._id) dataLast[camera._id] = { name: camera.name, value: 0 }

            for (const lastWeekEvent of lastWeekEvents)
                if (lastWeekEvent.camera)
                    if (dataLast[lastWeekEvent.camera]) dataLast[lastWeekEvent.camera].value++

            /*if (
                options.title &&
                options.subtitle &&
                options.series &&
                options.series.length &&
                options.exporting
            ) {
                options.title.text = t(
                    Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_WEEK
                );
                options.subtitle.text = `${t(Lang.FROM)} ${moment()
                    .subtract(7, 'days')
                    .format('DD/MM/YYYY HH:mm')} ${t(
                    Lang.TO
                )} ${moment().format('DD/MM/YYYY HH:mm')}`;
                options.series[0] = {
                    name: t(Lang.NUMBER_OF_VEHICLES),
                    type: 'column',
                    data: Object.values(dataLast).map((item) => item.value),
                };

                options.exporting.filename = t(
                    Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_WEEK
                );
            }*/

            let options: Options = {
                title: {
                    text: t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_WEEK)
                },
                subtitle: {
                    text: `${t(Lang.FROM)} ${moment()
                        .subtract(7, 'days')
                        .format('DD/MM/YYYY HH:mm')} ${t(
                        Lang.TO
                    )} ${moment().format('DD/MM/YYYY HH:mm')}`
                },
                chart: {
                    type: 'column'
                },
                exporting: {
                    enabled: true,
                    filename: t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_WEEK)
                },
                lang,
                xAxis: {
                    categories: cameras.map((camera) => camera.name),
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: t(Lang.NUMBER_OF_VEHICLES)
                    }
                },
                series: [
                    {
                        name: t(Lang.NUMBER_OF_VEHICLES),
                        type: 'column',
                        data: Object.values(dataLast).map((item) => item.value)
                    }
                ],
                accessibility: { enabled: false }
            }

            setLastWeekOptions(options)

            const subtract1day = moment().utc().subtract(1, 'days')

            const lastDayEvents = lastWeekEvents.filter((event) =>
                event.createdAt ? moment(event.createdAt) > subtract1day : false
            )

            if (!lastDayEvents) return

            for (const camera of cameras)
                if (camera._id) dataLast[camera._id] = { name: camera.name, value: 0 }

            for (const lastDayEvent of lastDayEvents)
                if (lastDayEvent.camera)
                    if (dataLast[lastDayEvent.camera]) dataLast[lastDayEvent.camera].value++

            if (
                options.title &&
                options.subtitle &&
                options.series &&
                options.series.length &&
                options.exporting
            ) {
                options.title.text = t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_DAY)
                options.subtitle.text = `${t(Lang.FROM)} ${moment()
                    .subtract(1, 'days')
                    .format('DD/MM/YYYY HH:mm')} ${t(
                    Lang.TO
                )} ${moment().format('DD/MM/YYYY HH:mm')}`
                options.series[0] = {
                    name: t(Lang.NUMBER_OF_VEHICLES),
                    type: 'column',
                    data: Object.values(dataLast).map((item) => item.value)
                }
                options.exporting.filename = t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_DAY)
            }

            setLastDayOptions(options)

            const subctract1hour = moment().utc().subtract(1, 'hour')

            const lastHourEvents = lastDayEvents.filter((event) =>
                event.createdAt ? moment(event.createdAt) > subctract1hour : false
            )

            for (const camera of cameras)
                if (camera._id) dataLast[camera._id] = { name: camera.name, value: 0 }

            for (const lastHourEvent of lastHourEvents)
                if (lastHourEvent.camera)
                    if (dataLast[lastHourEvent.camera]) dataLast[lastHourEvent.camera].value++

            if (
                options.title &&
                options.subtitle &&
                options.series &&
                options.series.length &&
                options.exporting
            ) {
                options.title.text = t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_HOUR)
                options.subtitle.text = `${t(Lang.FROM)} ${moment()
                    .subtract(1, 'hour')
                    .format('HH:mm')} ${t(Lang.TO)} ${moment().format('HH:mm')}`
                options.series[0] = {
                    name: t(Lang.NUMBER_OF_VEHICLES),
                    type: 'column',
                    data: Object.values(dataLast).map((item) => item.value)
                }
                options.exporting.filename = t(Lang.NUMBER_OF_VEHICLES_DETECTED_LAST_HOUR)
            }

            setLastHourOptiohns(options)

            const totalEvents = events.length

            const dataEnvironments: {
                [index: string]: { name: string; y: number; color: string }
            } = {}

            const environmentalColor: { [index: string]: string } = {}

            for (const label of Object.keys(EnvironmentLabel)) {
                if (label === EnvironmentLabel.C) environmentalColor[label] = '#6db43e'

                if (label === EnvironmentLabel.B) environmentalColor[label] = '#fdf001'

                if (label === EnvironmentLabel.zero) environmentalColor[label] = '#0179b5'

                if (label === EnvironmentLabel.Eco) environmentalColor[label] = '#a6c96a'

                if (label === EnvironmentLabel.without) environmentalColor[label] = '#c42525'

                if (label === EnvironmentLabel.foreign) environmentalColor[label] = '#492970'

                if (label === EnvironmentLabel.fail) environmentalColor[label] = 'black'
            }

            for (const label of Object.keys(EnvironmentLabel)) {
                dataEnvironments[label] = {
                    name: t(langObj[label.toUpperCase()]),
                    y: 0,
                    color: environmentalColor[label]
                }
            }

            for (const event of events)
                if (event.environmentLabel) dataEnvironments[event.environmentLabel].y++

            for (const label of Object.keys(EnvironmentLabel)) {
                dataEnvironments[label].y = (dataEnvironments[label].y / totalEvents) * 100
            }

            options = {
                title: {
                    text: t(Lang.PROPORTION_VEHICLES_ENVIRONMENT_LABEL)
                },
                chart: {
                    type: 'pie'
                },
                exporting: {
                    enabled: true,
                    filename: t(Lang.PROPORTION_VEHICLES_ENVIRONMENT_LABEL)
                },
                lang,
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: { enabled: false },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [
                    {
                        name: t(Lang.ENVIRONMENT_LABEL),
                        type: 'pie',
                        data: Object.values(dataEnvironments)
                    }
                ]
            }

            setEnvironmentLabelOptions(options)

            const onlineCameras = cameras.filter(
                (camera) =>
                    camera.status === DeviceStatus.online ||
                    camera.status === DeviceStatus.receiving
            ).length

            const offlineCameras = cameras.filter(
                (camera) => camera.status === DeviceStatus.offline
            ).length

            const panels = await Panel.getPanels()

            let onlinePanels = 0
            let offlinePanels = 0

            if (panels) {
                onlinePanels = panels.filter(
                    (panel) =>
                        panel.status === DeviceStatus.online ||
                        panel.status === DeviceStatus.receiving
                ).length
                offlinePanels = panels.filter(
                    (panel) => panel.status === DeviceStatus.offline
                ).length
            }

            const sensors = await Sensor.getSensors()

            let onlineSensors = 0
            let offlineSensors = 0

            if (sensors) {
                onlineSensors = sensors.filter(
                    (sensor) =>
                        sensor.status === DeviceStatus.online ||
                        sensor.status === DeviceStatus.receiving
                ).length

                offlineSensors = sensors.filter(
                    (sensor) => sensor.status === DeviceStatus.offline
                ).length
            }

            options = {
                title: {
                    text: t(Lang.AVAILABILITY_AND_STATUS_OF_EQUIPMENT)
                },
                chart: {
                    type: 'column'
                },
                exporting: {
                    enabled: true,
                    filename: t(Lang.AVAILABILITY_AND_STATUS_OF_EQUIPMENT)
                },
                lang,
                xAxis: {
                    categories: [t(Lang.CAMERAS), t(Lang.PANELS), t(Lang.SENSORS)]
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: t(Lang.NUMBER_OF_DEVICES)
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: t(Lang.DISCONNECTED),
                        data: [offlineCameras, offlinePanels, offlineSensors],
                        type: 'column',
                        color: 'red'
                    },
                    {
                        name: t(Lang.CONNECTED),
                        data: [onlineCameras, onlinePanels, onlineSensors],
                        type: 'column',
                        color: 'green'
                    }
                ]
            }

            setDevicesStatusOptions(options)

            const data = [
                {
                    name: t(Lang.POSSIBLEINFRINGEMENT),
                    color: 'orange',
                    y: events.filter((event) => event.type === typeEvent.possibleInfringement)
                        .length
                },
                {
                    name: t(Lang.SANCTIONED),
                    color: 'purple',
                    y: events.filter((event) => event.type === typeEvent.sanctioned).length
                },
                {
                    name: t(Lang.INVALIDATED),
                    color: 'grey',
                    y: events.filter((event) => event.type === typeEvent.invalidated).length
                }
            ]

            options = {
                title: {
                    text: t(Lang.SANCTIONS_BY_TYPES_AND_VALIDATIONS)
                },
                chart: {
                    type: 'pie'
                },
                exporting: {
                    enabled: true,
                    filename: t(Lang.SANCTIONS_BY_TYPES_AND_VALIDATIONS)
                },
                lang,
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: { enabled: false },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [
                    {
                        name: t(Lang.SANCTIONS),
                        type: 'pie',
                        data: Object.values(data)
                    }
                ]
            }

            setSanctionsOptions(options)

            if (options.title && options.series && options.exporting) {
                options.title.text = t(Lang.TYPE_OF_REGISTRY)
                options.exporting.filename = t(Lang.TYPE_OF_REGISTRY)

                data.push({
                    name: t(Lang.UNAUTHORIZED),
                    color: 'red',
                    y: events.filter((event) => event.type === typeEvent.unauthorized).length
                })

                data.push({
                    name: t(Lang.AUTHORIZED),
                    color: 'green',
                    y: events.filter((event) => event.type === typeEvent.authorized).length
                })

                options.series = [
                    {
                        name: t(Lang.TYPE_OF_REGISTRY),
                        type: 'pie',
                        data: Object.values(data)
                    }
                ]

                setEventTypesOptions(options)
            }
        }
        load()
    }, [fetchEvents])

    useEffect(() => {
        const loadSensorEvents = async () => {
            const sensorsTypes = await SensorType.getSensorTypes()

            if (!sensorsTypes) return

            setSensorsType(sensorsTypes)

            if (!sensorTypeSelected && sensorsTypes.length > 0) {
                setSensorTypeSelected(sensorsTypes[0])
                setParameters(sensorsTypes[0].parameters)
            }

            const allSensors = await Sensor.getSensors()

            if (!allSensors) return

            let sensors: ISensor[] = []

            if (sensorTypeSelected)
                sensors = allSensors.filter((sensor) => sensor.type === sensorTypeSelected._id)
            else sensors = allSensors.filter((sensor) => sensor.type === sensorsTypes[0]._id)

            if (!sensors.length) return

            if (sensorSelected === undefined || !sensorSelected.length)
                setSensorSelected(sensors[0]._id)

            if (!parameterSelected) {
                if (sensorTypeSelected) setParameterSelected(sensorTypeSelected.parameters[0])
                else setParameterSelected(sensorsTypes[0].parameters[0])
            }

            setSensors(sensors)
        }

        loadSensorEvents()
    }, [fetchSensorEvents])

    const handleChangeSensorType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        if (!sensorsType.length) return

        const typeSelected = sensorsType.filter(
            (sensorType) => sensorType._id === event.target.value
        )

        if (!typeSelected.length) return

        setSensorTypeSelected(typeSelected[0])
        setParameters(typeSelected[0].parameters)
        triggerSensorFetch()
    }

    const handleChangeParameter = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        if (!parameters.length) return

        const parameterSelected = parameters.filter(
            (parameter) => parameter._id === event.target.value
        )

        if (!parameterSelected.length) return

        setParameterSelected(parameterSelected[0])
        triggerSensorFetch()
    }

    const SubmitSensor = async (data: AnalyticsSensorForm) => {
        if (!data.start || !data.end) return

        const filter: AnalyticsSensorForm = {
            start: moment(data.start).utc().format('YYYY-MM-DD HH:mm'),
            end: moment(data.end).utc().format('YYYY-MM-DD HH:mm')
        }

        if (data.sensorId?.length) filter['sensorId'] = data.sensorId

        const events = await SensorEvent.getSensorEvents(filter)

        if (!events) return

        const seriesAux: {
            [index: string]: {
                name: string
                type: string
                data: [number, number][]
            }
        } = {}

        for (const event of events) {
            const sensorsEvent = sensors.filter((sensor) => sensor._id === event.sensorId)

            if (!sensorsEvent.length) return

            const sensor: ISensor = sensorsEvent[0]

            const measurements: ISensorData[] = event.data.filter(
                (data) => data.name === parameterSelected?.name
            )

            if (event.createdAt && measurements.length) {
                if (seriesAux[event.sensorId]) {
                    seriesAux[event.sensorId] = {
                        name: sensor.name,
                        type: 'line',
                        data: [
                            ...seriesAux[event.sensorId].data,
                            [moment(event.createdAt).valueOf(), measurements[0].value]
                        ]
                    }
                } else
                    seriesAux[event.sensorId] = {
                        name: sensor.name,
                        type: 'line',
                        data: [[moment(event.createdAt).valueOf(), measurements[0].value]]
                    }
            }
        }

        const series: SeriesOptionsType[] = []

        for (const sAux of Object.values(seriesAux)) {
            series.push({
                name: sAux.name,
                type: 'line',
                data: sAux.data
            })
        }

        const options: Options = {
            title: {
                text: t(Lang.SENSOR_EVENTS)
            },
            subtitle: {
                text: `${t(Lang.FROM)} ${moment(data.start).format(
                    'DD/MM/YYYY HH:mm'
                )} ${t(Lang.TO)} ${moment(data.end).format('DD/MM/YYYY HH:mm')}`
            },
            chart: {
                type: 'line'
            },
            exporting: {
                enabled: true,
                filename: t(Lang.SENSOR_EVENTS)
            },
            lang,
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    minute: '%d/%m/%Y %H:%M'
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                labels: {
                    rotation: -45
                }
            },
            yAxis: {
                title: {
                    text: `${parameterSelected?.name} (${parameterSelected?.units})`
                }
            },
            series: series,
            tooltip: {
                formatter: function () {
                    return `${moment(this.x).format(
                        'DD/MM/YYYY HH:mm:ss'
                    )}</br>${this.series.name}: <b>${this.y} ${parameterSelected?.units}</b>`
                }
            },
            accessibility: { enabled: false }
        }

        setSensorsOptions(options)
    }

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-lg-block">
                    {user ? <Menu currentPage={t(Lang.ANALYTICS)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-4 p-xl-3 pe-xl-4">
                    <Row className="mb-5">
                        <Col xs="12" xl="4">
                            <Form.Group as={Row}>
                                <Col xs="12">
                                    <Form.Select onChange={handleChangeZone}>
                                        <option value="">{t(Lang.FEMALE_ALL)}</option>
                                        {zones.map((zone) => {
                                            return (
                                                <option key={zone._id} value={zone._id}>
                                                    {zone.name}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {lastHourOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={lastHourOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {lastDayOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={lastDayOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {lastWeekOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={lastWeekOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {environmentLabelOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={environmentLabelOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded p-3">
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>{t(Lang.PLATE)}</Tooltip>}
                                            >
                                                <InputGroup.Text className="text-white bg-primary">
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'font']}
                                                        viewBox="0 0 512 512"
                                                        fixedWidth
                                                    />
                                                </InputGroup.Text>
                                            </OverlayTrigger>
                                            <Form.Control
                                                id="plate"
                                                type="text"
                                                ref={inputPlate}
                                                placeholder={t(Lang.PLATE)}
                                                required
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" onClick={handleTracing}>
                                            {t(Lang.ANALYZE)}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                {tracing ? (
                                                    <Table
                                                        responsive
                                                        striped
                                                        hover
                                                        variant="primary"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>{t(Lang.CAMERA)}</th>
                                                                <th>{t(Lang.DIRECTION)}</th>
                                                                <th>{t(Lang.DETECTIONS)}</th>
                                                                <th>{t(Lang.PERCENTAGE)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.values(tracing).map(
                                                                (track, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{track.name}</td>
                                                                            <td>
                                                                                {t(
                                                                                    langObj[
                                                                                        track.direction.toUpperCase()
                                                                                    ]
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {track.detections}
                                                                            </td>
                                                                            <td>{`${track.percentage.toFixed(
                                                                                2
                                                                            )}%`}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {sankeyOptions ? (
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={sankeyOptions}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {devicesStatusOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={devicesStatusOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {sanctionsOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={sanctionsOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {eventTypesOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={eventTypesOptions}
                                    />
                                ) : (
                                    <Loader loading={true} size={100} color="#0d6efd" />
                                )}
                            </div>
                        </Col>
                    </Row>
                    {cameras.length ? (
                        <Row className="mb-5">
                            <Col>
                                <Form onSubmit={handleSubmitEvent(SubmitEvent)}>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400
                                                    }}
                                                    overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                                                >
                                                    <InputGroup.Text
                                                        className={`text-white ${
                                                            errorsEvent.start
                                                                ? 'bg-danger border-2 border-danger'
                                                                : 'bg-primary'
                                                        }`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'hourglass-start']}
                                                            viewBox="0 0 512 512"
                                                            fixedWidth
                                                        />
                                                    </InputGroup.Text>
                                                </OverlayTrigger>
                                                <Form.Control
                                                    type="datetime-local"
                                                    required
                                                    {...registerEvent('start', {
                                                        required: t(Lang.REQUIRED_FIELD, {
                                                            field: t(Lang.START)
                                                        }) as string
                                                    })}
                                                />
                                            </InputGroup>
                                            {errorsEvent.start && (
                                                <ErrorField message={errorsEvent.start.message} />
                                            )}
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400
                                                    }}
                                                    overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                                                >
                                                    <InputGroup.Text
                                                        className={`text-white ${
                                                            errorsEvent.end
                                                                ? 'bg-danger border-2 border-danger'
                                                                : 'bg-primary'
                                                        }`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'hourglass-end']}
                                                            viewBox="0 0 512 512"
                                                            fixedWidth
                                                        />
                                                    </InputGroup.Text>
                                                </OverlayTrigger>
                                                <Form.Control
                                                    id="end"
                                                    type="datetime-local"
                                                    required
                                                    {...registerEvent('end', {
                                                        required: t(Lang.REQUIRED_FIELD, {
                                                            field: t(Lang.END)
                                                        }) as string
                                                    })}
                                                />
                                            </InputGroup>
                                            {errorsEvent.end && (
                                                <ErrorField message={errorsEvent.end.message} />
                                            )}
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400
                                                    }}
                                                    overlay={<Tooltip>{t(Lang.CAMERA)}</Tooltip>}
                                                >
                                                    <InputGroup.Text
                                                        className={`text-white ${
                                                            errorsEvent.camera
                                                                ? 'bg-danger border-2 border-danger'
                                                                : 'bg-primary'
                                                        }`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'video']}
                                                            viewBox="0 0 512 512"
                                                            fixedWidth
                                                        />
                                                    </InputGroup.Text>
                                                </OverlayTrigger>
                                                <Form.Select
                                                    id="camera"
                                                    required
                                                    {...registerEvent('camera', {
                                                        required: t(Lang.REQUIRED_FIELD, {
                                                            field: t(Lang.CAMERA)
                                                        }) as string,
                                                        maxLength: {
                                                            value: 32,
                                                            message: t(Lang.MAX_LENGTH_FIELD, {
                                                                field: t(Lang.CAMERA),
                                                                value: 32
                                                            })
                                                        }
                                                    })}
                                                >
                                                    {cameras.map((camera) => {
                                                        return (
                                                            <option
                                                                key={camera._id}
                                                                value={camera._id}
                                                            >
                                                                {camera.name}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </InputGroup>
                                            {errorsEvent.camera && (
                                                <ErrorField message={errorsEvent.camera.message} />
                                            )}
                                        </Col>
                                        <Col>
                                            <Button type="submit" variant="primary">
                                                {t(Lang.ANALYZE)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <Row className="mb-5">
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {detectionsOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={detectionsOptions}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {typeRegistryOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={typeRegistryOptions}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            {cameraSelected ? (
                                <div className="shadow rounded p-3 text-center">
                                    <h5>{t(Lang.RUSH_HOUR)}</h5>
                                    <Table responsive striped hover variant="primary">
                                        <thead>
                                            <tr>
                                                <th>{t(Lang.CAMERA)}</th>
                                                <th>{t(Lang.RUSH_HOUR_ENTRANCE)}</th>
                                                <th>{t(Lang.NUMBER_OF_VEHICLES)}</th>
                                                <th>{t(Lang.RUSH_HOUR_EXIT)}</th>
                                                <th>{t(Lang.NUMBER_OF_VEHICLES)}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{cameraSelected.name}</td>
                                                <td>{maxEntrance?.date}</td>
                                                <td>{maxEntrance?.value}</td>
                                                <td>{maxExit?.date}</td>
                                                <td>{maxExit?.value}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col xs={12} xl={6} className="mb-3">
                            <div className="shadow rounded">
                                {alertOptions ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={alertOptions}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmitSensor(SubmitSensor)}>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>{t(Lang.SENSOR_TYPE)}</Tooltip>}
                                            >
                                                <InputGroup.Text className="text-white bg-primary">
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'cubes']}
                                                        viewBox="0 0 512 512"
                                                        fixedWidth
                                                    />
                                                </InputGroup.Text>
                                            </OverlayTrigger>
                                            <Form.Select
                                                id="type"
                                                onChange={handleChangeSensorType}
                                            >
                                                {sensorsType.map((sensorType: ISensorType) => {
                                                    return (
                                                        <option
                                                            key={sensorType._id}
                                                            value={sensorType._id}
                                                        >
                                                            {sensorType.name}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>{t(Lang.SENSORS)}</Tooltip>}
                                            >
                                                <InputGroup.Text className="text-white bg-primary">
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'memory']}
                                                        viewBox="0 0 512 512"
                                                        fixedWidth
                                                    />
                                                </InputGroup.Text>
                                            </OverlayTrigger>
                                            <Form.Select {...registerSensor('sensorId')}>
                                                <option key="" value="">
                                                    {t(Lang.ALL)}
                                                </option>
                                                {sensors.map((sensor: ISensor) => {
                                                    return (
                                                        <option key={sensor._id} value={sensor._id}>
                                                            {sensor.name}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>{t(Lang.PARAMETERS)}</Tooltip>}
                                            >
                                                <InputGroup.Text className="text-white bg-primary">
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'gear']}
                                                        viewBox="0 0 512 512"
                                                        fixedWidth
                                                    />
                                                </InputGroup.Text>
                                            </OverlayTrigger>
                                            <Form.Select
                                                id="parameters"
                                                onChange={handleChangeParameter}
                                            >
                                                {parameters.map((parameter: ISensorParameter) => {
                                                    return (
                                                        <option
                                                            key={parameter._id}
                                                            value={parameter._id}
                                                        >
                                                            {parameter.name}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{
                                                    show: 250,
                                                    hide: 400
                                                }}
                                                overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                                            >
                                                <InputGroup.Text
                                                    className={`text-white ${
                                                        errorsSensor.start
                                                            ? 'bg-danger border-2 border-danger'
                                                            : 'bg-primary'
                                                    }`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'hourglass-start']}
                                                        viewBox="0 0 512 512"
                                                        fixedWidth
                                                    />
                                                </InputGroup.Text>
                                            </OverlayTrigger>
                                            <Form.Control
                                                type="datetime-local"
                                                required
                                                {...registerSensor('start', {
                                                    required: t(Lang.REQUIRED_FIELD, {
                                                        field: t(Lang.START)
                                                    }) as string
                                                })}
                                            />
                                        </InputGroup>
                                        {errorsSensor.start && (
                                            <ErrorField message={errorsSensor.start.message} />
                                        )}
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{
                                                    show: 250,
                                                    hide: 400
                                                }}
                                                overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                                            >
                                                <InputGroup.Text
                                                    className={`text-white ${
                                                        errorsSensor.end
                                                            ? 'bg-danger border-2 border-danger'
                                                            : 'bg-primary'
                                                    }`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'hourglass-end']}
                                                        viewBox="0 0 512 512"
                                                        fixedWidth
                                                    />
                                                </InputGroup.Text>
                                            </OverlayTrigger>
                                            <Form.Control
                                                id="end"
                                                type="datetime-local"
                                                required
                                                {...registerSensor('end', {
                                                    required: t(Lang.REQUIRED_FIELD, {
                                                        field: t(Lang.END)
                                                    }) as string
                                                })}
                                            />
                                        </InputGroup>
                                        {errorsSensor.end && (
                                            <ErrorField message={errorsSensor.end.message} />
                                        )}
                                    </Col>
                                    <Col>
                                        <Button type="submit" variant="primary">
                                            {t(Lang.ANALYZE)}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} xl={6} className="mb-3">
                                        <div className="shadow rounded">
                                            {sensorsOptions ? (
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={sensorsOptions}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default AnalyticsLayout
