import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IUser from '../../services/User/User.interface'
import * as Auth from '../../services/Authentication'
import { useNavigate } from 'react-router-dom'
import * as URL from '../../routes/URL'
import './Header.css'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import LanguageSelector from './LanguageSelector'
import Notification from './Notification'
import { REACT_APP_BACKEND_URL } from '../../configuration'
import { useEffect, useState } from 'react'
import * as Log from '../../services/Log/Log'
import { LogCategory } from '../../services/Log/Log.interface'
import moment from 'moment'

interface HeaderProps {
    user?: IUser
}

const Header = ({ user }: HeaderProps): JSX.Element => {
    const navigate = useNavigate()
    const [lastConnection, setLastConnection] = useState('')
    const { t } = useTranslation()

    const handleLogout = () => {
        Auth.logout()
        navigate(URL.ROOT)
    }

    useEffect(() => {
        const loadLastConnection = async () => {
            if (user) {
                const logs = await Log.getLogs({
                    user: user?.username,
                    category: LogCategory.login
                })

                if (logs && logs.length > 1)
                    setLastConnection(moment(logs[1].createdAt).format('DD/MM/YYYY HH:mm:ss'))
            }
        }

        loadLastConnection()
    }, [user])

    return (
        <Navbar bg="light" variant="light" className="border shadow w-100" expand={false}>
            <Container>
                <Navbar.Brand href={URL.HOME} className="col-6 col-sm-4 col-lg-3">
                    <img
                        alt=""
                        src="/img/logoZBE.png"
                        className="d-inline-block align-top col-12"
                    />
                </Navbar.Brand>
                <Nav className="justify-content-end flex-row align-items-center">
                    {lastConnection.length ? (
                        <span className="text-secondary me-2 h6">
                            {`${t(Lang.LAST_CONNECTION)}: ${lastConnection}`}
                        </span>
                    ) : (
                        ''
                    )}

                    <NavDropdown
                        className="me-3"
                        title={
                            <>
                                <FontAwesomeIcon
                                    icon={['fas', 'user']}
                                    size="2x"
                                    className="text-primary me-2"
                                    fixedWidth
                                />
                                <span className="d-none d-md-inline">{user?.username}</span>
                            </>
                        }
                    >
                        <NavDropdown.Item href={URL.PROFILE}>{t(Lang.PROFILE)}</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleLogout}>{t(Lang.LOGOUT)}</NavDropdown.Item>
                    </NavDropdown>
                    <LanguageSelector />
                    {user && user._id ? <Notification userId={user?._id} /> : ''}
                </Nav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-lg-none" />
                <Navbar.Collapse className="justify-content-start">
                    <Nav>
                        <Nav.Link href={URL.HOME}>{t(Lang.HOME)}</Nav.Link>
                        <Nav.Link href={URL.REAL_TIME}>{t(Lang.REAL_TIME)}</Nav.Link>
                        <Nav.Link href={URL.ANALYTICS}>{t(Lang.ANALYTICS)}</Nav.Link>
                        <NavDropdown title={t(Lang.SANCTIONS)}>
                            <NavDropdown.Item href={URL.SANCTIONS}>
                                {t(Lang.SANCTIONS)}
                            </NavDropdown.Item>
                            <NavDropdown.Item href={URL.REMITTANCES}>
                                {t(Lang.REMITTANCES)}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t(Lang.LISTS)}>
                            <NavDropdown.Item href={URL.WHITE_LISTS}>
                                {t(Lang.WHITE_LISTS)}
                            </NavDropdown.Item>
                            <NavDropdown.Item href={URL.BLACK_LISTS}>
                                {t(Lang.BLACK_LISTS)}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href={URL.REGISTRY}>{t(Lang.REGISTRY)}</Nav.Link>
                        <Nav.Link href={URL.ALERTS}>{t(Lang.ALERTS)}</Nav.Link>
                        <Nav.Link href={URL.WARNINGS}>{t(Lang.WARNINGS)}</Nav.Link>
                        {user && user.role === 'admin' ? (
                            <NavDropdown title={t(Lang.SETTINGS)}>
                                <NavDropdown.Item href={URL.USERS}>
                                    {t(Lang.USERS)}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={URL.ZONES}>
                                    {t(Lang.ZONES)}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={URL.CAMERAS}>
                                    {t(Lang.CAMERAS)}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={URL.PANELS}>
                                    {t(Lang.PANELS)}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={URL.SENSORS}>
                                    {t(Lang.SENSORS)}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={URL.SENSOR_TYPES}>
                                    {t(Lang.SENSOR_TYPES)}
                                </NavDropdown.Item>

                                <NavDropdown.Item href={URL.SETTINGS}>
                                    {t(Lang.SYSTEM)}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={URL.LOG}>{t(Lang.LOG)}</NavDropdown.Item>
                                <NavDropdown.Item
                                    href={`${REACT_APP_BACKEND_URL}/api/documentation`}
                                >
                                    API
                                </NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            ''
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header
