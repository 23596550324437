import IUser from '../../services/User/User.interface'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import React, { useEffect, useState } from 'react'
import IZone from '../../services/Zone/Zone.interface'
import * as Zone from '../../services/Zone/Zone'
import Header from '../../components/header/Header'
import { Row, Col, Form } from 'react-bootstrap'
import Menu from '../../components/menu/Menu'
import Loader from '../../components/Loader'
import Pagination from '../../components/CustomPagination'
import IRemittance from '../../services/Remittance/Remittance.interface'
import * as Remittance from '../../services/Remittance/Remittance'
import RemittancesFilter, { RemittancesFilterForm } from './components/RemittancesFilter'
import RemittancesTable from './components/RemittancesTable'
import moment from 'moment'

interface RemittancesLayoutProps {
    user?: IUser
}

const RemittancesLayout = ({ user }: RemittancesLayoutProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [zones, setZones] = useState<IZone[]>([])
    const [zoneSelected, setZoneSelected] = useState<string | undefined>()
    const [limit, setLimit] = useState(50)
    const [page, setPage] = useState(1)
    const [remittances, setRemittances] = useState<IRemittance[]>([])
    const [totalRemittances, setTotalRemittances] = useState<number>()
    const [fetchRemittances, setFetchRemittances] = useState(true)
    const [filter, setFilter] = useState<RemittancesFilterForm>()

    const triggerRemittanceFetch = () => setFetchRemittances((t) => !t)

    const handleChangeZone = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setZoneSelected(event.target.value)
        setPage(1)
        triggerRemittanceFetch()
    }

    const handleChangeLimit = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setLimit(parseInt(event.target.value))
        setPage(1)
        triggerRemittanceFetch()
    }

    const handleClickPage = (nPage: number) => {
        setPage(nPage)
        triggerRemittanceFetch()
    }

    const handleFilter = (data: RemittancesFilterForm) => {
        const remittance: RemittancesFilterForm = {}
        Object.entries(data).map(([index, value]) => {
            if (value !== '') {
                if (index === 'start' || index === 'end')
                    remittance[index] = moment(value).utc().format('YYYY-MM-DD HH:mm')
                else remittance[index as keyof RemittancesFilterForm] = value
            }
        })
        setFilter(remittance)
        setLoading(true)
        setPage(1)
        triggerRemittanceFetch()
    }

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones()

            if (zones && zones.length) {
                setZones(zones)

                if (!zoneSelected) setZoneSelected(zones[0]._id)

                const zoneId = zoneSelected || zones[0]._id || ''

                const remittancesCount = await Remittance.getRemittancesCount({
                    ...filter,
                    start:
                        filter && filter.start
                            ? filter.start
                            : moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm'),
                    end: filter && filter.end ? filter.end : moment().format('YYYY-MM-DD HH:mm'),
                    zone: zoneId
                })

                if (remittancesCount) setTotalRemittances(remittancesCount)

                const remittances = await Remittance.getRemittances({
                    ...filter,
                    start:
                        filter && filter.start
                            ? filter.start
                            : moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm'),
                    end: filter && filter.end ? filter.end : moment().format('YYYY-MM-DD HH:mm'),
                    zone: zoneId,
                    limit,
                    page
                })

                if (remittances) setRemittances(remittances)
            }
            setLoading(false)
        }
        load()
    }, [fetchRemittances])

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? <Menu currentPage={t(Lang.REMITTANCES)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-4 p-xl-3 pe-xl-4">
                    <Row className="mb-5">
                        {zones.length ? (
                            <Col xs="12" xl="4">
                                <Form.Group as={Row}>
                                    <Col xs="12">
                                        <Form.Select onChange={handleChangeZone}>
                                            {zones.map((zone) => {
                                                return (
                                                    <option key={zone._id} value={zone._id}>
                                                        {zone.name}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <RemittancesFilter handleFilter={handleFilter} />
                        </Col>
                    </Row>
                    {remittances.length ? (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <RemittancesTable remittances={remittances} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" xl="2" className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ITEMS_PER_PAGE, {
                                            item: t(Lang.REGISTRY)
                                        })}
                                    </Form.Label>
                                </Col>
                                <Col xs="6" xl="1">
                                    <Form.Select defaultValue={limit} onChange={handleChangeLimit}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Col>
                                {totalRemittances && totalRemittances > limit ? (
                                    <Col xs="12" xl="9">
                                        <Pagination
                                            totalPages={Math.ceil(totalRemittances / limit)}
                                            currentPage={page}
                                            pageClicked={handleClickPage}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    )
}

export default RemittancesLayout
