import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IZone from './Zone.interface'

const apiURL = `${baseURL}/zones`

export const createZone = async (zone: IZone): Promise<IZone | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, zone, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdZone: IZone = response.data
            return createdZone
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getZones = async (): Promise<IZone[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const zones: IZone[] = response.data

            return zones
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getZone = async (id: string): Promise<IZone | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const zone: IZone = response.data
            return zone
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateZone = async (zone: IZone): Promise<IZone | false> => {
    try {
        const response = await axios.put(`${apiURL}/${zone._id}`, zone, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedZone: IZone = response.data
            return updatedZone
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteZone = async (id: string): Promise<IZone | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedZone: IZone = response.data
            return deletedZone
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
