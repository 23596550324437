import { CSVLink } from 'react-csv'
import IPlate from '../../../services/Plate/Plate.interface'
import IList from '../../../services/List/List.interface'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as Lang from '../../../i18n/constants'
import { useTranslation } from 'react-i18next'

interface BlackExportCSVProps {
    plates: IPlate[]
    list: IList
}

const BlackExportCSV = ({ plates, list }: BlackExportCSVProps): JSX.Element => {
    const { t } = useTranslation()

    const headers = [
        { label: 'plate', key: 'plate' },
        { label: 'description', key: 'description' },
        { label: 'observations', key: 'observations' },
        { label: 'brand', key: 'brand' },
        { label: 'model', key: 'model' },
        { label: 'color', key: 'color' }
    ]

    const filename = `${list.name}_${moment(new Date()).format('DD-MM-YYYY HH:mm')}.csv`

    return (
        <CSVLink headers={headers} data={plates} filename={filename} separator=";">
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t(Lang.EXPORT_CSV_FILE)}</Tooltip>}
            >
                <Button variant="link" className="btn-add">
                    <FontAwesomeIcon icon={['fas', 'file-csv']} size="2x" fixedWidth />
                </Button>
            </OverlayTrigger>
        </CSVLink>
    )
}

export default BlackExportCSV
