import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios'
import { jwtDecode } from 'jwt-decode'
import baseURL from './BaseURL'
import IUser from './User/User.interface'

const apiURL = `${baseURL}/login`

interface Token {
    sub: string
    iat: number
    exp: number
}

export const authHeader = (): AxiosRequestHeaders => {
    let token = localStorage.getItem('token')

    if (!token) token = sessionStorage.getItem('token')

    if (token) {
        return { Authorization: `Bearer ${token}` } as AxiosRequestHeaders
    } else return {} as AxiosRequestHeaders
}

export const login = async (
    username: string,
    password: string,
    remember?: boolean,
    twoFactorPassword?: string
): Promise<AxiosResponse> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, {
            username,
            password,
            twoFactorPassword
        })

        if (response.data.token) {
            if (remember) {
                sessionStorage.removeItem('token')
                localStorage.setItem('token', response.data.token)
            } else {
                localStorage.removeItem('token')
                sessionStorage.setItem('token', response.data.token)
            }
        }

        return response
    } catch (error) {
        console.error(error)

        const err = error as AxiosError

        if (err.response) return err.response
        else return { status: 408 } as AxiosResponse
    }
}

export const logout = (): void => {
    localStorage.removeItem('token')
    localStorage.removeItem('layer')
    sessionStorage.removeItem('token')
}

export const getToken = (): string | null => {
    let tokenStr = localStorage.getItem('token')

    if (!tokenStr) tokenStr = sessionStorage.getItem('token')

    if (!tokenStr) return null

    const token: Token = jwtDecode(tokenStr)

    if (Date.now() >= token.exp * 1000) return null

    return tokenStr
}

export const getUserId = (): string | null => {
    let tokenStr = localStorage.getItem('token')

    if (!tokenStr) tokenStr = sessionStorage.getItem('token')

    if (!tokenStr) return null

    const token: Token = jwtDecode(tokenStr)

    if (!token.sub) return null

    if (Date.now() >= token.exp * 1000) return null

    return token.sub
}

export const getCurrentUser = (): IUser | null => {
    const userStr = localStorage.getItem('user')

    if (userStr) {
        const user: IUser = JSON.parse(userStr)

        return user
    } else return null
}

export const startRecovery = async (username: string): Promise<boolean> => {
    try {
        const response: AxiosResponse = await axios.post(baseURL + '/startrecovery', { username })

        if (response.status === 200) return true
        else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const recovery = async (
    username: string,
    recoveryPassword: string,
    onlyCheck: boolean,
    newPassword?: string
): Promise<number> => {
    try {
        const response: AxiosResponse = await axios.post(baseURL + '/recovery', {
            username,
            recoveryPassword,
            onlyCheck,
            newPassword
        })

        if (response.data.error) if (response.data.error.code === 1) return 2

        return 1
    } catch (error) {
        console.error(error)
        return 0
    }
}
