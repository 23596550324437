import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IRemittance from './Remittance.interface'
import { RemittancesFilterForm } from '../../pages/Remittances/components/RemittancesFilter'

const apiURL = `${baseURL}/remittances`

export const createRemittance = async (remittance: IRemittance): Promise<IRemittance | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, remittance, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdRemittance: IRemittance = response.data
            return createdRemittance
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getRemittances = async (
    filter?: RemittancesFilterForm
): Promise<IRemittance[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: filter
        })

        if (response.data) {
            const remittances: IRemittance[] = response.data
            return remittances
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getRemittancesCount = async (
    filter?: RemittancesFilterForm
): Promise<number | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { ...filter, count: true }
        })

        if (response.data) {
            const totalRemittances: number = response.data
            return totalRemittances
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getRemittance = async (id: string): Promise<IRemittance | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const remittance: IRemittance = response.data
            return remittance
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateRemittance = async (remittance: IRemittance): Promise<IRemittance | false> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiURL}/${remittance._id}`, remittance, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedRemittance: IRemittance = response.data
            return updatedRemittance
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteRemittance = async (id: string): Promise<IRemittance | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedRemittance: IRemittance = response.data
            return deletedRemittance
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
