import ICamera from '../../services/Camera/Camera.interface'
import IZone from '../../services/Zone/Zone.interface'
import { RestrictionMode } from '../Option/Option.interface'

export enum typeEvent {
    authorized = 'authorized',
    unauthorized = 'unauthorized',
    possibleInfringement = 'possibleInfringement',
    invalidated = 'invalidated',
    sanctioned = 'sanctioned',
    pendingSanction = 'pendingSanction',
    courtesyTime = 'courtesyTime'
}

export enum carState {
    new = 'new',
    recurrent = 'recurrent'
}

export enum carDirection {
    in = 'in',
    out = 'out',
    unknown = 'unknown'
}

export enum EnvironmentLabel {
    zero = 'zero',
    Eco = 'Eco',
    B = 'B',
    C = 'C',
    without = 'without',
    foreign = 'foreign',
    fail = 'fail'
}

export default interface IEvent {
    _id?: string
    plate: string
    camera: ICamera['_id']
    zone: IZone['_id']
    type: typeEvent
    country?: string
    confidence?: number
    state?: carState
    direction: carDirection
    imagePlateURL?: string
    imageContextURL?: string
    imageOCRURL?: string
    imagePixelatedURL?: string
    sentSanctionDate?: Date
    environmentLabel?: EnvironmentLabel
    restrictionMode?: RestrictionMode
    invalidatedReason?: string
    createdAt?: Date
    updatedAt?: Date
}
