import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
    Row,
    Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'
import ErrorField from '../form/ErrorField'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import IList, { TypeList } from '../../services/List/List.interface'
import * as List from '../../services/List/List'
import IZone from '../../services/Zone/Zone.interface'
import IOption from '../../services/Option/Option.interface'
import ITimeZone from '../../services/TimeZone/TimeZone.interface'
import moment from 'moment'
import { Days } from '../../services/Day/Day.interface'
import ICalendar from '../../services/Calendar/Calendar.interface'
import IExpiration, { Period } from '../../services/Expiration/Expiration.interface'
import DatePicker from 'react-datepicker'

interface ListModalProps {
    show: boolean
    handleClose: () => void
    reloadLists: () => void
    list?: IList | false
    zones: IZone[]
    descriptions: IOption[]
    type: TypeList
}

interface Day {
    name: string
    active: boolean
    timeZone1: ITimeZone
    timeZone2: ITimeZone
}

interface ListForm
    extends Omit<
        IList,
        | 'emailRecipients'
        | 'calendar'
        | 'expirationPerUser'
        | 'expirationPerHome'
        | 'defaultExpiration'
    > {
    emailRecipients: string
    days?: Day[]
    dateOfExpiry?: string
    expirationPerUserValue?: number
    expirationPerUserPeriod?: string
    expirationPerHomeValue?: number
    expirationPerHomePeriod?: string
    defaultExpirationValue?: number
    defaultExpirationPeriod?: string
}

const ListModal = ({
    show,
    handleClose,
    reloadLists,
    list,
    zones,
    descriptions,
    type
}: ListModalProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue
    } = useForm<ListForm>()

    const { fields, update } = useFieldArray({
        control,
        name: 'days'
    })

    const handleCreateList = async (data: ListForm): Promise<void> => {
        setLoading(true)

        const emailRecipients = data.emailRecipients
            .split(';')
            .map((email) => email.trim())
            .filter((email) => email !== '')

        const list: IList = {
            name: data.name,
            description: data.description,
            type,
            zones: data.zones,
            enabled: data.enabled,
            webAlert: data.webAlert,
            emailAlert: data.emailAlert
        }

        if (type === TypeList.white) {
            const calendar: ICalendar = {
                days: [],
                dateOfExpiry: moment(data.dateOfExpiry, 'YYYY-MM-DD').toDate()
            }

            if (data.days) {
                for (const day of data.days)
                    if (day.active)
                        calendar.days.push({
                            day: day.name as Days,
                            timeZone1: {
                                start: day.timeZone1.start
                                    ? moment(day.timeZone1.start, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : '',
                                end: day.timeZone1.end
                                    ? moment(day.timeZone1.end, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : ''
                            },
                            timeZone2: {
                                start: day.timeZone2.start
                                    ? moment(day.timeZone2.start, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : '',
                                end: day.timeZone2.end
                                    ? moment(day.timeZone2.end, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : ''
                            }
                        })
            }

            list.calendar = calendar

            list.requiredCensus = data.requiredCensus
            list.requiredAuthorization = data.requiredAuthorization
            list.maxPerUser = data.maxPerUser

            const expirationPerUser: IExpiration = {
                value: data.expirationPerUserValue || 0,
                period: (data.expirationPerUserPeriod as Period) || Period.days
            }

            list.expirationPerUser = expirationPerUser

            list.maxPerHome = data.maxPerHome

            const expirationPerHome: IExpiration = {
                value: data.expirationPerHomeValue || 0,
                period: (data.expirationPerHomePeriod as Period) || Period.days
            }

            list.expirationPerHome = expirationPerHome

            const defaultExpiration: IExpiration = {
                value: data.defaultExpirationValue || 0,
                period: (data.defaultExpirationPeriod as Period) || Period.days
            }

            list.defaultExpiration = defaultExpiration
        }

        if (emailRecipients[0] !== '') list.emailRecipients = emailRecipients

        const createdList = await List.createList(list)

        if (createdList) {
            if (type === TypeList.white)
                toast.success(
                    t(Lang.WHITE_LIST_CREATED_SUCCESSFULLY, {
                        list: createdList
                    })
                )
            else
                toast.success(
                    t(Lang.BLACK_LIST_CREATED_SUCCESSFULLY, {
                        list: createdList
                    })
                )
            reloadLists()
        } else if (type === TypeList.white) toast.error(t(Lang.ERROR_CREATING_WHITE_LIST, { list }))
        else toast.error(t(Lang.ERROR_CREATING_BLACK_LIST, { list }))

        setLoading(false)
        handleClose()
    }

    const handleUpdateList = async (data: ListForm): Promise<void> => {
        setLoading(true)

        const emailRecipients = data.emailRecipients.split(';').map((email) => email.trim())

        const list: IList = {
            _id: data._id,
            name: data.name,
            description: data.description,
            type,
            zones: data.zones,
            enabled: data.enabled,
            webAlert: data.webAlert,
            emailAlert: data.emailAlert
        }

        if (type === TypeList.white) {
            const calendar: ICalendar = {
                days: [],
                dateOfExpiry: moment(data.dateOfExpiry, 'YYYY-MM-DD').toDate()
            }

            if (data.days) {
                for (const day of data.days) {
                    if (day.active)
                        calendar.days.push({
                            day: day.name as Days,
                            timeZone1: {
                                start: day.timeZone1.start
                                    ? moment(day.timeZone1.start, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : '',
                                end: day.timeZone1.end
                                    ? moment(day.timeZone1.end, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : ''
                            },
                            timeZone2: {
                                start: day.timeZone2.start
                                    ? moment(day.timeZone2.start, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : '',
                                end: day.timeZone2.end
                                    ? moment(day.timeZone2.end, 'HH:mm')
                                          .local()
                                          .utc()
                                          .format('HH:mm')
                                    : ''
                            }
                        })
                }
            }

            list.calendar = calendar
            list.requiredCensus = data.requiredCensus
            list.requiredAuthorization = data.requiredAuthorization
            list.maxPerUser = data.maxPerUser

            const expirationPerUser: IExpiration = {
                value: data.expirationPerUserValue || 0,
                period: (data.expirationPerUserPeriod as Period) || Period.days
            }

            list.expirationPerUser = expirationPerUser

            list.maxPerHome = data.maxPerHome

            const expirationPerHome: IExpiration = {
                value: data.expirationPerHomeValue || 0,
                period: (data.expirationPerHomePeriod as Period) || Period.days
            }

            list.expirationPerHome = expirationPerHome

            const defaultExpiration: IExpiration = {
                value: data.defaultExpirationValue || 0,
                period: (data.defaultExpirationPeriod as Period) || Period.days
            }

            list.defaultExpiration = defaultExpiration
        }

        if (emailRecipients[0] !== '') list.emailRecipients = emailRecipients

        const updatedList = await List.updateList(list)

        if (updatedList) {
            if (type === TypeList.white)
                toast.success(
                    t(Lang.WHITE_LIST_UPDATED_SUCCESSFULLY, {
                        list: updatedList
                    })
                )
            else
                toast.success(
                    t(Lang.BLACK_LIST_UPDATED_SUCCESSFULLY, {
                        list: updatedList
                    })
                )
            reloadLists()
        } else if (type === TypeList.white) toast.error(t(Lang.ERROR_UPDATING_WHITE_LIST, { list }))
        else toast.error(t(Lang.ERROR_UPDATING_BLACK_LIST, { list }))

        setLoading(false)
        handleClose()
    }

    const checkName = async (value: string): Promise<boolean> => {
        if (value && !list) {
            const lists = await List.getLists()

            if (lists) {
                const foundList = lists.find((list) => list.name === value)

                if (foundList) return false
            }
        }
        return true
    }

    const handleApplyAllDays = () => {
        const timeZone1: ITimeZone = { start: '', end: '' }
        const timeZone2: ITimeZone = { start: '', end: '' }

        for (const field of fields) {
            if (
                (field.timeZone1.start && field.timeZone1.end) ||
                (field.timeZone2.start && field.timeZone2.end)
            ) {
                if (field.timeZone1.start && field.timeZone1.end) {
                    timeZone1.start = field.timeZone1.start
                    timeZone1.end = field.timeZone1.end
                }

                if (field.timeZone2.start && field.timeZone2.end) {
                    timeZone2.start = field.timeZone2.start
                    timeZone2.end = field.timeZone2.end
                }

                break
            }
        }

        for (const [index, field] of Object.entries(fields))
            setValue(`days.${parseInt(index)}`, {
                name: field.name,
                active: true,
                timeZone1: { start: timeZone1.start, end: timeZone1.end },
                timeZone2: { start: timeZone2.start, end: timeZone2.end }
            })
    }

    useEffect(() => {
        const daysForm: Day[] = []

        if (list) {
            let loadedList: ListForm = {
                ...list,
                emailRecipients: list.emailRecipients?.join('; ') || ''
            }

            if (list.type === TypeList.white) {
                loadedList.dateOfExpiry = list.calendar?.dateOfExpiry
                    ? moment(list.calendar?.dateOfExpiry).format('YYYY-MM-DD')
                    : ''

                if (list.calendar) {
                    Object.keys(Days).map((item) => {
                        if (list.calendar) {
                            const found = list.calendar.days.find((day) => day.day == item)

                            if (found) {
                                daysForm.push({
                                    name: item,
                                    active: true,
                                    timeZone1: {
                                        start:
                                            found.timeZone1 && found.timeZone1.start
                                                ? moment
                                                      .utc(found.timeZone1.start, 'HH:mm')
                                                      .local()
                                                      .format('HH:mm')
                                                : '',
                                        end:
                                            found.timeZone1 && found.timeZone1.end
                                                ? moment
                                                      .utc(found.timeZone1.end, 'HH:mm')
                                                      .local()
                                                      .format('HH:mm')
                                                : ''
                                    },
                                    timeZone2: {
                                        start:
                                            found.timeZone2 && found.timeZone2.start
                                                ? moment
                                                      .utc(found.timeZone2.start, 'HH:mm')
                                                      .local()
                                                      .format('HH:mm')
                                                : '',
                                        end:
                                            found.timeZone2 && found.timeZone2.end
                                                ? moment
                                                      .utc(found.timeZone2.end, 'HH:mm')
                                                      .local()
                                                      .format('HH:mm')
                                                : ''
                                    }
                                })
                            } else
                                daysForm.push({
                                    name: item,
                                    active: false,
                                    timeZone1: { start: '', end: '' },
                                    timeZone2: { start: '', end: '' }
                                })
                        }
                    })
                } else {
                    Object.keys(Days).map((item) => {
                        daysForm.push({
                            name: item,
                            active: false,
                            timeZone1: { start: '', end: '' },
                            timeZone2: { start: '', end: '' }
                        })
                    })
                }

                loadedList = { ...loadedList, days: daysForm }

                if (list.expirationPerUser) {
                    loadedList.expirationPerUserValue = list.expirationPerUser.value
                    loadedList.expirationPerUserPeriod = list.expirationPerUser.period
                }

                if (list.expirationPerHome) {
                    loadedList.expirationPerHomeValue = list.expirationPerHome.value
                    loadedList.expirationPerHomePeriod = list.expirationPerHome.period
                }

                if (list.defaultExpiration) {
                    loadedList.defaultExpirationValue = list.defaultExpiration.value
                    loadedList.defaultExpirationPeriod = list.defaultExpiration.period
                }
            }

            reset(loadedList)
        } else {
            let loadedList: ListForm = {
                name: '',
                description: '',
                type,
                zones: [''],
                enabled: false,
                webAlert: false,
                emailAlert: false,
                emailRecipients: ''
            }

            if (type === TypeList.white) {
                loadedList.dateOfExpiry = moment(new Date()).format('YYYY-MM-DD')

                Object.keys(Days).map((item) => {
                    daysForm.push({
                        name: item,
                        active: false,
                        timeZone1: { start: '', end: '' },
                        timeZone2: { start: '', end: '' }
                    })
                })

                loadedList = { ...loadedList, days: daysForm }

                loadedList.requiredCensus = false
                loadedList.requiredAuthorization = false
                loadedList.maxPerUser = 0
                loadedList.expirationPerUserValue = 0
                loadedList.expirationPerUserPeriod = Period.days
                loadedList.maxPerHome = 0
                loadedList.expirationPerHomeValue = 0
                loadedList.expirationPerHomePeriod = Period.days
                loadedList.defaultExpirationValue = 0
                loadedList.defaultExpirationPeriod = Period.days
            }

            reset(loadedList)
        }
    }, [list])

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={list ? handleSubmit(handleUpdateList) : handleSubmit(handleCreateList)}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {list
                            ? type === TypeList.white
                                ? t(Lang.EDIT_WHITE_LIST, { list })
                                : t(Lang.EDIT_BLACK_LIST, { list })
                            : type === TypeList.white
                              ? t(Lang.ADD_WHITE_LIST)
                              : t(Lang.ADD_BLACK_LIST)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={errors.name ? 'border-2 border-danger' : ''}
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 128
                                    })
                                },
                                validate: async (v) =>
                                    (await checkName(v)) ||
                                    (t(Lang.A_LIST_WITH_THIS_NAME_ALREADY_EXISTS) as string)
                            })}
                        />
                    </InputGroup>
                    {errors.name && <ErrorField message={errors.name.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="description"
                            className={errors.description ? 'border-2 border-danger' : ''}
                            {...register('description')}
                        >
                            {descriptions.map((description, index) => {
                                return (
                                    <option key={`description_${index}`} value={description.value}>
                                        {description.value}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.description && <ErrorField message={errors.description.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.ZONES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.zones ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'vector-square']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="zones"
                            multiple
                            className={errors.zones ? 'border-2 border-danger' : ''}
                            {...register('zones', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.ZONES)
                                }) as string
                            })}
                        >
                            {zones.map((zone: IZone) => {
                                return (
                                    <option key={zone._id} value={zone._id}>
                                        {zone.name}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    <Form.Check
                        id="enabled"
                        type="checkbox"
                        label={t(Lang.ENABLED)}
                        inline
                        className="mb-3"
                        {...register('enabled')}
                    />
                    <Form.Check
                        id="webAlert"
                        type="checkbox"
                        label={t(Lang.WEB_ALERT)}
                        inline
                        className="mb-3"
                        {...register('webAlert')}
                    />
                    <Form.Check
                        id="emailAlert"
                        type="checkbox"
                        label={t(Lang.EMAIL_ALERT)}
                        inline
                        className="mb-3"
                        {...register('emailAlert')}
                    />
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.EMAIL_RECIPIENTS)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.emailRecipients
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'envelope']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="emailRecipients"
                            type="text"
                            placeholder={t(Lang.EMAIL_RECIPIENTS)}
                            aria-label={t(Lang.EMAIL_RECIPIENTS)}
                            aria-describedby={t(Lang.EMAIL_RECIPIENTS)}
                            className={errors.emailRecipients ? 'border-2 border-danger' : ''}
                            {...register('emailRecipients')}
                        />
                    </InputGroup>
                    <Form.Text id="emailRecipientsHelp" muted className="mb-3">
                        {t(Lang.SEMICOLON_SEPARATED_EMAILS)}
                    </Form.Text>
                    {type === TypeList.white ? (
                        <>
                            <Row>
                                <Col>
                                    <Form.Label>{t(Lang.CALENDAR)}</Form.Label>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Button variant="primary" onClick={handleApplyAllDays}>
                                        {t(Lang.APPLY_ALL)}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : null}
                    {type === TypeList.white &&
                        fields.map((item, index) => {
                            return (
                                <div key={index} className="mb-3 card p-3">
                                    <Row className="mb-1">
                                        <Col>
                                            <Controller
                                                name={`days.${index}.active`}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={field.value}
                                                        label={t(langObj[item.name.toUpperCase()])}
                                                        onChange={() => {
                                                            field.onChange(!item.active)
                                                            item.active = !item.active
                                                            update(index, item)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col className="d-flex align-items-center">
                                            {t(Lang.AM)}
                                        </Col>
                                        <Col>
                                            <Controller
                                                name={`days.${index}.timeZone1.start`}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <DatePicker
                                                        value={field.value}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption={t(Lang.HOUR)}
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        className="form-control w-100"
                                                        onChange={(date) => {
                                                            const timeString = date
                                                                ? date.toTimeString().slice(0, 5)
                                                                : ''
                                                            field.onChange(timeString)
                                                            item.timeZone1.start = timeString
                                                            update(index, item)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Controller
                                                name={`days.${index}.timeZone1.end`}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <DatePicker
                                                        value={field.value}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption={t(Lang.HOUR)}
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        className="form-control w-100"
                                                        onChange={(date) => {
                                                            const timeString = date
                                                                ? date.toTimeString().slice(0, 5)
                                                                : ''
                                                            field.onChange(timeString)
                                                            item.timeZone1.end = timeString
                                                            update(index, item)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex align-items-center">
                                            {t(Lang.PM)}
                                        </Col>
                                        <Col>
                                            <Controller
                                                name={`days.${index}.timeZone2.start`}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <DatePicker
                                                        value={field.value}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption={t(Lang.HOUR)}
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        className="form-control w-100"
                                                        onChange={(date) => {
                                                            const timeString = date
                                                                ? date.toTimeString().slice(0, 5)
                                                                : ''
                                                            field.onChange(timeString)
                                                            item.timeZone2.start = timeString
                                                            update(index, item)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Controller
                                                name={`days.${index}.timeZone2.end`}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <DatePicker
                                                        value={field.value}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption={t(Lang.HOUR)}
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        className="form-control w-100"
                                                        onChange={(date) => {
                                                            const timeString = date
                                                                ? date.toTimeString().slice(0, 5)
                                                                : ''
                                                            field.onChange(timeString)
                                                            item.timeZone2.end = timeString
                                                            update(index, item)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    {type === TypeList.white ? (
                        <InputGroup className="mb-3">
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>{t(Lang.DATE_OF_EXPIRY)}</Tooltip>}
                            >
                                <InputGroup.Text
                                    className={`text-white ${
                                        errors.dateOfExpiry
                                            ? 'bg-danger border-2 border-danger'
                                            : 'bg-primary'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'calendar-days']}
                                        viewBox="0 0 512 512"
                                        fixedWidth
                                    />
                                </InputGroup.Text>
                            </OverlayTrigger>
                            <FormControl
                                id="dateOfExpiry"
                                type="date"
                                aria-label={t(Lang.DATE_OF_EXPIRY)}
                                aria-describedby={t(Lang.DATE_OF_EXPIRY)}
                                className={errors.dateOfExpiry ? 'border-2 border-danger' : ''}
                                {...register('dateOfExpiry')}
                            />
                        </InputGroup>
                    ) : null}
                    {type === TypeList.white && errors.dateOfExpiry && (
                        <ErrorField message={errors.dateOfExpiry.message} />
                    )}
                    {type === TypeList.white ? (
                        <>
                            <Row>
                                <Col>
                                    <Form.Check
                                        id="requiredCensus"
                                        type="checkbox"
                                        label={t(Lang.REQUIRED_CENSUS)}
                                        inline
                                        className="mb-3"
                                        {...register('requiredCensus')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        id="requiredAuthorization"
                                        type="checkbox"
                                        label={t(Lang.REQUIRED_AUTHORIZATION)}
                                        inline
                                        className="mb-3"
                                        {...register('requiredAuthorization')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>{t(Lang.MAX_PER_USER)}</Tooltip>}
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.maxPerUser
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'user']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <FormControl
                                            id="maxPerUser"
                                            type="number"
                                            min={0}
                                            aria-label={t(Lang.MAX_PER_USER)}
                                            aria-describedby={t(Lang.MAX_PER_USER)}
                                            className={
                                                errors.maxPerUser ? 'border-2 border-danger' : ''
                                            }
                                            {...register('maxPerUser', {
                                                min: {
                                                    value: 0,
                                                    message: t(Lang.MIN_FIELD, {
                                                        field: t(Lang.MAX_PER_USER),
                                                        value: 0
                                                    })
                                                }
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.maxPerUser && (
                                        <ErrorField message={errors.maxPerUser.message} />
                                    )}
                                </Col>
                                <Col xs={3}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>{t(Lang.EXPIRATION_PER_USER)}</Tooltip>
                                            }
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.expirationPerUserValue
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'hashtag']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <FormControl
                                            id="expirationPerUserValue"
                                            type="number"
                                            min={0}
                                            aria-label={t(Lang.EXPIRATION_PER_USER)}
                                            aria-describedby={t(Lang.EXPIRATION_PER_USER)}
                                            className={
                                                errors.maxPerUser ? 'border-2 border-danger' : ''
                                            }
                                            {...register('expirationPerUserValue', {
                                                min: {
                                                    value: 0,
                                                    message: t(Lang.MIN_FIELD, {
                                                        field: t(Lang.EXPIRATION_PER_USER),
                                                        value: 0
                                                    })
                                                }
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.expirationPerUserValue && (
                                        <ErrorField
                                            message={errors.expirationPerUserValue.message}
                                        />
                                    )}
                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>{t(Lang.EXPIRATION_PER_USER)}</Tooltip>
                                            }
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.expirationPerUserPeriod
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'clock']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <Form.Select
                                            id="expirationPerUserPeriod"
                                            className={
                                                errors.expirationPerUserPeriod
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...register('expirationPerUserPeriod')}
                                        >
                                            {Object.keys(Period).map((period: string) => {
                                                return (
                                                    <option key={period} value={period}>
                                                        {t(langObj[period.toUpperCase()])}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>{t(Lang.MAX_PER_HOME)}</Tooltip>}
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.maxPerHome
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'house']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <FormControl
                                            id="maxPerHome"
                                            type="number"
                                            min={0}
                                            aria-label={t(Lang.MAX_PER_HOME)}
                                            aria-describedby={t(Lang.MAX_PER_HOME)}
                                            className={
                                                errors.maxPerUser ? 'border-2 border-danger' : ''
                                            }
                                            {...register('maxPerHome', {
                                                min: {
                                                    value: 0,
                                                    message: t(Lang.MIN_FIELD, {
                                                        field: t(Lang.MAX_PER_HOME),
                                                        value: 0
                                                    })
                                                }
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.maxPerHome && (
                                        <ErrorField message={errors.maxPerHome.message} />
                                    )}
                                </Col>
                                <Col xs={3}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>{t(Lang.EXPIRATION_PER_HOME)}</Tooltip>
                                            }
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.expirationPerHomeValue
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'hashtag']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <FormControl
                                            id="expirationPerHomeValue"
                                            type="number"
                                            min={0}
                                            aria-label={t(Lang.EXPIRATION_PER_HOME)}
                                            aria-describedby={t(Lang.EXPIRATION_PER_HOME)}
                                            className={
                                                errors.expirationPerHomeValue
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...register('expirationPerHomeValue', {
                                                min: {
                                                    value: 0,
                                                    message: t(Lang.MIN_FIELD, {
                                                        field: t(Lang.EXPIRATION_PER_HOME),
                                                        value: 0
                                                    })
                                                }
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.expirationPerHomeValue && (
                                        <ErrorField
                                            message={errors.expirationPerHomeValue.message}
                                        />
                                    )}
                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>{t(Lang.EXPIRATION_PER_HOME)}</Tooltip>
                                            }
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.expirationPerHomePeriod
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'clock']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <Form.Select
                                            id="expirationPerHomePeriod"
                                            className={
                                                errors.expirationPerHomePeriod
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...register('expirationPerHomePeriod')}
                                        >
                                            {Object.keys(Period).map((period: string) => {
                                                return (
                                                    <option key={period} value={period}>
                                                        {t(langObj[period.toUpperCase()])}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>{t(Lang.DEFAULT_EXPIRATION)}</Tooltip>
                                            }
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.defaultExpirationValue
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'hashtag']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <FormControl
                                            id="defaultExpirationValue"
                                            type="number"
                                            min={0}
                                            aria-label={t(Lang.DEFAULT_EXPIRATION)}
                                            aria-describedby={t(Lang.DEFAULT_EXPIRATION)}
                                            className={
                                                errors.defaultExpirationValue
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...register('defaultExpirationValue', {
                                                min: {
                                                    value: 0,
                                                    message: t(Lang.MIN_FIELD, {
                                                        field: t(Lang.DEFAULT_EXPIRATION),
                                                        value: 0
                                                    })
                                                }
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.defaultExpirationValue && (
                                        <ErrorField
                                            message={errors.defaultExpirationValue.message}
                                        />
                                    )}
                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>{t(Lang.DEFAULT_EXPIRATION)}</Tooltip>
                                            }
                                        >
                                            <InputGroup.Text
                                                className={`text-white ${
                                                    errors.defaultExpirationPeriod
                                                        ? 'bg-danger border-2 border-danger'
                                                        : 'bg-primary'
                                                }`}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'clock']}
                                                    viewBox="0 0 512 512"
                                                    fixedWidth
                                                />
                                            </InputGroup.Text>
                                        </OverlayTrigger>
                                        <Form.Select
                                            id="defaultExpirationPeriod"
                                            className={
                                                errors.defaultExpirationPeriod
                                                    ? 'border-2 border-danger'
                                                    : ''
                                            }
                                            {...register('defaultExpirationPeriod')}
                                        >
                                            {Object.keys(Period).map((period: string) => {
                                                return (
                                                    <option key={period} value={period}>
                                                        {t(langObj[period.toUpperCase()])}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader loading={loading} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ListModal
