import { Table, Button, Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../components/Loader'
import IEvent, { typeEvent, EnvironmentLabel } from '../../../services/Event/Event.interface'
import * as Event from '../../../services/Event/Event'
import ICamera from '../../../services/Camera/Camera.interface'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import * as Remittance from '../../../services/Remittance/Remittance'

interface SanctionsTableForm {
    type: typeEvent
    invalidatedReason?: string
}

interface SanctionsTableProps {
    sanctions: IEvent[]
    cameras: ICamera[]
    handleEditSanction: (event: React.MouseEvent<HTMLButtonElement>) => void
    reloadSanctions: () => void
}

const SanctionsTable = ({
    sanctions,
    cameras,
    handleEditSanction,
    reloadSanctions
}: SanctionsTableProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState('')
    const [loadingSave, setLoadingSave] = useState(false)
    const [loadingSend, setLoadingSend] = useState(false)
    const [sanctionsChecked, setSanctionsChecked] = useState<IEvent[]>([])
    const [show, setShow] = useState(false)
    const checkboxRefs = useRef<(HTMLInputElement | null)[]>([])

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const { register, handleSubmit, resetField } = useForm<SanctionsTableForm>()

    const handleDeleteSanction = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault()
        const button: HTMLButtonElement = event.currentTarget
        const id = button.value

        setLoading(id)

        const deletedSanction = await Event.deleteEvent(id)

        if (deletedSanction) {
            toast.success(t(Lang.EVENT_DELETED_SUCCESSFULLY))
            reloadSanctions()
        } else toast.error(t(Lang.ERROR_DELETING_EVENT))

        setLoading('')
    }

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sanction = sanctions.find((sanction) => sanction._id === event.target.value)

        if (!sanction) return

        if (event.target.checked) setSanctionsChecked([...sanctionsChecked, sanction])
        else
            setSanctionsChecked(
                sanctionsChecked.filter((sanctionFiltered) => sanctionFiltered._id !== sanction._id)
            )
    }

    const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === typeEvent.invalidated) setShow(true)
        else setShow(false)
    }

    const handleSubmitSave = async (data: SanctionsTableForm) => {
        setLoadingSave(true)
        const promises: Promise<IEvent | false>[] = []

        if (!sanctionsChecked.length) {
            setLoadingSave(false)
            return toast.error(t(Lang.NO_SANCTION_SELECTED))
        }

        for (const sanctionChecked of sanctionsChecked) {
            if (sanctionChecked.sentSanctionDate) {
                setLoadingSave(false)
                return toast.error(t(Lang.SANCTION_SENT_CANNOT_BE_MODIFIED))
            }
        }

        for (const sanctionChecked of sanctionsChecked) {
            sanctionChecked.type = data.type
            sanctionChecked.invalidatedReason = data.invalidatedReason
            promises.push(Event.updateEvent(sanctionChecked))
        }

        const results = await Promise.all(promises)

        if (results && results.length) {
            for (let i = 0; i < results.length; i++)
                if (!results[i]) {
                    setLoadingSave(false)
                    return toast.error(t(Lang.ERROR_UPDATING_SANCTIONS))
                }

            toast.success(t(Lang.SANCTIONS_UPDATED_SUCCESSFULLY))
        }

        setLoadingSave(false)
        reloadSanctions()
        resetField('type')
        setShow(false)
    }

    const handleSelectAllSanctions = () => {
        const sanctioned: IEvent[] = []

        for (let i = 0; i < sanctions.length; i++) {
            if (sanctions[i].type === typeEvent.pendingSanction) {
                sanctioned.push(sanctions[i])
                const element = checkboxRefs.current[i]

                if (element) element.checked = true
            }
        }

        if (sanctioned && sanctioned.length) setSanctionsChecked(sanctioned)
    }

    const handleSendSanctions = async () => {
        setLoadingSend(true)
        const sanctionsId: string[] = []

        const pending = sanctionsChecked.filter(
            (sanctionChecked) => sanctionChecked.type === typeEvent.pendingSanction
        )

        if (!pending.length) {
            setLoadingSend(false)
            return toast.error(t(Lang.NO_SANCTION_TYPE_SANCTIONED_SELECTED))
        }

        for (const sanction of pending) {
            if (sanction.sentSanctionDate) {
                setLoadingSend(false)
                return toast.error(t(Lang.SANCTION_SENT_CANNOT_BE_MODIFIED))
            }
        }

        pending.sort((sanction1: IEvent, sanction2: IEvent) => {
            if (sanction1.createdAt && sanction2.createdAt)
                return moment(sanction1.createdAt).valueOf() - moment(sanction2.createdAt).valueOf()
            else return 0
        })

        const promises: Promise<IEvent | false>[] = []

        for (const sanction of pending) {
            sanction.sentSanctionDate = new Date()
            sanction.type = typeEvent.sanctioned
            promises.push(Event.updateEvent(sanction))
            if (sanction._id) sanctionsId.push(sanction._id)
        }

        const results = await Promise.all(promises)

        for (const result of results) {
            if (!result) {
                setLoadingSend(false)
                return toast.error(t(Lang.ERROR_SENDING_SANCTIONS))
            }
        }

        const firstSanctionDate = pending[0].createdAt ? pending[0].createdAt : moment().toDate()
        const lastSanctionDate = moment(pending[pending.length - 1].createdAt).toDate()

        const result = await Remittance.createRemittance({
            sanctions: sanctionsId,
            firstSanctionDate,
            lastSanctionDate,
            zone: pending[0].zone
        })

        if (!result) {
            setLoadingSend(false)
            return toast.error(t(Lang.ERROR_CREATING_REMITTANCE))
        }

        toast.success(t(Lang.SANCTIONS_SENT_SUCCESSFULLY))
        setLoadingSend(false)
        reloadSanctions()
    }

    return (
        <>
            <Row className="mb-3">
                <Col lg="8">
                    <Form onSubmit={handleSubmit(handleSubmitSave)}>
                        <Row>
                            <Col className="mb-3">
                                <Form.Select
                                    defaultValue=""
                                    {...register('type')}
                                    onChange={handleChangeSelect}
                                    required
                                >
                                    <option disabled value="">
                                        {t(Lang.MARK_AS)}
                                    </option>
                                    <option value={typeEvent.pendingSanction}>
                                        {t(Lang.PENDING_SHIPPING)}
                                    </option>
                                    <option value={typeEvent.invalidated}>
                                        {t(Lang.INVALIDATED)}
                                    </option>
                                </Form.Select>
                            </Col>
                            <Col className={show ? 'mb-3 d-block' : 'd-none'}>
                                <Form.Control
                                    id="invalidatedReason"
                                    type="text"
                                    maxLength={512}
                                    placeholder={t(Lang.INVALIDATED_REASON)}
                                    required={show}
                                    {...register('invalidatedReason')}
                                />
                            </Col>
                            <Col>
                                <Button type="submit" variant="primary">
                                    {loadingSave ? (
                                        <Loader loading={loadingSave} size={25} color="#FFFFFF" />
                                    ) : (
                                        t(Lang.SAVE)
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col lg="4">
                    <Button
                        variant="primary"
                        onClick={handleSelectAllSanctions}
                        className="me-3 mb-3"
                    >
                        {t(Lang.SELECT_ALL_PENDING)}
                    </Button>
                    <Button variant="secondary" className="mb-3" onClick={handleSendSanctions}>
                        {loadingSend ? (
                            <Loader loading={loadingSend} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SEND_SANCTIONS)
                        )}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive striped hover variant="primary">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t(Lang.PLATE)}</th>
                                <th>{t(Lang.CAMERA)}</th>
                                <th>{t(Lang.DIRECTION)}</th>
                                <th>{t(Lang.DATE)}</th>
                                <th>{t(Lang.TYPE_OF_REGISTRY)}</th>
                                <th>{t(Lang.ENVIRONMENT_LABEL)}</th>
                                <th>{t(Lang.SENT_SANCTION_DATE)}</th>
                                <th>{t(Lang.RESTRICTION_MODE)}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sanctions.map((sanction: IEvent, index) => {
                                const camera = cameras.filter(
                                    (camera) => camera._id === sanction.camera
                                )

                                let environmentLabel: string | JSX.Element = ''

                                if (sanction.environmentLabel) {
                                    switch (sanction.environmentLabel) {
                                        case EnvironmentLabel.B:
                                        case EnvironmentLabel.C:
                                        case EnvironmentLabel.Eco:
                                        case EnvironmentLabel.zero:
                                            environmentLabel = (
                                                <img
                                                    src={`/img/environment_labels/${sanction.environmentLabel}.svg`}
                                                    alt={`Distintivo ambiental ${t(
                                                        langObj[
                                                            sanction.environmentLabel.toUpperCase()
                                                        ]
                                                    )}`}
                                                    title={`Distintivo ambiental ${t(
                                                        langObj[
                                                            sanction.environmentLabel.toUpperCase()
                                                        ]
                                                    )}`}
                                                    style={{ maxWidth: '44px' }}
                                                />
                                            )
                                            break
                                        default:
                                            environmentLabel = t(
                                                langObj[sanction.environmentLabel.toUpperCase()]
                                            )
                                    }
                                }
                                return (
                                    <tr key={sanction._id}>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                value={sanction._id}
                                                onChange={handleChangeCheckbox}
                                                ref={(el: HTMLInputElement | null) =>
                                                    (checkboxRefs.current[index] = el)
                                                }
                                            />
                                        </td>
                                        <td>{sanction.plate}</td>
                                        <td>{camera.length ? camera[0].name : ''}</td>
                                        <td>{t(langObj[sanction.direction.toUpperCase()])}</td>
                                        <td>
                                            {moment(sanction.createdAt).format(
                                                'DD/MM/YYYY HH:mm:ss'
                                            )}
                                        </td>
                                        <td>{t(langObj[sanction.type.toUpperCase()])}</td>
                                        <td>{environmentLabel}</td>
                                        <td>
                                            {sanction.sentSanctionDate
                                                ? moment(sanction.sentSanctionDate).format(
                                                      'DD/MM/YYYY HH:mm:ss'
                                                  )
                                                : ''}
                                        </td>
                                        <td>
                                            {sanction.restrictionMode
                                                ? t(langObj[sanction.restrictionMode.toUpperCase()])
                                                : ''}
                                        </td>
                                        <td>
                                            <Button
                                                variant="link"
                                                value={sanction._id}
                                                onClick={handleEditSanction}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'pen-to-square']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                variant="link"
                                                className="btn-delete"
                                                value={sanction._id}
                                                onClick={handleDeleteSanction}
                                            >
                                                {loading === sanction._id ? (
                                                    <Loader
                                                        loading={true}
                                                        size={25}
                                                        color="#dc3545"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'trash-can']}
                                                        size="2x"
                                                        fixedWidth
                                                    />
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default SanctionsTable
