import IZone from '../Zone/Zone.interface'

export enum Direction {
    entry = 'entry',
    exit = 'exit',
    both = 'both'
}

export enum Detection {
    through = 'through',
    continue = 'continue'
}

type ModelList = {
    models: string[]
}

export const Manufacturers: { [key: string]: ModelList } = {
    Wisenet: {
        models: ['XNO-6080R']
    },
    Simec: {
        models: ['STARE-VS5-D5']
    }
}

export enum DeviceStatus {
    online = 'online',
    offline = 'offline',
    receiving = 'receiving'
}

export interface ImageFrame {
    top: number
    right: number
    bottom: number
    left: number
}

export default interface ICamera {
    _id?: string
    identifier: string
    name: string
    description?: string
    location: string
    coordinates: string
    direction: Direction
    detection: Detection
    manufacturer: string
    model: string
    host: string
    port: number
    zone: IZone['_id']
    alive?: Date
    status?: DeviceStatus
    imageFrame?: ImageFrame
    createdAt?: Date
    updatedAt?: Date
}
