import { Form, Row, Col, Button } from 'react-bootstrap'
import { useRef } from 'react'
import IList from '../../../services/List/List.interface'
import IPlate from '../../../services/Plate/Plate.interface'
import * as Plate from '../../../services/Plate/Plate'
import { useForm } from 'react-hook-form'
import * as Papa from 'papaparse'
import { toast } from 'react-toastify'
import * as Lang from '../../../i18n/constants'
import { useTranslation } from 'react-i18next'

interface BlackImportCSVProps {
    list: IList
    reloadPlates: () => void
}

interface BlackImportForm {
    file: FileList
    replace: boolean
}

const BlackImportCSV = ({ list, reloadPlates }: BlackImportCSVProps): JSX.Element => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const labelRef = useRef<HTMLLabelElement>(null)

    const { register, handleSubmit } = useForm<BlackImportForm>()

    const inputFileRegister = register('file', {
        required: true
    })

    const fileReader = new FileReader()

    const handleUpload = () => {
        inputRef.current?.click()
    }

    const handleChange = () => {
        if (inputRef.current && labelRef.current)
            labelRef.current.innerText = inputRef.current.value
                ? inputRef.current.value
                : t(Lang.IMPORT_CSV_FILE)
    }

    const handleInsertPlates = async (data: BlackImportForm): Promise<void> => {
        if (data.file.length) {
            fileReader.onload = async (event) => {
                if (event.target) {
                    const csvOutput = event.target.result as string
                    const dataParsed = Papa.parse<IPlate>(csvOutput, {
                        header: true,
                        delimiter: ';'
                    })
                    if (list._id) {
                        const plates = dataParsed.data.map((plate) => {
                            return { ...plate, list: list._id }
                        })
                        const insertedPlates = await Plate.insertPlates(
                            plates,
                            list._id,
                            data.replace
                        )
                        if (insertedPlates) toast.success(t(Lang.SUCCESSFULLY_IMPORTED_CSV_FILE))
                        else toast.error(t(Lang.ERROR_IMPORTING_CSV_FILE))

                        reloadPlates()
                    }
                }
            }

            fileReader.readAsText(data.file[0])
        }
    }

    return (
        <Form onSubmit={handleSubmit(handleInsertPlates)}>
            <Form.Group controlId="importCSV" as={Row} className="d-flex align-items-center">
                <Col xs="12" lg="5" xl="6" className="d-flex align-items-center">
                    <Form.Label
                        className="my-auto border py-1 px-3 rounded-start text-truncate col-6"
                        ref={labelRef}
                    >
                        {t(Lang.IMPORT_CSV_FILE)}
                    </Form.Label>
                    <Form.Control
                        type="file"
                        className="d-none"
                        accept=".csv"
                        {...inputFileRegister}
                        onChange={(e) => {
                            inputFileRegister.onChange(e)
                            handleChange()
                        }}
                        ref={(e: unknown) => {
                            inputFileRegister.ref(e)
                            inputRef.current = e as HTMLInputElement
                        }}
                    />
                    <div className="border-2 border-end">
                        <Button
                            variant="primary"
                            onClick={handleUpload}
                            className="rounded-0 py-1 px-3"
                        >
                            {t(Lang.CHOOSE_FILE)}
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            variant="primary"
                            className="rounded-0 rounded-end py-1 px-3"
                        >
                            {t(Lang.SEND)}
                        </Button>
                    </div>
                </Col>
                <Col xs="12" lg="3" className="d-flex align-items-center">
                    <Form.Check type="checkbox" label="Reemplazar" {...register('replace')} />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default BlackImportCSV
