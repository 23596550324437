import { AxiosResponse } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, Form, Card, InputGroup, Row, Col, Container } from 'react-bootstrap'
import RecoveryModal from './components/RecoveryModal'
import * as Auth from '../../services/Authentication'
//import { AuthContext } from '../../components/auth/AuthContext';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import * as URL from '../../routes/URL'
import * as User from '../../services/User/User'
import TermsOfUseModal from './components/TermsOfUseModal'
import IUser from '../../services/User/User.interface'

interface LoginFormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement
    password: HTMLInputElement
    remember: HTMLInputElement
    twoFactorPassword: HTMLInputElement
}

export interface LoginFormElement extends HTMLFormElement {
    readonly elements: LoginFormElements
}

const LoginLayout = (): JSX.Element => {
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)
    const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false)
    const inputPassword = useRef<HTMLInputElement>(null)
    const inputTwoFactorPassword = useRef<HTMLInputElement>(null)
    const [userModal, setUserModal] = useState<IUser>()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleShow = (): void => setShow(true)
    const handleClose = (): void => setShow(false)

    const handleCloseTermsOfUseModal = (): void => setShowTermsOfUseModal(false)

    const handleSubmit = async (event: React.FormEvent<LoginFormElement>): Promise<void> => {
        event.preventDefault()
        const username = event.currentTarget.elements.username.value
        const password = event.currentTarget.elements.password.value
        const remember = event.currentTarget.elements.remember.checked
        const twoFactorPassword = event.currentTarget.elements.twoFactorPassword.value

        const authResponse: AxiosResponse = await Auth.login(
            username,
            password,
            remember,
            twoFactorPassword
        )

        if (authResponse.status === 200) {
            setMessage('')
            const user = await User.getUser(authResponse.data.userId)

            if (user) {
                if (user.acceptedTermsOfUse) navigate(URL.HOME)
                else {
                    setUserModal(user)
                    setShowTermsOfUseModal(true)
                }
            }
        }
        if (authResponse.status === 400) setMessage(t(Lang.INVALID_2FA_CODE))

        if (authResponse.status === 401) {
            const response = authResponse.data

            switch (response.error) {
                case 1:
                    setMessage(t(Lang.USERNAME_NOT_EXISTS))
                    break
                case 2:
                    setMessage(t(Lang.MAX_LOGIN_ATTEMPTS_EXCEEDED))
                    break
                case 3:
                    setMessage(
                        t(Lang.INVALID_PASSWORD, {
                            attempt: response.attempts
                        })
                    )
                    break
                case 4:
                    setMessage(t(Lang.EXPIRED_PASSWORD))
                    break
                case 6:
                    setMessage(t(Lang.INVALID_2FA_CODE))
                    break
            }

            if (response.error === 5) {
                if (inputTwoFactorPassword.current) {
                    inputTwoFactorPassword.current.disabled = false
                    inputTwoFactorPassword.current.className = 'form-control mb-3'
                }
            }
        }
        if (authResponse.status === 408) setMessage(t(Lang.LOST_CONNECTION_TO_SERVER))
    }

    const handleHideShowPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (!inputPassword.current) return

        if (event.currentTarget.className === 'p-0 text-secondary btn btn-link') {
            event.currentTarget.className = 'p-0 text-primary btn btn-link'
            inputPassword.current.type = 'text'
        } else {
            event.currentTarget.className = 'p-0 text-secondary btn btn-link'
            inputPassword.current.type = 'password'
        }
    }

    useEffect(() => {
        const load = async () => {
            const token = Auth.getToken()

            if (token) {
                const userId = Auth.getUserId()

                if (userId) {
                    const user = await User.getUser(userId)

                    if (user && user.acceptedTermsOfUse) navigate(URL.HOME)
                }
            }
        }

        load()
    }, [])

    return (
        <div className="align-items-center h-100 d-flex">
            <Container className="my-auto">
                <Row className="mb-5">
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        sm={{ span: 8, offset: 2 }}
                        lg={{ span: 4, offset: 4 }}
                    >
                        <Card className="shadow">
                            <Card.Header className="bg-white border-0 mt-2">
                                <h3>{t(Lang.SIGN_IN)}</h3>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="username">
                                            <FontAwesomeIcon
                                                icon={['fas', 'user']}
                                                viewBox="0 0 512 512"
                                            />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            placeholder={t(Lang.USERNAME_OR_EMAIL)}
                                            aria-label="Username"
                                            aria-describedby="username"
                                            required
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="password">
                                            <FontAwesomeIcon
                                                icon={['fas', 'key']}
                                                viewBox="0 0 512 512"
                                            />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder={t(Lang.PASSWORD)}
                                            aria-label="Password"
                                            aria-describedby="password"
                                            required
                                            ref={inputPassword}
                                        />
                                        <InputGroup.Text>
                                            <Button
                                                variant="link"
                                                className="p-0 text-secondary"
                                                onClick={handleHideShowPassword}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'eye']}
                                                    viewBox="0 0 512 512"
                                                />
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control
                                        type="text"
                                        className="d-none"
                                        name="twoFactorPassword"
                                        disabled
                                        placeholder={t(Lang.TWO_FACTOR_CODE)}
                                        ref={inputTwoFactorPassword}
                                    />

                                    <Form.Check
                                        type="checkbox"
                                        id="remember"
                                        name="remember"
                                        label={t(Lang.REMEMBER_ME)}
                                        className="mb-3"
                                    />
                                    <Button type="submit" variant="primary" className="w-100 mb-3">
                                        {t(Lang.LOGIN)}
                                    </Button>
                                    <div className="text-end">
                                        <Button variant="link" onClick={handleShow}>
                                            {t(Lang.FORGOT_PASSWORD)}
                                        </Button>
                                    </div>
                                    <Alert variant="danger" hidden={!message.length}>
                                        {message}
                                    </Alert>
                                </Form>
                                <RecoveryModal show={show} handleClose={handleClose} />
                                {userModal ? (
                                    <TermsOfUseModal
                                        show={showTermsOfUseModal}
                                        handleClose={handleCloseTermsOfUseModal}
                                        user={userModal}
                                    />
                                ) : (
                                    ''
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col
                        xs={{ span: 6, offset: 3 }}
                        sm={{ span: 4, offset: 4 }}
                        lg={{ span: 2, offset: 5 }}
                    >
                        <img src="/img/logoZBEHorizontal.png" className="w-100" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LoginLayout
