import { isArray } from 'highcharts'
import { Modal, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import IUser from '../../../services/User/User.interface'
import { Line } from '../../../services/Panel/Panel.interface'

interface LogModalProps {
    oldElement: object
    newElement: object
    show: boolean
    users: IUser[]
    handleClose: () => void
}

const LogModal = ({
    oldElement,
    newElement,
    show,
    users,
    handleClose
}: LogModalProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="very-large-modal">
            <Modal.Header closeButton className="border-0">
                <Modal.Title>{t(Lang.COMPARISON)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} xl={6}>
                        <Row>
                            <Col>
                                <h4>{t(Lang.OLD_ELEMENT)}</h4>
                            </Col>
                        </Row>
                        {Object.entries(oldElement).map(([label, value]) => {
                            return (
                                <Row key={label}>
                                    <Col>
                                        {isArray(value) ? (
                                            <>
                                                <div>
                                                    {label}:<br />
                                                </div>

                                                {value.map((str: string | Line) => {
                                                    if (label === 'users') {
                                                        const usersFiltered = users.filter(
                                                            (user) => user._id === str
                                                        )
                                                        return (
                                                            <div key={str as string}>
                                                                {usersFiltered.length
                                                                    ? usersFiltered[0].username
                                                                    : (str as string)}
                                                            </div>
                                                        )
                                                    }
                                                    if (label === 'lines') {
                                                        if (typeof str !== 'string')
                                                            return (
                                                                <div key={str._id}>{str.text}</div>
                                                            )
                                                    } else {
                                                        return (
                                                            <div key={str as string}>
                                                                {str as string}
                                                                <br />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </>
                                        ) : (
                                            `${label}: ${value}`
                                        )}
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                    <Col xs={12} xl={6}>
                        <Row>
                            <Col>
                                <h4>{t(Lang.NEW_ELEMENT)}</h4>
                            </Col>
                        </Row>
                        {Object.entries(newElement).map(([label, value]) => {
                            return (
                                <Row key={label}>
                                    <Col>
                                        {isArray(value) ? (
                                            <>
                                                <div>
                                                    {label}:<br />
                                                </div>

                                                {value.map((str: string | Line) => {
                                                    if (label === 'users') {
                                                        const usersFiltered = users.filter(
                                                            (user) => user._id === str
                                                        )
                                                        return (
                                                            <div key={str as string}>
                                                                {usersFiltered.length
                                                                    ? usersFiltered[0].username
                                                                    : (str as string)}
                                                            </div>
                                                        )
                                                    }
                                                    if (label === 'lines') {
                                                        if (typeof str !== 'string')
                                                            return (
                                                                <div key={str._id}>{str.text}</div>
                                                            )
                                                    } else {
                                                        return (
                                                            <div key={str as string}>
                                                                {str as string}
                                                                <br />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </>
                                        ) : (
                                            `${label}: ${value}`
                                        )}
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default LogModal
