import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import ISensor from './Sensor.interface'

const apiURL = `${baseURL}/sensors`

export const createSensor = async (sensor: ISensor): Promise<ISensor | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, sensor, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdSensor: ISensor = response.data
            return createdSensor
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getSensors = async (zone?: string): Promise<ISensor[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { zone }
        })

        if (response.data) {
            const sensors: ISensor[] = response.data
            return sensors
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getSensor = async (id: string): Promise<ISensor | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const sensor: ISensor = response.data
            return sensor
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateSensor = async (sensor: ISensor): Promise<ISensor | false> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiURL}/${sensor._id}`, sensor, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedSensor: ISensor = response.data
            return updatedSensor
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteSensor = async (id: string): Promise<ISensor | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedSensor: ISensor = response.data
            return deletedSensor
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
