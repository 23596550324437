import { Table, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../components/Loader'
import IEvent, { EnvironmentLabel } from '../../../services/Event/Event.interface'
import * as Event from '../../../services/Event/Event'
import ICamera from '../../../services/Camera/Camera.interface'
import moment from 'moment'

interface EventsTableProps {
    events: IEvent[]
    cameras: ICamera[]
    handleEditEvent: (event: React.MouseEvent<HTMLButtonElement>) => void
    reloadEvents: () => void
}

const EventsTable = ({ events, cameras, handleEditEvent, reloadEvents }: EventsTableProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState('')

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const handleDeleteEvent = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault()
        const button: HTMLButtonElement = event.currentTarget
        const id = button.value

        setLoading(id)

        const deletedEvent = await Event.deleteEvent(id)

        if (deletedEvent) {
            toast.success(t(Lang.EVENT_DELETED_SUCCESSFULLY))
            reloadEvents()
        } else toast.error(t(Lang.ERROR_DELETING_EVENT))

        setLoading('')
    }

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.PLATE)}</th>
                            <th>{t(Lang.CAMERA)}</th>
                            <th>{t(Lang.DIRECTION)}</th>
                            <th>{t(Lang.DATE)}</th>
                            <th>{t(Lang.TYPE_OF_REGISTRY)}</th>
                            <th>{t(Lang.ENVIRONMENT_LABEL)}</th>
                            <th>{t(Lang.SENT_SANCTION_DATE)}</th>
                            <th>{t(Lang.INVALIDATED_REASON)}</th>
                            <th>{t(Lang.RESTRICTION_MODE)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event: IEvent) => {
                            const camera = cameras.filter((camera) => camera._id === event.camera)

                            let environmentLabel: string | JSX.Element = ''

                            if (event.environmentLabel) {
                                switch (event.environmentLabel) {
                                    case EnvironmentLabel.B:
                                    case EnvironmentLabel.C:
                                    case EnvironmentLabel.Eco:
                                    case EnvironmentLabel.zero:
                                        environmentLabel = (
                                            <img
                                                src={`/img/environment_labels/${event.environmentLabel}.svg`}
                                                alt={`Distintivo ambiental ${t(
                                                    langObj[event.environmentLabel.toUpperCase()]
                                                )}`}
                                                title={`Distintivo ambiental ${t(
                                                    langObj[event.environmentLabel.toUpperCase()]
                                                )}`}
                                                style={{ maxWidth: '44px' }}
                                            />
                                        )
                                        break
                                    default:
                                        environmentLabel = t(
                                            langObj[event.environmentLabel.toUpperCase()]
                                        )
                                }
                            }

                            return (
                                <tr key={event._id}>
                                    <td></td>
                                    <td>{event.plate}</td>
                                    <td>{camera.length ? camera[0].name : ''}</td>
                                    <td>{t(langObj[event.direction.toUpperCase()])}</td>
                                    <td>{moment(event.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>{t(langObj[event.type.toUpperCase()])}</td>
                                    <td>{environmentLabel}</td>
                                    <td>
                                        {event.sentSanctionDate
                                            ? moment(event.sentSanctionDate).format(
                                                  'DD/MM/YYYY HH:mm:ss'
                                              )
                                            : ''}
                                    </td>
                                    <td>{event.invalidatedReason}</td>
                                    <td>
                                        {event.restrictionMode
                                            ? t(langObj[event.restrictionMode.toUpperCase()])
                                            : ''}
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={event._id}
                                            onClick={handleEditEvent}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={event._id}
                                            onClick={handleDeleteEvent}
                                        >
                                            {loading === event._id ? (
                                                <Loader loading={true} size={25} color="#dc3545" />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default EventsTable
