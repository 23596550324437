import { Table, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import IZone from '../../../services/Zone/Zone.interface'
import * as Zone from '../../../services/Zone/Zone'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../components/Loader'

interface ZonesTableProps {
    zones: IZone[]
    handleEditZone: (event: React.MouseEvent<HTMLButtonElement>) => void
    reloadZones: () => void
}

const ZonesTable = ({ zones, handleEditZone, reloadZones }: ZonesTableProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState('')

    const handleDeleteZone = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault()
        const button: HTMLButtonElement = event.currentTarget

        const id = button.value

        setLoading(id)

        const deletedZone = await Zone.deleteZone(id)

        if (deletedZone) {
            setLoading('')
            toast.success(t(Lang.ZONE_DELETED_SUCCESSFULLY, { zone: deletedZone }))
            reloadZones()
        } else {
            setLoading('')
            toast.error(t(Lang.ERROR_DELETING_ZONE, { zone: deletedZone }))
        }
    }

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.NAME)}</th>
                            <th>{t(Lang.DESCRIPTION)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {zones.map((zone) => {
                            return (
                                <tr key={zone._id}>
                                    <td></td>
                                    <td>{zone.name}</td>
                                    <td className="text-truncate">{zone.description}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            value={zone._id}
                                            onClick={handleEditZone}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'pen-to-square']}
                                                size="2x"
                                                fixedWidth
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="link"
                                            className="btn-delete"
                                            value={zone._id}
                                            onClick={handleDeleteZone}
                                        >
                                            {loading === zone._id ? (
                                                <Loader loading={true} size={25} color="#dc3545" />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash-can']}
                                                    size="2x"
                                                    fixedWidth
                                                />
                                            )}
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default ZonesTable
