import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { REACT_APP_BACKEND_URL } from '../../../configuration'
import * as Lang from '../../../i18n/constants'
import ICamera from '../../../services/Camera/Camera.interface'
import * as Event from '../../../services/Event/Event'
import IEvent from '../../../services/Event/Event.interface'

interface RealTimeCameraProps {
    camera: ICamera
    zoneId: string
    handleEditEvent: (event: IEvent) => void
    fetchEvent: boolean
}

const RealTimeCamera = ({
    camera,
    zoneId,
    handleEditEvent,
    fetchEvent
}: RealTimeCameraProps): JSX.Element => {
    const { t } = useTranslation()
    const [lastEvent, setLastEvent] = useState<IEvent>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    useEffect(() => {
        const loadLastEvent = async () => {
            const event = await Event.getEvents({
                zone: zoneId,
                camera: camera._id,
                limit: 1
            })

            if (event && event.length) setLastEvent(event[0])
        }
        loadLastEvent()
    }, [fetchEvent])

    const handleClick = () => {
        if (lastEvent) handleEditEvent(lastEvent)
    }

    return (
        <Row>
            {lastEvent ? (
                <Button
                    variant="link"
                    className="text-decoration-none text-black p-0"
                    value={lastEvent._id}
                    onClick={handleClick}
                >
                    <Col className="shadow p-3 rounded">
                        <Row className="mb-3">
                            <Col className="text-center">
                                <h4>{camera.name}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4">
                                {lastEvent ? (
                                    <>
                                        <Row className="mb-5">
                                            <Col>
                                                <img
                                                    src={`${REACT_APP_BACKEND_URL}/${lastEvent.imagePlateURL}`}
                                                    className="w-100"
                                                />
                                            </Col>
                                        </Row>
                                        {lastEvent.imageOCRURL && lastEvent.imageOCRURL.length ? (
                                            <Row className="mb-5">
                                                <Col>
                                                    <img
                                                        src={`${REACT_APP_BACKEND_URL}/${lastEvent.imageOCRURL}`}
                                                        className="w-100"
                                                    />
                                                </Col>
                                            </Row>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col xs="8">
                                {lastEvent ? (
                                    <img
                                        src={`${REACT_APP_BACKEND_URL}/${lastEvent.imageContextURL}`}
                                        className="w-100"
                                    />
                                ) : (
                                    ''
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Button>
            ) : (
                <Col className="shadow p-3 rounded">
                    <Row className="mb-3">
                        <Col className="text-center">
                            <h4>{`${t(Lang.CAMERA)} ${camera.name}`}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4"></Col>
                        <Col xs="8"></Col>
                    </Row>
                </Col>
            )}
        </Row>
    )
}

export default RealTimeCamera
