import React, { useEffect, useState } from 'react'
import { Modal, Button, FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import IOption from '../../../services/Option/Option.interface'
import * as Option from '../../../services/Option/Option'
import IUser from '../../../services/User/User.interface'
import * as User from '../../../services/User/User'
import * as URL from '../../../routes/URL'
import { useNavigate } from 'react-router-dom'

interface TermsOfUseProps {
    show: boolean
    handleClose: () => void
    user: IUser
}

const TermsOfUseModal = ({ show, handleClose, user }: TermsOfUseProps): JSX.Element => {
    const { t } = useTranslation()
    const [termsOfUseOption, setTermsOfUseOption] = useState<IOption>()
    const navigate = useNavigate()

    const acceptTermsOfUse = async () => {
        const updatedUser: IUser = {
            _id: user._id,
            username: user.username,
            email: user.email,
            firstname: user.firstname,
            role: user.role,
            twoFactorEnabled: user.twoFactorEnabled,
            acceptedTermsOfUse: true
        }

        await User.updateUser(updatedUser)
        handleClose()
        navigate(URL.HOME)
    }

    useEffect(() => {
        const load = async () => {
            const options = await Option.getOptions()

            if (options) {
                const optionsList: IOption[] = options.filter(
                    (option) => option.name === 'termsOfUse'
                )

                if (optionsList.length) setTermsOfUseOption(optionsList[0])
            }
        }
        load()
    }, [])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>{t(Lang.TERMS_OF_USE)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {termsOfUseOption ? (
                    <FormControl
                        as="textarea"
                        rows={15}
                        readOnly
                        defaultValue={termsOfUseOption.value}
                    />
                ) : (
                    ''
                )}
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="secondary" onClick={handleClose}>
                    {t(Lang.CANCEL)}
                </Button>
                <Button type="submit" variant="primary" onClick={acceptTermsOfUse}>
                    {t(Lang.ACCEPT)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TermsOfUseModal
