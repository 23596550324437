import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ErrorField from '../../../components/form/ErrorField'
import Loader from '../../../components/Loader'
import { REACT_APP_BACKEND_URL } from '../../../configuration'
import * as Lang from '../../../i18n/constants'
import ICamera from '../../../services/Camera/Camera.interface'
import * as Event from '../../../services/Event/Event'
import IEvent, {
    carDirection,
    carState,
    EnvironmentLabel,
    typeEvent
} from '../../../services/Event/Event.interface'
import * as Option from '../../../services/Option/Option'
import { RestrictionMode } from '../../../services/Option/Option.interface'
import * as Plate from '../../../services/Plate/Plate'
import IPlate from '../../../services/Plate/Plate.interface'

interface EventModalForm {
    _id?: string
    plate: string
    camera: string
    zone: string
    type: typeEvent
    country?: string
    confidence?: number
    state?: carState
    direction: carDirection
    imagePlateURL?: string
    imageContextURL?: string
    imageOCRURL?: string
    imagePixelatedURL?: string
    sentSanctionDate?: string
    environmentLabel?: EnvironmentLabel
    brand?: string
    model?: string
    color?: string
}

interface EventModalProps {
    show: boolean
    handleClose: () => void
    reloadEvents: () => void
    event?: IEvent | false
    cameras: ICamera[]
    zoneId: string
}

const EventModal = ({
    show,
    handleClose,
    reloadEvents,
    event,
    cameras,
    zoneId
}: EventModalProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [plate, setPlate] = useState<IPlate>()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<EventModalForm>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const handleCreateEvent = async (data: EventModalForm): Promise<void> => {
        setLoading(true)

        const options = await Option.getOptions()

        if (!options) return setLoading(false)

        const restrictionMode = options.find((option) => option.name === 'restrictionMode')

        if (!restrictionMode) return setLoading(false)

        const event: IEvent = {
            plate: data.plate,
            camera: data.camera,
            zone: zoneId,
            type: data.type,
            country: data.country,
            confidence: data.confidence,
            state: data.state,
            direction: data.direction,
            imagePlateURL: data.imagePlateURL,
            imageContextURL: data.imageContextURL,
            imageOCRURL: data.imageOCRURL,
            imagePixelatedURL: data.imagePixelatedURL,
            sentSanctionDate: moment(data.sentSanctionDate, 'YYYY-MM-DD HH:mm').toDate(),
            environmentLabel: data.environmentLabel,
            restrictionMode: restrictionMode.value as RestrictionMode
        }

        const createdEvent = await Event.createEvent(event)

        if (createdEvent) {
            toast.success(t(Lang.EVENT_CREATED_SUCCESSFULLY))
            reloadEvents()
        } else toast.error(t(Lang.ERROR_CREATING_EVENT))

        const plate = await Plate.getPlates(undefined, event.plate)

        if (plate && plate.length) {
            await Plate.updatePlate({
                ...plate[0],
                brand: data.brand,
                model: data.model,
                color: data.color
            })
        } else {
            await Plate.createPlate({
                plate: event.plate,
                brand: data.brand,
                color: data.color
            })
        }

        setLoading(false)

        handleClose()
    }

    const handleUpdateEvent = async (data: EventModalForm): Promise<void | React.ReactText> => {
        setLoading(true)

        if (event && event.sentSanctionDate) {
            setLoading(false)
            return toast.error(t(Lang.SANCTION_SENT_CANNOT_BE_MODIFIED))
        }

        const options = await Option.getOptions()

        if (!options) return setLoading(false)

        const restrictionMode = options.find((option) => option.name === 'restrictionMode')

        if (!restrictionMode) return setLoading(false)

        const updateEvent: IEvent = {
            _id: data._id,
            plate: data.plate,
            camera: data.camera,
            zone: zoneId,
            type: data.type,
            country: data.country,
            confidence: data.confidence,
            state: data.state,
            direction: data.direction,
            imagePlateURL: data.imagePlateURL,
            imageContextURL: data.imageContextURL,
            imageOCRURL: data.imageOCRURL,
            imagePixelatedURL: data.imagePixelatedURL,
            sentSanctionDate: moment(data.sentSanctionDate, 'YYYY-MM-DD HH:mm').toDate(),
            environmentLabel: data.environmentLabel
        }

        const updatedEvent = await Event.updateEvent(updateEvent)

        if (updatedEvent) {
            toast.success(t(Lang.EVENT_UPDATED_SUCCESSFULLY))
            reloadEvents()
        } else toast.error(t(Lang.ERROR_UPDATING_EVENT))

        if (plate) {
            await Plate.updatePlate({
                ...plate,
                brand: data.brand,
                model: data.model,
                color: data.color
            })

            setPlate(plate)
        } else
            await Plate.createPlate({
                plate: data.plate,
                brand: data.brand,
                model: data.model,
                color: data.color
            })

        setLoading(false)
        handleClose()
    }

    useEffect(() => {
        const load = async () => {
            if (event) {
                const eventForm: EventModalForm = {
                    _id: event._id,
                    plate: event.plate,
                    camera: event.camera as string,
                    zone: zoneId,
                    type: event.type,
                    country: event.country,
                    confidence: event.confidence,
                    state: event.state,
                    direction: event.direction,
                    imagePlateURL: event.imagePlateURL,
                    imageContextURL: event.imageContextURL,
                    imageOCRURL: event.imageOCRURL,
                    imagePixelatedURL: event.imagePixelatedURL,
                    sentSanctionDate: event.sentSanctionDate
                        ? moment(event.sentSanctionDate).format('YYYY-MM-DDTHH:mm')
                        : '',
                    environmentLabel: event.environmentLabel,
                    brand: '',
                    model: '',
                    color: ''
                }

                const plate = await Plate.getPlates(undefined, event.plate)

                if (plate && plate.length) {
                    eventForm.brand = plate[0].brand
                    eventForm.model = plate[0].model
                    eventForm.color = plate[0].color
                    setPlate(plate[0])
                }

                reset(eventForm)
            } else
                reset({
                    plate: '',
                    camera: '',
                    zone: zoneId,
                    type: typeEvent.possibleInfringement,
                    country: 'ES',
                    confidence: 1,
                    state: carState.new,
                    direction: carDirection.in,
                    imagePlateURL: '',
                    imageContextURL: '',
                    imageOCRURL: '',
                    imagePixelatedURL: '',
                    sentSanctionDate: '',
                    environmentLabel: EnvironmentLabel.without,
                    brand: '',
                    model: '',
                    color: ''
                })
        }
        load()
    }, [event])

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="large-modal">
            <Form
                onSubmit={event ? handleSubmit(handleUpdateEvent) : handleSubmit(handleCreateEvent)}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>{event ? t(Lang.EDIT_EVENT) : t(Lang.ADD_EVENT)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PLATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.plate ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="plate"
                            type="text"
                            maxLength={32}
                            placeholder={'* ' + t(Lang.PLATE)}
                            aria-label={t(Lang.PLATE)}
                            aria-describedby={t(Lang.PLATE)}
                            className={errors.plate ? 'border-2 border-danger' : ''}
                            {...register('plate', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.PLATE)
                                }) as string,
                                maxLength: {
                                    value: 32,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.PLATE),
                                        value: 32
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.plate && <ErrorField message={errors.plate.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.CAMERA)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.camera
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'video']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="camera"
                            {...register('camera', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.CAMERA)
                                }) as string,
                                maxLength: {
                                    value: 32,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.CAMERA),
                                        value: 32
                                    })
                                }
                            })}
                        >
                            {cameras.map((camera) => {
                                return (
                                    <option key={camera._id} value={camera._id}>
                                        {camera.name}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.camera && <ErrorField message={errors.camera.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.TYPE_OF_REGISTRY)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.type ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'rectangle-list']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="type"
                            {...register('type', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.TYPE_OF_REGISTRY)
                                }) as string
                            })}
                        >
                            {Object.keys(typeEvent).map((type) => {
                                return (
                                    <option key={type} value={type}>
                                        {t(langObj[type.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.type && <ErrorField message={errors.type.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COUNTRY)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.country
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'earth-europe']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="country"
                            type="text"
                            maxLength={64}
                            placeholder={t(Lang.COUNTRY)}
                            aria-label={t(Lang.COUNTRY)}
                            aria-describedby={t(Lang.COUNTRY)}
                            className={errors.country ? 'border-2 border-danger' : ''}
                            {...register('country', {
                                maxLength: {
                                    value: 64,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COUNTRY),
                                        value: 64
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.country && <ErrorField message={errors.country.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.CONFIDENCE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.confidence
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'thumbs-up']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="confidence"
                            type="number"
                            step="0.000001"
                            min={0}
                            max={1}
                            maxLength={11}
                            placeholder={t(Lang.CONFIDENCE)}
                            aria-label={t(Lang.CONFIDENCE)}
                            aria-describedby={t(Lang.CONFIDENCE)}
                            className={errors.confidence ? 'border-2 border-danger' : ''}
                            {...register('confidence', {
                                maxLength: {
                                    value: 11,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.CONFIDENCE),
                                        value: 11
                                    })
                                },
                                min: {
                                    value: 0,
                                    message: t(Lang.MIN_FIELD, {
                                        field: t(Lang.CONFIDENCE),
                                        value: 0
                                    })
                                },
                                max: {
                                    value: 1,
                                    message: t(Lang.MAX_FIELD, {
                                        field: t(Lang.CONFIDENCE),
                                        value: 1
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.confidence && <ErrorField message={errors.confidence.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.CAR_STATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.state ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'car-side']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select id="state" {...register('state')}>
                            {Object.keys(carState).map((state) => {
                                return (
                                    <option key={state} value={state}>
                                        {t(langObj[state.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.state && <ErrorField message={errors.state.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DIRECTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.direction
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-arrow']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="direction"
                            {...register('direction', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.DIRECTION)
                                }) as string
                            })}
                        >
                            {Object.keys(carDirection).map((direction) => {
                                return (
                                    <option key={direction} value={direction}>
                                        {t(langObj[direction.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.direction && <ErrorField message={errors.direction.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.SENT_SANCTION_DATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.sentSanctionDate
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'calendar-days']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="sentSanctionDate"
                            type="datetime-local"
                            defaultValue=""
                            aria-label={t(Lang.SENT_SANCTION_DATE)}
                            aria-describedby={t(Lang.SENT_SANCTION_DATE)}
                            className={errors.sentSanctionDate ? 'border-2 border-danger' : ''}
                            {...register('sentSanctionDate')}
                        />
                    </InputGroup>
                    {errors.sentSanctionDate && (
                        <ErrorField message={errors.sentSanctionDate.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.ENVIRONMENT_LABEL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.environmentLabel
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'leaf']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="environmentLabel"
                            {...register('environmentLabel', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.ENVIRONMENT_LABEL)
                                }) as string
                            })}
                        >
                            {Object.keys(EnvironmentLabel).map((label) => {
                                return (
                                    <option key={label} value={label}>
                                        {t(langObj[label.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.environmentLabel && (
                        <ErrorField message={errors.environmentLabel.message} />
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.IMAGE_PLATE_URL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.imagePlateURL
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'rectangle-list']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="imagePlateURL"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.IMAGE_PLATE_URL)}
                            aria-label={t(Lang.IMAGE_PLATE_URL)}
                            aria-describedby={t(Lang.IMAGE_PLATE_URL)}
                            className={errors.imagePlateURL ? 'border-2 border-danger' : ''}
                            {...register('imagePlateURL', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.IMAGE_PLATE_URL),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.imagePlateURL && <ErrorField message={errors.imagePlateURL.message} />}
                    {event && event.imagePlateURL && event.imagePlateURL.length ? (
                        <Row className="mb-3">
                            <Col xs="6">
                                <img
                                    src={`${REACT_APP_BACKEND_URL}/${event.imagePlateURL}`}
                                    className="w-100 card"
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.IMAGE_OCR_URL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.imageOCRURL
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'car-rear']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="imageOCRURL"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.IMAGE_OCR_URL)}
                            aria-label={t(Lang.IMAGE_OCR_URL)}
                            aria-describedby={t(Lang.IMAGE_OCR_URL)}
                            className={errors.imageOCRURL ? 'border-2 border-danger' : ''}
                            {...register('imageOCRURL', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.IMAGE_OCR_URL),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.imageOCRURL && <ErrorField message={errors.imageOCRURL.message} />}
                    {event && event.imageOCRURL && event.imageOCRURL.length ? (
                        <Row className="mb-3">
                            <Col xs="6">
                                <img
                                    src={`${REACT_APP_BACKEND_URL}/${event.imageOCRURL}`}
                                    className="w-100 card"
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.IMAGE_PIXELATED_URL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.imagePixelatedURL
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'table-cells']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="imagePixelatedURL"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.IMAGE_PIXELATED_URL)}
                            aria-label={t(Lang.IMAGE_PIXELATED_URL)}
                            aria-describedby={t(Lang.IMAGE_PIXELATED_URL)}
                            className={errors.imagePixelatedURL ? 'border-2 border-danger' : ''}
                            {...register('imagePixelatedURL', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.IMAGE_PIXELATED_URL),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.imagePixelatedURL && (
                        <ErrorField message={errors.imagePixelatedURL.message} />
                    )}
                    {event && event.imagePixelatedURL && event.imagePixelatedURL.length ? (
                        <Row className="mb-3">
                            <Col xs="6">
                                <img
                                    src={`${REACT_APP_BACKEND_URL}/${event.imagePixelatedURL}`}
                                    className="w-100 card"
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.IMAGE_CONTEXT_URL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.imageContextURL
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'image']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="imageContextURL"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.IMAGE_CONTEXT_URL)}
                            aria-label={t(Lang.IMAGE_CONTEXT_URL)}
                            aria-describedby={t(Lang.IMAGE_CONTEXT_URL)}
                            className={errors.imageContextURL ? 'border-2 border-danger' : ''}
                            {...register('imageContextURL', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.IMAGE_CONTEXT_URL),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.imageContextURL && (
                        <ErrorField message={errors.imageContextURL.message} />
                    )}
                    {event && event.imageContextURL && event.imageContextURL.length ? (
                        <Row className="mb-3">
                            <Col xs="12">
                                <img
                                    src={`${REACT_APP_BACKEND_URL}/${event.imageContextURL}`}
                                    className="w-100 card"
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.BRAND)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.brand ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'industry']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="brand"
                            type="text"
                            maxLength={128}
                            placeholder={t(Lang.BRAND)}
                            aria-label={t(Lang.BRAND)}
                            aria-describedby={t(Lang.BRAND)}
                            className={errors.brand ? 'border-2 border-danger' : ''}
                            {...register('brand', {
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.BRAND),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.brand && <ErrorField message={errors.brand.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MODEL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.model ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="model"
                            type="text"
                            maxLength={128}
                            placeholder={t(Lang.MODEL)}
                            aria-label={t(Lang.MODEL)}
                            aria-describedby={t(Lang.MODEL)}
                            className={errors.model ? 'border-2 border-danger' : ''}
                            {...register('model', {
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.MODEL),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.model && <ErrorField message={errors.model.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COLOR)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.color ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'palette']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="color"
                            type="text"
                            maxLength={64}
                            placeholder={t(Lang.COLOR)}
                            aria-label={t(Lang.COLOR)}
                            aria-describedby={t(Lang.COLOR)}
                            className={errors.color ? 'border-2 border-danger' : ''}
                            {...register('color', {
                                maxLength: {
                                    value: 64,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COLOR),
                                        value: 64
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.color && <ErrorField message={errors.color.message} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        disabled={
                            event &&
                            event.sentSanctionDate !== undefined &&
                            event.sentSanctionDate !== null
                        }
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader loading={loading} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default EventModal
