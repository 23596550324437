import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IAlert from './Alert.interface'
import { AlertsFiltersForm } from '../../pages/Alerts/components/AlertsFilters'

const apiURL = `${baseURL}/alerts`

export const createAlert = async (alert: IAlert): Promise<IAlert | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, alert, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdAlert: IAlert = response.data
            return createdAlert
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getAlerts = async (filter?: AlertsFiltersForm): Promise<IAlert[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: filter
        })

        if (response.data) {
            const alerts: IAlert[] = response.data
            return alerts
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getAlertsCount = async (filter?: AlertsFiltersForm): Promise<number | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}`, {
            headers: Auth.authHeader(),
            params: { ...filter, count: true }
        })

        if (response.data) {
            const alertsCount: number = response.data
            return alertsCount
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getAlert = async (id: string): Promise<IAlert | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const alert: IAlert = response.data
            return alert
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateAlert = async (alert: IAlert): Promise<IAlert | false> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiURL}/${alert._id}`, alert, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedAlert: IAlert = response.data
            return updatedAlert
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteAlert = async (id: string): Promise<IAlert | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedAlert: IAlert = response.data
            return deletedAlert
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
