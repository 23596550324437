import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { REACT_APP_BACKEND_URL } from '../../configuration'
import * as Lang from '../../i18n/constants'
import * as URL from '../../routes/URL'
import IUser, { Role } from '../../services/User/User.interface'
import './Menu.css'

interface MenuProps {
    currentPage: string
    user: IUser
}

const Menu = ({ currentPage, user }: MenuProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <Nav className="bg-primary h-100 text-white flex-column">
            <div className="py-3 link-light text-center text-decoration-none border-bottom">
                <span className="fs-5 fw-semibold">{currentPage}</span>
            </div>
            <ul className="list-unstyled">
                <li className="mb-1">
                    <Button variant="primary" href={URL.HOME} className="w-100 text-start btn-menu">
                        <FontAwesomeIcon icon={['fas', 'house']} fixedWidth />
                        <span className="ms-2">{t(Lang.HOME)}</span>
                    </Button>
                </li>
                <li className="mb-1">
                    <Button
                        variant="primary"
                        href={URL.REAL_TIME}
                        className="w-100 text-start btn-menu"
                    >
                        <FontAwesomeIcon icon={['fas', 'clock']} fixedWidth />
                        <span className="ms-2">{t(Lang.REAL_TIME)}</span>
                    </Button>
                </li>
                <li className="mb-1">
                    <Button
                        variant="primary"
                        href={URL.ANALYTICS}
                        className="w-100 text-start btn-menu"
                    >
                        <FontAwesomeIcon icon={['fas', 'chart-column']} fixedWidth />
                        <span className="ms-2">{t(Lang.ANALYTICS)}</span>
                    </Button>
                </li>
                <li className="mb-1">
                    <Accordion>
                        <Accordion.Button
                            as={Button}
                            color="primary"
                            className="w-100 text-start rounded btn-menu"
                        >
                            <FontAwesomeIcon icon={['fas', 'gavel']} fixedWidth />
                            <span className="ms-2">{t(Lang.SANCTIONS)}</span>
                        </Accordion.Button>
                        <Accordion.Body className="bg-secondary">
                            <ul className="list-unstyled">
                                <li>
                                    <Button
                                        variant="link"
                                        href={URL.SANCTIONS}
                                        className="text-decoration-none text-white w-100 text-start"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'gavel']} fixedWidth />
                                        <span className="ms-2">{t(Lang.SANCTIONS)}</span>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        variant="link"
                                        href={URL.REMITTANCES}
                                        className="text-decoration-none text-white w-100 text-start"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'paper-plane']} fixedWidth />
                                        <span className="ms-2">{t(Lang.REMITTANCES)}</span>
                                    </Button>
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion>
                </li>
                <li className="mb-1">
                    <Accordion>
                        <Accordion.Button
                            as={Button}
                            color="primary"
                            className="w-100 text-start rounded btn-menu"
                        >
                            <FontAwesomeIcon icon={['fas', 'list']} fixedWidth />
                            <span className="ms-2">{t(Lang.LISTS)}</span>
                        </Accordion.Button>
                        <Accordion.Body className="bg-secondary">
                            <ul className="list-unstyled">
                                <li>
                                    <Button
                                        variant="link"
                                        href={URL.WHITE_LISTS}
                                        className="text-decoration-none text-white w-100 text-start"
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'rectangle-list']}
                                            fixedWidth
                                        />
                                        <span className="ms-2">{t(Lang.WHITE_LISTS)}</span>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        variant="link"
                                        href={URL.BLACK_LISTS}
                                        className="text-decoration-none text-white w-100 text-start"
                                    >
                                        <FontAwesomeIcon
                                            icon={['far', 'rectangle-list']}
                                            fixedWidth
                                        />
                                        <span className="ms-2">{t(Lang.BLACK_LISTS)}</span>
                                    </Button>
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion>
                </li>
                <li className="mb-1">
                    <Button
                        variant="primary"
                        href={URL.REGISTRY}
                        className="w-100 text-start btn-menu"
                    >
                        <FontAwesomeIcon icon={['fas', 'file-lines']} fixedWidth />
                        <span className="ms-2">{t(Lang.REGISTRY)}</span>
                    </Button>
                </li>
                <li className="mb-1">
                    <Button
                        variant="primary"
                        href={URL.ALERTS}
                        className="w-100 text-start btn-menu"
                    >
                        <FontAwesomeIcon icon={['fas', 'circle-exclamation']} fixedWidth />
                        <span className="ms-2">{t(Lang.ALERTS)}</span>
                    </Button>
                </li>
                <li className="mb-1">
                    <Button
                        variant="primary"
                        href={URL.WARNINGS}
                        className="w-100 text-start btn-menu"
                    >
                        <FontAwesomeIcon icon={['fas', 'triangle-exclamation']} fixedWidth />
                        <span className="ms-2">{t(Lang.WARNINGS)}</span>
                    </Button>
                </li>
                {user.role === Role.admin ? (
                    <li className="mb-1">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Button
                                as={Button}
                                color="primary"
                                className="w-100 text-start rounded btn-menu"
                            >
                                <FontAwesomeIcon icon={['fas', 'screwdriver-wrench']} fixedWidth />
                                <span className="ms-2">{t(Lang.SETTINGS)}</span>
                            </Accordion.Button>
                            <Accordion.Body className="bg-secondary p-0">
                                <ul className="list-unstyled">
                                    <li>
                                        <Button
                                            variant="link"
                                            href={URL.USERS}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'user']} fixedWidth />
                                            <span className="ms-2">{t(Lang.USERS)}</span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            variant="link"
                                            href={URL.ZONES}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'vector-square']}
                                                fixedWidth
                                            />
                                            <span className="ms-2">{t(Lang.ZONES)}</span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            variant="link"
                                            href={URL.CAMERAS}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'video']} fixedWidth />
                                            <span className="ms-2">{t(Lang.CAMERAS)}</span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            variant="link"
                                            href={URL.PANELS}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'chalkboard']}
                                                fixedWidth
                                            />
                                            <span className="ms-2">{t(Lang.PANELS)}</span>
                                        </Button>
                                    </li>
                                    <Accordion defaultActiveKey="1">
                                        <Accordion.Button
                                            as={Button}
                                            className="w-100 text-start rounded btn-menu inner"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'memory']} fixedWidth />
                                            <span className="ms-2">{t(Lang.SENSORS)}</span>
                                        </Accordion.Button>
                                        <Accordion.Body className="inner">
                                            <li>
                                                <Button
                                                    variant="link"
                                                    href={URL.SENSORS}
                                                    className="text-decoration-none text-white w-100 text-start"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'memory']}
                                                        fixedWidth
                                                    />
                                                    <span className="ms-2">{t(Lang.SENSORS)}</span>
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    variant="link"
                                                    href={URL.SENSOR_TYPES}
                                                    className="text-decoration-none text-white w-100 text-start"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'cubes']}
                                                        fixedWidth
                                                    />
                                                    <span className="ms-2">
                                                        {t(Lang.SENSOR_TYPES)}
                                                    </span>
                                                </Button>
                                            </li>
                                        </Accordion.Body>
                                    </Accordion>

                                    <li>
                                        <Button
                                            variant="link"
                                            href={URL.SETTINGS}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'gears']} fixedWidth />
                                            <span className="ms-2">{t(Lang.SYSTEM)}</span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            variant="link"
                                            href={URL.LOG}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'file-lines']}
                                                fixedWidth
                                            />
                                            <span className="ms-2">{t(Lang.LOG)}</span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            variant="link"
                                            href={`${REACT_APP_BACKEND_URL}/api/documentation`}
                                            className="text-decoration-none text-white w-100 text-start"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'gear']} fixedWidth />
                                            <span className="ms-2">API</span>
                                        </Button>
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion>
                    </li>
                ) : (
                    ''
                )}
            </ul>
        </Nav>
    )
}

export default Menu
