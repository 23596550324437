import { CSVLink } from 'react-csv'
import * as Lang from '../../../i18n/constants'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ILog from '../../../services/Log/Log.interface'

interface ICSV {
    [key: string]: string | undefined
}

interface LogExportCSVProps {
    logs: ILog[]
}

const LogExportCSV = ({ logs }: LogExportCSVProps): JSX.Element => {
    const { t } = useTranslation()

    const headers = [
        { label: 'ip', key: 'ip' },
        { label: 'user', key: 'user' },
        { label: 'date', key: 'date' },
        { label: 'action', key: 'action' },
        { label: 'category', key: 'category' },
        { label: 'description', key: 'description' }
    ]

    const filename = `log_${moment(new Date()).format('DD-MM-YYYY HH:mm')}.csv`

    const data: ICSV[] = []

    for (const log of logs) {
        const dataLog: ICSV = {
            ip: log.ip,
            user: log.user,
            date: moment(log.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            action: log.action,
            category: log.category,
            description: log.description
        }

        data.push(dataLog)
    }

    return (
        <CSVLink headers={headers} data={data} filename={filename} separator=";">
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t(Lang.EXPORT_CSV_FILE)}</Tooltip>}
            >
                <Button variant="link" className="btn-add">
                    <FontAwesomeIcon icon={['fas', 'file-csv']} size="2x" fixedWidth />
                </Button>
            </OverlayTrigger>
        </CSVLink>
    )
}

export default LogExportCSV
