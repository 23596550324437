export enum Role {
    admin = 'admin',
    user = 'user'
}

export default interface IUser {
    _id?: string
    username: string
    password?: string
    passwordExpiration?: Date
    passwordHistory?: [string]
    loginAttempts?: number
    email: string
    firstname: string
    lastname?: string
    role: Role
    phone?: string
    recoveryPassword?: string
    recoveryExpiration?: Date
    twoFactorEnabled: boolean
    twoFactorPassword?: string
    twoFactorExpiration?: Date
    acceptedTermsOfUse?: boolean
    acceptedTermsOfUseDate?: Date
    createdAt?: Date
    updatedAt?: Date
}
