import IZone from '../Zone/Zone.interface'
import ICalendar from '../Calendar/Calendar.interface'
import IExpiration from '../Expiration/Expiration.interface'

export enum TypeList {
    black = 'black',
    white = 'white'
}

export default interface IList {
    _id?: string
    name: string
    description?: string
    type: TypeList
    zones: [IZone['_id']]
    enabled: boolean
    webAlert: boolean
    emailAlert: boolean
    emailRecipients?: string[]
    calendar?: ICalendar
    requiredCensus?: boolean
    requiredAuthorization?: boolean
    maxPerUser?: number
    expirationPerUser?: IExpiration
    maxPerHome?: number
    expirationPerHome?: IExpiration
    defaultExpiration?: IExpiration
    createdAt?: Date
    updatedAt?: Date
}
