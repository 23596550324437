import React, { useState } from 'react'

interface Context {
    userInfo: string
    setUserInfo: (user: string) => void
}

export const AuthContext = React.createContext<Context>({
    userInfo: '',
    setUserInfo: (user: string) =>
        console.log(`Did you gorgot to add AuthContext on top of your app for ${user}?`)
})

export const AuthProvider: React.FC<any> = ({ children }) => {
    const [userInfo, setUserInfo] = useState<string>('')

    return <AuthContext.Provider value={{ userInfo, setUserInfo }}>{children}</AuthContext.Provider>
}
