import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import App from './App'
import './i18n/config'
import './index.css'

const rootElement = document.getElementById('root')

if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
        <div className="h-100">
            <App />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="colored"
            />
        </div>
    )
}
