import { cloneElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as User from '../../services/User/User'
import IUser from '../../services/User/User.interface'
import * as Auth from '../../services/Authentication'

interface AuthWrapperProps {
    children: JSX.Element
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
    const navigate = useNavigate()
    const [user, setUser] = useState<IUser>()
    const userId = Auth.getUserId()

    useEffect(() => {
        const loadUser = async () => {
            if (!userId) return navigate('/')
            const user = await User.getUser(userId)

            if (user && user.acceptedTermsOfUse) {
                setUser(user)
            } else navigate('/')
        }
        loadUser()
    }, [])

    return <>{cloneElement(children, { user })}</>
}
