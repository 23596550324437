import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

export interface RemittancesFilterForm {
    zone?: string
    start?: string
    end?: string
    page?: number
    limit?: number
}

interface RemittancesFilterProps {
    handleFilter: (data: RemittancesFilterForm) => void
}

const RemittancesFilter = ({ handleFilter }: RemittancesFilterProps): JSX.Element => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<RemittancesFilterForm>()

    return (
        <Form onSubmit={handleSubmit(handleFilter)}>
            <Row>
                <Col className="mb-3" lg="3" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.start ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-start']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="startFilter"
                            type="datetime-local"
                            defaultValue={moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm')}
                            {...register('start')}
                        />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="3" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.end ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-end']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="endFilter"
                            type="datetime-local"
                            defaultValue={moment().format('YYYY-MM-DD HH:mm')}
                            {...register('end')}
                        />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="1" sm="2">
                    <Button variant="primary" type="submit" className="w-100">
                        {t(Lang.FILTER)}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default RemittancesFilter
