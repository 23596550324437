import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ErrorField from '../../components/form/ErrorField'

export interface PlatesListFilterForm {
    plate?: string
    owner?: string
}

interface PlatesListFilterProps {
    handleFilter: (data: PlatesListFilterForm) => void
}

const PlatesListFilter = ({ handleFilter }: PlatesListFilterProps) => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<PlatesListFilterForm>()

    return (
        <Form onSubmit={handleSubmit(handleFilter)}>
            <Row>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PLATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.plate ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="plate"
                            type="text"
                            maxLength={32}
                            placeholder={t(Lang.PLATE)}
                            aria-label={t(Lang.PLATE)}
                            aria-describedby={t(Lang.PLATE)}
                            className={errors.plate ? 'border-2 border-danger' : ''}
                            {...register('plate', {
                                maxLength: {
                                    value: 32,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.PLATE),
                                        value: 32
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.plate && <ErrorField message={errors.plate.message} />}
                </Col>
                <Col className="mb-3" lg="4" md="4" sm="5">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.OWNER)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.owner ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="owner"
                            type="text"
                            maxLength={256}
                            placeholder={t(Lang.OWNER)}
                            aria-label={t(Lang.OWNER)}
                            aria-describedby={t(Lang.OWNER)}
                            className={errors.owner ? 'border-2 border-danger' : ''}
                            {...register('owner', {
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.OWNER),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.owner && <ErrorField message={errors.owner.message} />}
                </Col>
                <Col className="mb-3" lg="1" sm="2">
                    <Button variant="primary" type="submit" className="w-100">
                        {t(Lang.FILTER)}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default PlatesListFilter
