import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IUser from '../../../services/User/User.interface'
import { LogCategory } from '../../../services/Log/Log.interface'

export interface LogFilterForm {
    user?: string
    category?: string
    start?: string
    end?: string
    page?: number
    limit?: number
    count?: number
}

interface LogFiltersProps {
    users?: IUser[]
    handleFilter: (data: LogFilterForm) => void
}

const LogFilters = ({ users, handleFilter }: LogFiltersProps): JSX.Element => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<LogFilterForm>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    return (
        <Form onSubmit={handleSubmit(handleFilter)}>
            <Row>
                {users ? (
                    <Col className="mb-3" lg="2" md="3" sm="4">
                        <InputGroup>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>{t(Lang.USER)}</Tooltip>}
                            >
                                <InputGroup.Text
                                    className={`text-white ${
                                        errors.user
                                            ? 'bg-danger border-2 border-danger'
                                            : 'bg-primary'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'user']}
                                        viewBox="0 0 512 512"
                                        fixedWidth
                                    />
                                </InputGroup.Text>
                            </OverlayTrigger>
                            <Form.Select id="userFilter" {...register('user')} defaultValue="">
                                <option value="">{t(Lang.ALL)}</option>
                                {users.map((user) => {
                                    return (
                                        <option key={user.username} value={user.username}>
                                            {user.username}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                ) : (
                    ''
                )}
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.start ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-start']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="startFilter"
                            type="datetime-local"
                            {...register('start')}
                        />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.end ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-end']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control id="endFilter" type="datetime-local" {...register('end')} />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.CATEGORY)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.user ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'user']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select id="categoryFilter" defaultValue="" {...register('category')}>
                            <option value="">{t(Lang.FEMALE_ALL)}</option>
                            {Object.keys(LogCategory).map((category) => {
                                return (
                                    <option key={category} value={category}>
                                        {t(langObj[category.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg="1" sm="2">
                    <Button variant="primary" type="submit">
                        {t(Lang.FILTER)}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default LogFilters
