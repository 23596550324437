import ICamera from '../../../services/Camera/Camera.interface'
import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm } from 'react-hook-form'
import ErrorField from '../../../components/form/ErrorField'
import { carDirection, typeEvent } from '../../../services/Event/Event.interface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

export interface EventsFilterForm {
    zone?: string
    camera?: string
    direction?: string
    start?: string
    end?: string
    plate?: string
    type?: string[]
    sentSanctionDate?: string
    page?: number
    limit?: number
    cameras?: string[]
    count?: boolean
    select?: string
}

interface EventsFilterProps {
    cameras?: ICamera[]
    handleFilter: (data: EventsFilterForm) => void
}

const EventsFilter = ({ cameras, handleFilter }: EventsFilterProps): JSX.Element => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<EventsFilterForm>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    return (
        <Form onSubmit={handleSubmit(handleFilter)}>
            <Row>
                <Col lg="2" md="3" sm="4" className="mb-3">
                    <Form.Control
                        id="plateFilter"
                        type="text"
                        maxLength={32}
                        placeholder={t(Lang.PLATE)}
                        aria-label={t(Lang.PLATE)}
                        aria-describedby={t(Lang.PLATE)}
                        className={errors.plate ? 'border-2 border-danger' : ''}
                        {...register('plate', {
                            maxLength: {
                                value: 32,
                                message: t(Lang.MAX_LENGTH_FIELD, {
                                    field: t(Lang.PLATE),
                                    value: 32
                                })
                            }
                        })}
                    />
                    {errors.plate && <ErrorField message={errors.plate.message} />}
                </Col>
                {cameras ? (
                    <Col className="mb-3" lg="2" md="3" sm="4">
                        <InputGroup>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>{t(Lang.CAMERA)}</Tooltip>}
                            >
                                <InputGroup.Text
                                    className={`text-white ${
                                        errors.camera
                                            ? 'bg-danger border-2 border-danger'
                                            : 'bg-primary'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'video']}
                                        viewBox="0 0 512 512"
                                        fixedWidth
                                    />
                                </InputGroup.Text>
                            </OverlayTrigger>
                            <Form.Select id="cameraFilter" {...register('camera')} defaultValue="">
                                <option value="">{t(Lang.FEMALE_ALL)}</option>
                                {cameras.map((camera) => {
                                    return (
                                        <option key={camera._id} value={camera._id}>
                                            {camera.name}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                ) : (
                    ''
                )}
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DIRECTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.direction
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-arrow']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="directionFilter"
                            {...register('direction')}
                            defaultValue=""
                        >
                            <option value="">{t(Lang.FEMALE_ALL)}</option>
                            {Object.keys(carDirection).map((direction) => {
                                return (
                                    <option key={direction} value={direction}>
                                        {t(langObj[direction.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.start ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-start']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="startFilter"
                            type="datetime-local"
                            defaultValue={moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm')}
                            {...register('start')}
                        />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.end ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-end']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="endFilter"
                            type="datetime-local"
                            defaultValue={moment().format('YYYY-MM-DD HH:mm')}
                            {...register('end')}
                        />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.TYPE_OF_REGISTRY)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.type ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'rectangle-list']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select id="typeFilter" {...register('type')}>
                            <option value="">{t(Lang.FEMALE_ALL)}</option>
                            {Object.keys(typeEvent).map((type) => {
                                return (
                                    <option key={type} value={type}>
                                        {t(langObj[type.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.SENT_SANCTION_DATE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.sentSanctionDate
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'calendar-days']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="sentSanctionDateFilter"
                            type="date"
                            {...register('sentSanctionDate')}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg="1" sm="2">
                    <Button variant="primary" type="submit" className="w-100">
                        {t(Lang.FILTER)}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventsFilter
