import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IPlate from './Plate.interface'

const apiURL = `${baseURL}/plates`

export const createPlate = async (plate: IPlate): Promise<IPlate | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, plate, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdPlate: IPlate = response.data
            return createdPlate
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const insertPlates = async (
    plates: IPlate[],
    listId: string,
    replace: boolean
): Promise<IPlate[] | false> => {
    try {
        const response: AxiosResponse = await axios.post(
            `${apiURL}/list/${listId}`,
            { plates, replace },
            { headers: Auth.authHeader() }
        )

        if (response.data) {
            const insertedPlates: IPlate[] = response.data
            return insertedPlates
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getPlates = async (
    list?: string,
    plate?: string,
    owner?: string,
    address?: string
): Promise<IPlate[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader(),
            params: { list, plate, owner, address }
        })

        if (response.data) {
            const plates: IPlate[] = response.data
            return plates
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getPlate = async (id: string): Promise<IPlate | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const plate: IPlate = response.data
            return plate
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updatePlate = async (plate: IPlate): Promise<IPlate | false> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiURL}/${plate._id}`, plate, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedPlate: IPlate = response.data
            return updatedPlate
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deletePlate = async (id: string): Promise<IPlate | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedPlate: IPlate = response.data
            return deletedPlate
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
