import ICamera from '../../../services/Camera/Camera.interface'
import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm } from 'react-hook-form'
import ErrorField from '../../../components/form/ErrorField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { carDirection } from '../../../services/Event/Event.interface'

export interface AlertsFiltersForm {
    user?: string
    zone?: string
    plate?: string
    start?: string
    end?: string
    camera?: string
    direction?: string
    limit?: number
    page?: number
    count?: boolean
}

interface AlertsFiltersProps {
    cameras?: ICamera[]
    handleFilter: (data: AlertsFiltersForm) => void
}

const AlertsFilters = ({ cameras, handleFilter }: AlertsFiltersProps) => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<AlertsFiltersForm>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    return (
        <Form onSubmit={handleSubmit(handleFilter)}>
            <Row>
                <Col lg="2" md="3" sm="4" className="mb-3">
                    <Form.Control
                        id="plateFilter"
                        type="text"
                        maxLength={32}
                        placeholder={t(Lang.PLATE)}
                        aria-label={t(Lang.PLATE)}
                        aria-describedby={t(Lang.PLATE)}
                        className={errors.plate ? 'border-2 border-danger' : ''}
                        {...register('plate', {
                            maxLength: {
                                value: 32,
                                message: t(Lang.MAX_LENGTH_FIELD, {
                                    field: t(Lang.PLATE),
                                    value: 32
                                })
                            }
                        })}
                    />
                    {errors.plate && <ErrorField message={errors.plate.message} />}
                </Col>
                {cameras ? (
                    <Col className="mb-3" lg="2" md="3" sm="4">
                        <InputGroup>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>{t(Lang.CAMERA)}</Tooltip>}
                            >
                                <InputGroup.Text
                                    className={`text-white ${
                                        errors.camera
                                            ? 'bg-danger border-2 border-danger'
                                            : 'bg-primary'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'video']}
                                        viewBox="0 0 512 512"
                                        fixedWidth
                                    />
                                </InputGroup.Text>
                            </OverlayTrigger>
                            <Form.Select id="cameraFilter" {...register('camera')} defaultValue="">
                                <option value="">{t(Lang.FEMALE_ALL)}</option>
                                {cameras.map((camera) => {
                                    return (
                                        <option key={camera._id} value={camera._id}>
                                            {camera.name}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                ) : (
                    ''
                )}
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DIRECTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.direction
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-arrow']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Select
                            id="directionFilter"
                            {...register('direction')}
                            defaultValue=""
                        >
                            <option value="">{t(Lang.FEMALE_ALL)}</option>
                            {Object.keys(carDirection).map((direction) => {
                                return (
                                    <option key={direction} value={direction}>
                                        {t(langObj[direction.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.START)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.start ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-start']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control
                            id="startFilter"
                            type="datetime-local"
                            {...register('start')}
                        />
                    </InputGroup>
                </Col>
                <Col className="mb-3" lg="2" md="3" sm="4">
                    <InputGroup>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.END)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.end ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'hourglass-end']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <Form.Control id="endFilter" type="datetime-local" {...register('end')} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg="1" sm="2">
                    <Button variant="primary" type="submit" className="w-100">
                        {t(Lang.FILTER)}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default AlertsFilters
