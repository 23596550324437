import { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Menu from '../../components/menu/Menu'
import IUser from '../../services/User/User.interface'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import Loader from '../../components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IList, { TypeList } from '../../services/List/List.interface'
import * as List from '../../services/List/List'
import IZone from '../../services/Zone/Zone.interface'
import * as Zone from '../../services/Zone/Zone'
import ListModal from '../../components/lists/ListModal'
import ListElement from '../../components/lists/ListElement'
import IOption from '../../services/Option/Option.interface'
import * as Option from '../../services/Option/Option'

interface WhiteListsLayoutProps {
    user?: IUser
}

const WhiteListsLayout = ({ user }: WhiteListsLayoutProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [lists, setLists] = useState<IList[]>([])
    const [showListModal, setShowListModal] = useState(false)
    const [listModal, setListModal] = useState<IList | false>()
    const [fetchLists, setFetchLists] = useState(true)
    const [zones, setZones] = useState<IZone[]>([])
    const [descriptions, setDescriptions] = useState<IOption[]>([])

    const triggerListFetch = () => setFetchLists((t) => !t)

    const handleOpenListModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const button: HTMLButtonElement = event.currentTarget

        const id = button.value

        if (id) {
            const list = lists.find((list) => list._id === id)
            setListModal(list)
        } else setListModal(undefined)

        setShowListModal(true)
    }

    const handleCloseListModal = (): void => {
        setListModal(false)
        setShowListModal(false)
    }

    useEffect(() => {
        const loadLists = async () => {
            const lists = await List.getLists()

            if (lists) {
                const whiteLists = lists.filter((list) => list.type === TypeList.white)
                setLists(whiteLists)
                setLoading(false)
            }
        }

        loadLists()
    }, [fetchLists])

    useEffect(() => {
        const loadZones = async () => {
            const zones = await Zone.getZones()

            if (zones) setZones(zones)
        }

        loadZones()
    }, [])

    useEffect(() => {
        const loadOptions = async () => {
            const options = await Option.getOptions()

            if (options) {
                const descriptionsList: IOption[] = options.filter(
                    (option) => option.name === 'descriptionLists'
                )

                setDescriptions(descriptionsList)
            }
        }

        loadOptions()
    }, [])

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? <Menu currentPage={t(Lang.WHITE_LISTS)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-3 pe-xl-4">
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="btn-add"
                                onClick={handleOpenListModal}
                            >
                                <FontAwesomeIcon icon={['fas', 'plus']} size="2x" fixedWidth />
                            </Button>
                            <ListModal
                                show={showListModal}
                                handleClose={handleCloseListModal}
                                reloadLists={triggerListFetch}
                                list={listModal}
                                zones={zones}
                                descriptions={descriptions}
                                type={TypeList.white}
                            />
                        </Col>
                    </Row>
                    {lists.length ? (
                        <Row>
                            {lists.map((list) => {
                                return (
                                    <Col key={list._id} sm="6" lg="4" xl="3" className="mb-3">
                                        <ListElement
                                            list={list}
                                            handleEditList={handleOpenListModal}
                                            reloadLists={triggerListFetch}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    )
}

export default WhiteListsLayout
