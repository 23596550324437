import { CSVLink } from 'react-csv'
import IPlate from '../../../services/Plate/Plate.interface'
import IList from '../../../services/List/List.interface'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Days } from '../../../services/Day/Day.interface'
import * as Lang from '../../../i18n/constants'
import { useTranslation } from 'react-i18next'

interface ICSV {
    [key: string]: string | undefined
}

interface WhiteExportCSVProps {
    plates: IPlate[]
    list: IList
}

const WhiteExportCSV = ({ plates, list }: WhiteExportCSVProps): JSX.Element => {
    const { t } = useTranslation()

    const headers = [
        { label: 'plate', key: 'plate' },
        { label: 'description', key: 'description' },
        { label: 'observations', key: 'observations' },
        { label: 'brand', key: 'brand' },
        { label: 'model', key: 'model' },
        { label: 'color', key: 'color' },
        { label: 'owner', key: 'owner' }
    ]

    Object.keys(Days).map((day) => {
        headers.push(
            {
                label: `${day}_timeZone1_start`,
                key: `${day}_timeZone1_start`
            },
            {
                label: `${day}_timeZone1_end`,
                key: `${day}_timeZone1_end`
            },
            {
                label: `${day}_timeZone2_start`,
                key: `${day}_timeZone2_start`
            },
            {
                label: `${day}_timeZone2_end`,
                key: `${day}_timeZone2_end`
            }
        )
    })

    headers.push({ label: 'dateOfExpiry', key: 'dateOfExpiry' })

    const data = []

    for (const plate of plates) {
        const dataPlate: ICSV = {
            plate: plate.plate,
            description: plate.description,
            observations: plate.observations,
            brand: plate.brand,
            model: plate.model,
            color: plate.color,
            owner: plate.owner,
            list: plate.list
        }

        if (plate.calendar) {
            plate.calendar.days.map((day) => {
                dataPlate[`${day.day}_timeZone1_start`] = day.timeZone1?.start
                dataPlate[`${day.day}_timeZone1_end`] = day.timeZone1?.end
                dataPlate[`${day.day}_timeZone2_start`] = day.timeZone2?.start
                dataPlate[`${day.day}_timeZone2_end`] = day.timeZone2?.end
            })
        }

        dataPlate['dateOfExpiry'] = plate.calendar?.dateOfExpiry
            ? moment(plate.calendar?.dateOfExpiry).format('DD/MM/YYYY')
            : ''

        data.push(dataPlate)
    }

    const filename = `${list.name}_${moment(new Date()).format('DD-MM-YYYY HH:mm')}.csv`

    return (
        <CSVLink headers={headers} data={data} filename={filename} separator=";">
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t(Lang.EXPORT_CSV_FILE)}</Tooltip>}
            >
                <Button variant="link" className="btn-add">
                    <FontAwesomeIcon icon={['fas', 'file-csv']} size="2x" fixedWidth />
                </Button>
            </OverlayTrigger>
        </CSVLink>
    )
}

export default WhiteExportCSV
