import axios, { AxiosResponse } from 'axios'
import * as Auth from '../Authentication'
import baseURL from '../BaseURL'
import IUser from './User.interface'

const apiURL = `${baseURL}/users`

export const createUser = async (user: IUser): Promise<IUser | false> => {
    try {
        const response: AxiosResponse = await axios.post(apiURL, user, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const createdUser: IUser = response.data
            return createdUser
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getUsers = async (): Promise<IUser[] | false> => {
    try {
        const response: AxiosResponse = await axios.get(apiURL, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const users: IUser[] = response.data
            return users
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getUser = async (id: string): Promise<IUser | false> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const user: IUser = response.data
            return user
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const updateUser = async (user: IUser): Promise<IUser | false> => {
    try {
        const response = await axios.put(`${apiURL}/${user._id}`, user, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const updatedUser: IUser = response.data
            return updatedUser
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const deleteUser = async (id: string): Promise<IUser | false> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiURL}/${id}`, {
            headers: Auth.authHeader()
        })

        if (response.data) {
            const deletedUser: IUser = response.data
            return deletedUser
        } else return false
    } catch (error) {
        console.error(error)
        return false
    }
}
