import {
    Modal,
    Form,
    InputGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    Button,
    FormLabel,
    Row,
    Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../../i18n/constants'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import ErrorField from '../../../components/form/ErrorField'
import Loader from '../../../components/Loader'
import { toast } from 'react-toastify'
import ICamera, {
    Detection,
    Direction,
    Manufacturers,
    DeviceStatus
} from '../../../services/Camera/Camera.interface'
import * as Camera from '../../../services/Camera/Camera'

interface CameraModalProps {
    show: boolean
    handleClose: () => void
    reloadCameras: () => void
    camera?: ICamera | false
    zoneId: string
}

const CameraModal = ({
    show,
    handleClose,
    reloadCameras,
    camera,
    zoneId
}: CameraModalProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [manufacturerSelected, setManufacturerSelected] = useState(Object.keys(Manufacturers)[0])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<ICamera>()

    const langObj: { [key: string]: string } = {}

    for (const key of Object.keys(Lang)) langObj[key] = key

    const handleCreateCamera = async (data: ICamera): Promise<void> => {
        setLoading(true)

        const camera: ICamera = {
            identifier: data.identifier,
            name: data.name,
            description: data.description,
            location: data.location,
            coordinates: data.coordinates,
            direction: data.direction,
            detection: data.detection,
            manufacturer: data.manufacturer,
            model: data.model,
            host: data.host,
            port: data.port,
            zone: zoneId,
            status: DeviceStatus.offline,
            imageFrame: data.imageFrame
        }

        const createdCamera = await Camera.createCamera(camera)

        if (createdCamera) {
            toast.success(t(Lang.CAMERA_CREATED_SUCCESSFULLY, { camera }))
            reloadCameras()
        } else toast.error(t(Lang.ERROR_CREATING_CAMERA, { camera }))

        setLoading(false)

        handleClose()
    }

    const handleUpdateCamera = async (data: ICamera): Promise<void> => {
        setLoading(true)

        const camera: ICamera = {
            _id: data._id,
            identifier: data.identifier,
            name: data.name,
            description: data.description,
            location: data.location,
            coordinates: data.coordinates,
            direction: data.direction,
            detection: data.detection,
            manufacturer: data.manufacturer,
            model: data.model,
            host: data.host,
            port: data.port,
            zone: zoneId,
            imageFrame: data.imageFrame
        }

        const updatedCamera = await Camera.updateCamera(camera)

        if (updatedCamera) {
            toast.success(t(Lang.CAMERA_UPDATED_SUCCESSFULLY, { camera }))
            reloadCameras()
        } else toast.error(t(Lang.ERROR_UPDATING_CAMERA, { camera }))

        setLoading(false)
        handleClose()
    }

    const handleChangeManufacturer = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setManufacturerSelected(event.target.value)
    }

    useEffect(() => {
        if (camera) {
            setManufacturerSelected(camera.manufacturer)
            reset(camera)
        } else
            reset({
                identifier: '',
                name: '',
                description: '',
                location: '',
                coordinates: '',
                direction: Direction.entry,
                detection: Detection.through,
                manufacturer: '',
                model: '',
                host: '',
                port: 0,
                zone: zoneId,
                imageFrame: { top: 0, right: 0, bottom: 0, left: 0 }
            })
    }, [camera])

    return (
        <Modal show={show} onHide={handleClose}>
            <Form
                onSubmit={
                    camera ? handleSubmit(handleUpdateCamera) : handleSubmit(handleCreateCamera)
                }
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        {camera ? t(Lang.EDIT_CAMERA, { camera }) : t(Lang.ADD_CAMERA)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <small>{t(Lang.REQUIRED_MESSAGE)}</small>
                    </p>
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.IDENTIFIER)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.identifier
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'id-card']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="identifier"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.IDENTIFIER)}
                            aria-label={t(Lang.IDENTIFIER)}
                            aria-describedby={t(Lang.IDENTIFIER)}
                            className={errors.name ? 'border-2 border-danger' : ''}
                            {...register('identifier', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.IDENTIFIER)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.IDENTIFIER),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.identifier && <ErrorField message={errors.identifier.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.NAME)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.name ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'font']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="name"
                            type="text"
                            maxLength={256}
                            placeholder={'* ' + t(Lang.NAME)}
                            aria-label={t(Lang.NAME)}
                            aria-describedby={t(Lang.NAME)}
                            className={errors.name ? 'border-2 border-danger' : ''}
                            {...register('name', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.NAME)
                                }) as string,
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.NAME),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.name && <ErrorField message={errors.name.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DESCRIPTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.description
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'align-left']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="description"
                            as="textarea"
                            maxLength={512}
                            rows={5}
                            placeholder={t(Lang.DESCRIPTION)}
                            aria-label={t(Lang.DESCRIPTION)}
                            aria-describedby={t(Lang.DESCRIPTION)}
                            className={errors.description ? 'border-2 border-danger' : ''}
                            {...register('description', {
                                maxLength: {
                                    value: 512,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.DESCRIPTION),
                                        value: 512
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.description && <ErrorField message={errors.description.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.LOCATION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.location
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-dot']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="location"
                            type="text"
                            maxLength={256}
                            placeholder={'* ' + t(Lang.LOCATION)}
                            aria-label={t(Lang.LOCATION)}
                            aria-describedby={t(Lang.LOCATION)}
                            className={errors.location ? 'border-2 border-danger' : ''}
                            {...register('location', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.LOCATION)
                                }) as string,
                                maxLength: {
                                    value: 256,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.LOCATION),
                                        value: 256
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.location && <ErrorField message={errors.location.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.COORDINATES)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.coordinates
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'location-crosshairs']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="coordinates"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.COORDINATES)}
                            aria-label={t(Lang.COORDINATES)}
                            aria-describedby={t(Lang.COORDINATES)}
                            className={errors.coordinates ? 'border-2 border-danger' : ''}
                            {...register('coordinates', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.COORDINATES)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.COORDINATES),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.coordinates && <ErrorField message={errors.coordinates.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DIRECTION)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.direction
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'compass']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="direction"
                            className={errors.direction ? 'border-2 border-danger' : ''}
                            {...register('direction', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.DIRECTION)
                                }) as string
                            })}
                        >
                            {Object.keys(Direction).map((direction: string) => {
                                return (
                                    <option key={direction} value={direction}>
                                        {'* ' + t(langObj[direction.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.direction && <ErrorField message={errors.direction.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.DETECTION_TYPE)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.detection
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'vector-square']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="detection"
                            className={errors.detection ? 'border-2 border-danger' : ''}
                            {...register('detection', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.DETECTION_TYPE)
                                }) as string
                            })}
                        >
                            {Object.keys(Detection).map((detection: string) => {
                                return (
                                    <option key={detection} value={detection}>
                                        {'* ' + t(langObj[detection.toUpperCase()])}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.detection && <ErrorField message={errors.detection.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MANUFACTURER)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.manufacturer
                                        ? 'bg-danger border-2 border-danger'
                                        : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'industry']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="manufacturer"
                            className={errors.manufacturer ? 'border-2 border-danger' : ''}
                            {...register('manufacturer', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.MANUFACTURER)
                                }) as string
                            })}
                            onChange={handleChangeManufacturer}
                        >
                            {Object.keys(Manufacturers).map((manufacturer: string) => {
                                return (
                                    <option key={manufacturer} value={manufacturer}>
                                        {'* ' + manufacturer}
                                    </option>
                                )
                            })}
                        </Form.Select>

                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.MODEL)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ms-3 rounded-start ${
                                    errors.model ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'cubes']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>

                        <Form.Select
                            id="model"
                            className={errors.model ? 'border-2 border-danger' : ''}
                            {...register('model', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.MODEL)
                                }) as string
                            })}
                        >
                            {Manufacturers[manufacturerSelected].models.map((model: string) => {
                                return (
                                    <option key={model} value={model}>
                                        {'* ' + model}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </InputGroup>
                    {errors.manufacturer && <ErrorField message={errors.manufacturer.message} /> &&
                        errors.model && <ErrorField message={errors.model.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.HOST)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.host ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'server']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="host"
                            type="text"
                            maxLength={128}
                            placeholder={'* ' + t(Lang.HOST)}
                            aria-label={t(Lang.HOST)}
                            aria-describedby={t(Lang.HOST)}
                            className={errors.host ? 'border-2 border-danger' : ''}
                            {...register('host', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.HOST)
                                }) as string,
                                maxLength: {
                                    value: 128,
                                    message: t(Lang.MAX_LENGTH_FIELD, {
                                        field: t(Lang.HOST),
                                        value: 128
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.host && <ErrorField message={errors.host.message} />}
                    <InputGroup className="mb-3">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t(Lang.PORT)}</Tooltip>}
                        >
                            <InputGroup.Text
                                className={`text-white ${
                                    errors.port ? 'bg-danger border-2 border-danger' : 'bg-primary'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'network-wired']}
                                    viewBox="0 0 512 512"
                                    fixedWidth
                                />
                            </InputGroup.Text>
                        </OverlayTrigger>
                        <FormControl
                            id="port"
                            type="number"
                            min={1}
                            max={65535}
                            placeholder={'* ' + t(Lang.PORT)}
                            aria-label={t(Lang.PORT)}
                            aria-describedby={t(Lang.PORT)}
                            className={errors.port ? 'border-2 border-danger' : ''}
                            {...register('port', {
                                required: t(Lang.REQUIRED_FIELD, {
                                    field: t(Lang.PORT)
                                }) as string,
                                min: {
                                    value: 1,
                                    message: t(Lang.MIN_FIELD, {
                                        field: t(Lang.PORT),
                                        value: 1
                                    })
                                },
                                max: {
                                    value: 65535,
                                    message: t(Lang.MAX_FIELD, {
                                        field: t(Lang.PORT),
                                        value: 65535
                                    })
                                }
                            })}
                        />
                    </InputGroup>
                    {errors.port && <ErrorField message={errors.port.message} />}
                    <FormLabel>{t(Lang.FRAME)}</FormLabel>
                    <Row>
                        <Col className="card pt-3">
                            <InputGroup className="mb-3">
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>{t(Lang.TOP_MARGIN)}</Tooltip>}
                                >
                                    <InputGroup.Text
                                        className={`text-white ${
                                            errors.imageFrame?.top
                                                ? 'bg-danger border-2 border-danger'
                                                : 'bg-primary'
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'up-long']}
                                            viewBox="0 0 512 512"
                                            fixedWidth
                                        />
                                    </InputGroup.Text>
                                </OverlayTrigger>
                                <FormControl
                                    id="topMargin"
                                    type="number"
                                    min={0}
                                    max={100000}
                                    className={
                                        errors.imageFrame?.top ? 'border-2 border-danger' : ''
                                    }
                                    {...register('imageFrame.top', {
                                        required: t(Lang.REQUIRED_FIELD, {
                                            field: t(Lang.TOP_MARGIN)
                                        }) as string,
                                        min: {
                                            value: 0,
                                            message: t(Lang.MIN_FIELD, {
                                                field: t(Lang.TOP_MARGIN),
                                                value: 0
                                            })
                                        },
                                        max: {
                                            value: 100000,
                                            message: t(Lang.MAX_FIELD, {
                                                field: t(Lang.TOP_MARGIN),
                                                value: 100000
                                            })
                                        }
                                    })}
                                />

                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>{t(Lang.BOTTOM_MARGIN)}</Tooltip>}
                                >
                                    <InputGroup.Text
                                        className={`text-white ms-3 ${
                                            errors.imageFrame?.bottom
                                                ? 'bg-danger border-2 border-danger'
                                                : 'bg-primary'
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'down-long']}
                                            viewBox="0 0 512 512"
                                            fixedWidth
                                        />
                                    </InputGroup.Text>
                                </OverlayTrigger>
                                <FormControl
                                    id="bottomMargin"
                                    type="number"
                                    min={0}
                                    max={100000}
                                    className={
                                        errors.imageFrame?.bottom ? 'border-2 border-danger' : ''
                                    }
                                    {...register('imageFrame.bottom', {
                                        required: t(Lang.REQUIRED_FIELD, {
                                            field: t(Lang.BOTTOM_MARGIN)
                                        }) as string,
                                        min: {
                                            value: 0,
                                            message: t(Lang.MIN_FIELD, {
                                                field: t(Lang.BOTTOM_MARGIN),
                                                value: 0
                                            })
                                        },
                                        max: {
                                            value: 100000,
                                            message: t(Lang.MAX_FIELD, {
                                                field: t(Lang.BOTTOM_MARGIN),
                                                value: 100000
                                            })
                                        }
                                    })}
                                />
                            </InputGroup>
                            {errors.imageFrame?.top && (
                                <ErrorField message={errors.imageFrame.top.message} />
                            )}

                            {errors.imageFrame?.bottom && (
                                <ErrorField message={errors.imageFrame.bottom.message} />
                            )}

                            <InputGroup className="mb-3">
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>{t(Lang.RIGHT_MARGIN)}</Tooltip>}
                                >
                                    <InputGroup.Text
                                        className={`text-white ${
                                            errors.imageFrame?.right
                                                ? 'bg-danger border-2 border-danger'
                                                : 'bg-primary'
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'right-long']}
                                            viewBox="0 0 512 512"
                                            fixedWidth
                                        />
                                    </InputGroup.Text>
                                </OverlayTrigger>
                                <FormControl
                                    id="rightMargin"
                                    type="number"
                                    min={0}
                                    max={100000}
                                    className={
                                        errors.imageFrame?.right ? 'border-2 border-danger' : ''
                                    }
                                    {...register('imageFrame.right', {
                                        required: t(Lang.REQUIRED_FIELD, {
                                            field: t(Lang.RIGHT_MARGIN)
                                        }) as string,
                                        min: {
                                            value: 0,
                                            message: t(Lang.MIN_FIELD, {
                                                field: t(Lang.RIGHT_MARGIN),
                                                value: 0
                                            })
                                        },
                                        max: {
                                            value: 100000,
                                            message: t(Lang.MAX_FIELD, {
                                                field: t(Lang.RIGHT_MARGIN),
                                                value: 100000
                                            })
                                        }
                                    })}
                                />
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>{t(Lang.LEFT_MARGIN)}</Tooltip>}
                                >
                                    <InputGroup.Text
                                        className={`text-white ms-3 ${
                                            errors.imageFrame?.left
                                                ? 'bg-danger border-2 border-danger'
                                                : 'bg-primary'
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'left-long']}
                                            viewBox="0 0 512 512"
                                            fixedWidth
                                        />
                                    </InputGroup.Text>
                                </OverlayTrigger>
                                <FormControl
                                    id="leftMargin"
                                    type="number"
                                    min={0}
                                    max={100000}
                                    className={
                                        errors.imageFrame?.left ? 'border-2 border-danger' : ''
                                    }
                                    {...register('imageFrame.left', {
                                        required: t(Lang.REQUIRED_FIELD, {
                                            field: t(Lang.LEFT_MARGIN)
                                        }) as string,
                                        min: {
                                            value: 0,
                                            message: t(Lang.MIN_FIELD, {
                                                field: t(Lang.LEFT_MARGIN),
                                                value: 0
                                            })
                                        },
                                        max: {
                                            value: 100000,
                                            message: t(Lang.MAX_FIELD, {
                                                field: t(Lang.LEFT_MARGIN),
                                                value: 100000
                                            })
                                        }
                                    })}
                                />
                            </InputGroup>
                            {errors.imageFrame?.right && (
                                <ErrorField message={errors.imageFrame.right.message} />
                            )}
                            {errors.imageFrame?.left && (
                                <ErrorField message={errors.imageFrame.left.message} />
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(Lang.CANCEL)}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        id="_id"
                        className="btn-modal"
                        {...register('_id')}
                    >
                        {loading ? (
                            <Loader loading={loading} size={25} color="#FFFFFF" />
                        ) : (
                            t(Lang.SAVE)
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CameraModal
