import { ClipLoader } from 'react-spinners'

interface LoadingProps {
    loading: boolean
    size: number
    color: string
}

const Loader = ({ loading, size, color }: LoadingProps): JSX.Element => {
    return (
        <div className="d-flex justify-content-center">
            <div className="my-auto">
                <ClipLoader color={color} loading={loading} size={size} />
            </div>
        </div>
    )
}

export default Loader
