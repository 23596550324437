import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Menu from '../../components/menu/Menu'
import IUser from '../../services/User/User.interface'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Lang from '../../i18n/constants'
import Loader from '../../components/Loader'
import IZone from '../../services/Zone/Zone.interface'
import * as Zone from '../../services/Zone/Zone'
import ICamera from '../../services/Camera/Camera.interface'
import * as Camera from '../../services/Camera/Camera'
import IEvent, { typeEvent } from '../../services/Event/Event.interface'
import * as Event from '../../services/Event/Event'
import SanctionsFilter from './components/SanctionsFilter'
import SanctionModal from './components/SanctionModal'
import Pagination from '../../components/CustomPagination'
import SanctionsTable from './components/SanctionsTable'
import { EventsFilterForm } from '../Events/components/EventsFilter'
import SanctionsExportCSV from './components/SanctionsExportCSV'
import SanctionsExportPDF from './components/SanctionsExportPDF'
import moment from 'moment'

interface SanctionsLayoutProps {
    user?: IUser
}

const SanctionsLayout = ({ user }: SanctionsLayoutProps): JSX.Element => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [zones, setZones] = useState<IZone[]>([])
    const [zoneSelected, setZoneSelected] = useState<string | undefined>()
    const [cameras, setCameras] = useState<ICamera[]>([])
    const [sanctions, setSanctions] = useState<IEvent[]>([])
    const [totalSanctions, setTotalSanctions] = useState<number>()
    const [fetchSanctions, setFetchSanctions] = useState(true)
    const [filter, setFilter] = useState<EventsFilterForm>()
    const [sanctionModal, setSanctionModal] = useState<IEvent | false>()
    const [showSanctionModal, setShowSanctionModal] = useState(false)
    const [limit, setLimit] = useState(50)
    const [page, setPage] = useState(1)
    const [sanctionsProposals, setSanctionsProposals] = useState<IEvent[]>([])

    const triggerSanctionFetch = () => setFetchSanctions((t) => !t)

    const handleChangeZone = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setZoneSelected(event.target.value)
        setPage(1)
        triggerSanctionFetch()
    }

    const handleFilter = (data: EventsFilterForm) => {
        const sanction: EventsFilterForm = {}
        Object.entries(data).map(([index, value]) => {
            if (value !== '') {
                if (index === 'cameras') sanction[index] = [value]
                else if (index === 'start' || index === 'end')
                    sanction[index] = moment(value).utc().format('YYYY-MM-DD HH:mm')
                else if (index === 'type') {
                    sanction[index] = sanction[index] ?? []
                    sanction[index]?.push(value)
                } else sanction[index as keyof EventsFilterForm] = value
            }
        })
        setFilter(sanction)
        setLoading(true)
        setPage(1)
        triggerSanctionFetch()
    }

    const handleOpenSanctionModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const button: HTMLButtonElement = event.currentTarget

        const id = button.value

        if (id) {
            const sanction = sanctions.find((sanction) => sanction._id === id)
            setSanctionModal(sanction)
        } else setSanctionModal(undefined)

        setShowSanctionModal(true)
    }

    const handleCloseSanctionModal = (): void => {
        setSanctionModal(false)
        setShowSanctionModal(false)
    }

    const handleChangeLimit = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setLimit(parseInt(event.target.value))
        setPage(1)
        triggerSanctionFetch()
    }

    const handleClickPage = (nPage: number) => {
        setPage(nPage)
        triggerSanctionFetch()
    }

    useEffect(() => {
        const load = async () => {
            const zones = await Zone.getZones()

            if (zones && zones.length) {
                setZones(zones)

                if (!zoneSelected) setZoneSelected(zones[0]._id)

                const zoneId = zoneSelected || zones[0]._id || ''

                const cameras = await Camera.getCameras(zoneId)

                if (cameras) setCameras(cameras)

                const sanctionsCount = await Event.getEventsCount({
                    ...filter,
                    start:
                        filter && filter.start
                            ? filter.start
                            : moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm'),
                    end: filter && filter.end ? filter.end : moment().format('YYYY-MM-DD HH:mm'),
                    type:
                        filter && filter.type
                            ? filter.type
                            : [
                                  typeEvent.possibleInfringement,
                                  typeEvent.pendingSanction,
                                  typeEvent.sanctioned
                              ],
                    zone: zoneId
                })

                if (sanctionsCount) setTotalSanctions(sanctionsCount)

                const sanctions = await Event.getEvents({
                    ...filter,
                    start:
                        filter && filter.start
                            ? filter.start
                            : moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm'),
                    end: filter && filter.end ? filter.end : moment().format('YYYY-MM-DD HH:mm'),
                    type:
                        filter && filter.type
                            ? filter.type
                            : [
                                  typeEvent.possibleInfringement,
                                  typeEvent.pendingSanction,
                                  typeEvent.sanctioned
                              ],
                    zone: zoneId,
                    limit,
                    page
                })

                if (sanctions) {
                    setSanctions(sanctions)

                    const sanctionsProposals = sanctions.filter(
                        (sanction) => sanction.sentSanctionDate
                    )

                    setSanctionsProposals(sanctionsProposals)
                }
            }
            setLoading(false)
        }
        load()
    }, [fetchSanctions])

    return (
        <>
            <Header user={user} />
            <Row className="h-100">
                <Col xs="3" xl="2" className="p-0 sidebar d-none d-xl-block">
                    {user ? <Menu currentPage={t(Lang.SANCTIONS)} user={user} /> : ''}
                </Col>
                <Col xs="12" xl="10" className="p-4 p-xl-3 pe-xl-4">
                    <Row className="mb-5">
                        {zones.length ? (
                            <Col xs="12" xl="4">
                                <Form.Group as={Row}>
                                    <Col xs="12">
                                        <Form.Select onChange={handleChangeZone}>
                                            {zones.map((zone) => {
                                                return (
                                                    <option key={zone._id} value={zone._id}>
                                                        {zone.name}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                    {cameras.length ? (
                        <>
                            {sanctionsProposals.length ? (
                                <Row className="mb-3">
                                    <Col xs="1">
                                        <SanctionsExportCSV
                                            events={sanctionsProposals}
                                            cameras={cameras}
                                        />
                                    </Col>
                                    <Col xs="1">
                                        <SanctionsExportPDF
                                            events={sanctionsProposals}
                                            cameras={cameras}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                ''
                            )}
                            <Row className="mb-5">
                                <Col>
                                    <SanctionsFilter
                                        cameras={cameras}
                                        handleFilter={handleFilter}
                                    />
                                    {zoneSelected && sanctionModal ? (
                                        <SanctionModal
                                            show={showSanctionModal}
                                            handleClose={handleCloseSanctionModal}
                                            sanction={sanctionModal}
                                            cameras={cameras}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        ''
                    )}
                    {sanctions.length && cameras.length ? (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <SanctionsTable
                                        sanctions={sanctions}
                                        cameras={cameras}
                                        handleEditSanction={handleOpenSanctionModal}
                                        reloadSanctions={triggerSanctionFetch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" xl="2" className="mb-3">
                                    <Form.Label>
                                        {t(Lang.ITEMS_PER_PAGE, {
                                            item: t(Lang.REGISTRY)
                                        })}
                                    </Form.Label>
                                </Col>
                                <Col xs="6" xl="1">
                                    <Form.Select defaultValue={limit} onChange={handleChangeLimit}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Col>
                                {totalSanctions && totalSanctions > limit ? (
                                    <Col xs="12" xl="9">
                                        <Pagination
                                            totalPages={Math.ceil(totalSanctions / limit)}
                                            currentPage={page}
                                            pageClicked={handleClickPage}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </>
                    ) : (
                        <Loader loading={loading} size={100} color="#0d6efd" />
                    )}
                </Col>
            </Row>
        </>
    )
}

export default SanctionsLayout
