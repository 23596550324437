import moment from 'moment'
import { Col, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { REACT_APP_BACKEND_URL } from '../../../configuration'
import * as Lang from '../../../i18n/constants'
import IRemittance from '../../../services/Remittance/Remittance.interface'

interface RemittancesTableProps {
    remittances: IRemittance[]
}

const RemittancesTable = ({ remittances }: RemittancesTableProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Row>
            <Col>
                <Table responsive striped hover variant="primary">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t(Lang.SUBMISSION_DATE)}</th>
                            <th>{t(Lang.FIRST_SANCTION_DATE)}</th>
                            <th>{t(Lang.LAST_SANCTION_DATE)}</th>
                            <th>{t(Lang.SANCTIONS_NUMBER)}</th>
                            <th>{t(Lang.FILENAME)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {remittances.map((remittance: IRemittance) => {
                            return (
                                <tr key={remittance._id}>
                                    <td></td>
                                    <td>
                                        {moment(remittance.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                                    </td>
                                    <td>
                                        {moment(remittance.firstSanctionDate).format(
                                            'DD/MM/YYYY HH:mm:ss'
                                        )}
                                    </td>
                                    <td>
                                        {moment(remittance.lastSanctionDate).format(
                                            'DD/MM/YYYY HH:mm:ss'
                                        )}
                                    </td>
                                    <td>{remittance.sanctions.length}</td>
                                    <td>
                                        <a
                                            href={`${REACT_APP_BACKEND_URL}/remittances/${remittance.fileName}`}
                                        >
                                            {remittance.fileName}
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default RemittancesTable
